import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import React, {PureComponent} from 'react';
import {EMAIL_ICON} from '../../Constants';
import Typography from '../../fhg/components/Typography';
import {SIGN_IN_STEP, EMAIL_STEP} from './MySignIn';

const styles = theme => ({
   mainGrid: {
      height: 'calc(100% - 80px)',
      width: '100%',
      minHeight: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.brand.gray,//'#5D788F',
      overflow: 'hidden',
   },
   contentStyle: {
      width: 436,
      padding: theme.spacing(3, 7),
      overflow: 'auto',
      backgroundColor: theme.palette.environment.light.level1.base,
   },
   buttonStyle: {
      color: theme.palette.common.white,
      textTransform: 'none',
      borderRadius: 4,
      backgroundColor: theme.palette.button.standard.primary,
      marginTop: `${theme.spacing(0.5)}px !important`,
      padding: '14px 0',
      lineHeight: '20px !important',
      height: '43px !important',
      width: '100%',

      '&:hover': {
         backgroundColor: theme.palette.button.standard.primary,
      },
   },
   emailButtonStyle: {
      color: theme.palette.common.white,
      textTransform: 'none',
      borderRadius: 4,
      backgroundColor: theme.palette.button.standard.primary,
      margin: `${theme.spacing(1.5)}px ${theme.spacing(0)}px ${theme.spacing(1)}px !important`,
      padding: '14px 0',
      lineHeight: '20px !important',
      height: '43px !important',
      width: '100%',

      '&:hover': {
         backgroundColor: theme.palette.button.standard.primary,
      },
   },
   firstTitleStyle: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
   },
   titleStyle: {
      margin: theme.spacing(4.5, 0, 2),
      textAlign: 'center',
   },
   subtitleStyle: {
      margin: theme.spacing(0, 4, 3),
      textAlign: 'center',
   },
});

/**
 * The custom AWS Cognito Sign In component.
 */
class SignInOptionsPanel extends PureComponent {

   constructor(props) {
      super(props);

      this.state = {};

      if (process.env.REACT_APP_ENVIRONMENT_NAME === "purplewave.net") {
         window.Intercom('update', {
            hide_default_launcher: true
         });
      }

      document.addEventListener('keydown', this.handleKey, false);
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   handleKey = (event) => {
      if (!event.defaultPrevented) {
         if (event.key === 'Enter') {
            this.handleSignInSubmit(event);
         }
      }
   };

   handleEmailSubmit = event => {
      const {onSubmit} = this.props;
      event.preventDefault();

      onSubmit && onSubmit(EMAIL_STEP);
   };

   handleSignInSubmit = event => {
      const {onSubmit} = this.props;
      event.preventDefault();

      onSubmit && onSubmit(SIGN_IN_STEP);
   };

   render() {
      const {classes} = this.props;

      return (
         <div className={classes.mainGrid}>
            <Grid container direction={'column'} className={classes.contentStyle} alignItems={'center'}>
               <Typography className={`${classes.firstTitleStyle} level-2-heading`} id={'login.title'}/>
               <img src={EMAIL_ICON} alt=''/>
               <Typography className={`${classes.titleStyle} level-3-heading`} id={'signInOptions.title'}/>
               <Typography className={`${classes.subtitleStyle} tight-default-text`} id={'signInOptions.subtitle'}/>
               <Button className={`subtle-button ${classes.emailButtonStyle}`}
                       onClick={this.handleEmailSubmit}>
                  <Typography color='inherit' className={'large-button-text'} id={'signInOptions.email.label'}/>
               </Button>
               <Button variant={'text'} color='secondary' className={classes.buttonStyle}
                       onClick={this.handleSignInSubmit}>
                  <Typography color='inherit' className={'large-button-text'} id={'signInOptions.signin.label'}/>
               </Button>
            </Grid>
         </div>
      );
   }
}

export default withStyles(styles)(SignInOptionsPanel);

export function LOGO() {
    if (process.env.REACT_APP_ENVIRONMENT_NAME === "purplewave.net") {
        return '/img/purple_wave/pw-icon-color-white.png';
    } else {
        return '/img/HW_only_WHITE-01.svg';
    }
}

export function LARGE_LOGO() {
    if (process.env.REACT_APP_ENVIRONMENT_NAME === "purplewave.net") {
        return '/img/purple_wave/pw-icon-color-white.png';
    } else {
        return '/img/Full_White_Outlines-01.svg';
    }
}

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import {Auth, API} from 'aws-amplify';
import {difference, each, find, defaults} from 'lodash';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Error404 from '../components/eval/components/Error404';
import ErrorSnackbar from '../components/eval/components/ErrorSnackbar';
import {NETWORK_ERROR_THRESHOLD, VALUATION_REASONS, VALUATION_REASONS_DEFAULTS} from '../Constants';

/**
 * Submit the item to the server.
 * @param uri The URI of the item.
 * @param method The command for the API.
 * @param body The item body to send to the server.
 */
export const requestForServer = async (uri, method = 'get', body, responseType = 'json', api = 'api') => {
   let jwtToken;
   let tokenRefreshError = false;

   try {
      const session = await Auth.currentSession();
      // User is logged in. Set auth header on all requests
      //signInUserSession.idToken.jwtToken
      jwtToken = session.idToken.jwtToken;
   } catch(e) {
      try {
         const session = await Auth.currentSession();
         jwtToken = session.idToken.jwtToken;
      } catch (e) {
         console.log('Error refreshing token', e);
         tokenRefreshError = true;
         //Allow to go through without jwToken for server requests not requiring an authenticated user.
      }
   }

   let myInit = { // OPTIONAL
      mode: responseType === 'blob' ? 'no-cors' : undefined,
      headers: {
         'Authorization': jwtToken,
         'content-type': responseType === 'blob' ? 'application/pdf' : 'application/json',
         'Accept': responseType === 'blob' ? 'application/pdf' : 'application/json',
      }, // OPTIONAL
      body,
      responseType,
      response: true // OPTIONAL (return entire response object instead of response.data)
   };
   // For admins, set the organization in the header to only show data from the selected organization.
   if (isAdminOnly() || isMultipleOrganization()) {
      const organization = localStorage['hw.'+window.btoa('organization')];
      if (organization && window.atob(organization)) {
         myInit.headers['HW-Organization'] = window.atob(organization);
      }
   }

   return API[method](api, uri, myInit).then(response => {
      if (uri === '/evaluations/all/open' && response.data.length === 0 && response.config.data === null) {
         console.log('Empty response for open');
         if (!process.env.REACT_APP_API) {
            let array = localStorage.openFail ? JSON.parse(localStorage.openFail) : [];
            array.push({ error: 'Empty response for open', response });
            localStorage.openFail = JSON.stringify(array);
         }
         return requestForServer(uri, method, { isFinal: true });
      }
      return {errorValues: undefined, data: response.data, status: response.status};
   }).catch(fetchError => {
      console.log(fetchError);
      const error = fetchError;
      const message = get(error, 'response.data.message') || fetchError.message;
      // WORKAROUND for AWS issue.  AWS gives the 'Network Error' when the active token expires (user is logged out).
      // If the user clicks to refresh and the error occurs again, force a signout and refresh.
      if (tokenRefreshError && message === 'Network Error') {
         if (localStorage.networkError && ((Date.now() - localStorage.networkError) < NETWORK_ERROR_THRESHOLD)) {
            Auth.signOut().then(() => {
               localStorage.removeItem('networkError');
               window.location.reload();
            }).catch(err => { console.log(err); });
         } else {
            localStorage.networkError = Date.now();
         }
      } else if (localStorage.networkError) {
         localStorage.removeItem('networkError');
      }
      return { error, errorValues: { message } };
   });
};

export function showFile(title, blob) {
   // It is necessary to create a new blob object with mime-type explicitly set
   // otherwise only Chrome works like it should
   var newBlob = blob; //new Blob([blob], {type: "application/pdf"})

   // IE doesn't allow using a blob object directly as link href
   // instead it is necessary to use msSaveOrOpenBlob
   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob, title + '.pdf');
      return;
   }

   // For other browsers:
   // Create a link pointing to the ObjectURL containing the blob.
   const data = window.URL.createObjectURL(newBlob);
   var link = document.createElement('a');
   link.href = data;
   link.target = '_blank';
   link.download = title + '.pdf';

   document.body.appendChild(link);
   link.click();

   link.parentNode.removeChild(link);
}

let userData;

export const getUserEmail = () => {
   return userData.email;
};

export const getUserData = () => {
   return userData;
};

// Features defined:
export const SUB_PACKAGES = 'SUB_PACKAGES';
export const CUSTOMER_ENV = 'CUSTOMER_ENV';
export const SEE_ESTIMATES = 'SEE_ESTIMATES';

export const isFeatureEnabled = feature => {
   return getUserData().features.indexOf(feature) >= 0;
};

/**
 * Set the local storage defaults for pinned columns from the estimate types config.
 *
 * @param estimateTypes The estimate types for which to set the columns.
 */
const setDefaultPinnedColumns = (estimateTypes) => {
   const pinColumns =  localStorage.pinColumns ? JSON.parse(localStorage.pinColumns) : {};

   for (const estimateType of estimateTypes) {
      defaults(pinColumns,
         {[estimateType.name]: estimateType.pinned, [`Your${estimateType.name}`]: estimateType.pinned_individual});
   }
   localStorage.pinColumns = JSON.stringify(pinColumns);
}

/**
 * Set the estimate types config.
 *
 * @param data The user data.
 */
export const setEstimateConfig = (data) => {
   userData.estimateTypes = get(data, 'current_organization.estimate_types', {});
   userData.uuid = get(data, 'user.user_id', '');
   setDefaultPinnedColumns(userData.estimateTypes);
}

/**
 * Set the estimate types config.
 *
 * @param data The user data.
 */
export const setDefaultValuationType = (data) => {
   userData.defaultValuationType = get(data, 'current_organization.workflow_config.default_valuation_type', 'HeavyWorth');
}

/**
 * Get the label for the estimate type name.
 *
 * @param name The estimate type name.
 * @return {*|string} The display label for the estimate type.
 */
export const getEstimateLabel = (name) => {
   const estimateType = find(userData.estimateTypes, {name});
   return estimateType ? estimateType.display_name : 'N/A';
}

export const getWorkflowEnabled = (estimate) => {
   const estimateType = find(userData.estimateTypes, {name: estimate.estimate_type_name});
   return estimateType?.enable_workflow_columns && estimateType?.active;
}

export const setUserData = (theAuthData) => {
   userData = {};
   userData.username = get(theAuthData, 'username');
   userData.name = get(theAuthData, 'attributes.name');
   userData.email = get(theAuthData, 'attributes.email');
   const features = get(theAuthData, 'signInUserSession.idToken.payload.feature_flags', []);
   userData.features = features && features.length > 0 ? features.split('|') : [];
   const groups = get(theAuthData, 'signInUserSession.idToken.payload["cognito:groups"]', []);
   userData.isPlatformAdmin = groups.indexOf('PlatformAdmin') >= 0;
   // TODO add the group name for Admin.
   userData.isAdmin = userData.isPlatformAdmin;// || groups.indexOf("Admin") >= 0;
   userData.isFilesOnly = groups.indexOf('FilesOnly') >= 0;
   userData.groups = difference(groups, ['PlatformAdmin', 'FilesOnly', 'Evaluator']);
   userData.promptEmailSubscription = undefined;
   userData.sendEmails = get(theAuthData, 'signInUserSession.idToken.payload.send_emails');
   userData.multipleOrganizations = get(theAuthData, 'signInUserSession.idToken.payload.multiple_organizations', 'false') === 'true'
   const orgs = get(theAuthData, 'signInUserSession.idToken.payload.organizations', []);
   const organization_names = orgs && orgs.length > 0 ? orgs.split('|') : [];
   const org_keys = get(theAuthData, 'signInUserSession.idToken.payload.organization_keys', []);
   const organization_keys = org_keys && org_keys.length > 0 ? org_keys.split('|') : [];

   let organizations = []
   each(organization_keys, (element, index, _) => {
      let org_name = organization_names[index]
      organizations[index] = {name: org_name, organization_id: element}
   })
   userData.organizations = organizations;

   const valuationReasons = get(theAuthData, 'signInUserSession.idToken.payload.valuation_reasons') || VALUATION_REASONS;
   userData.valuation_reasons = valuationReasons.split('|');

   const valuationReasonDefault = get(theAuthData, 'signInUserSession.idToken.payload.default_valuation_reason') || VALUATION_REASONS_DEFAULTS;
   userData.valuation_reason_default = valuationReasonDefault.split('|');
};

export const setPromptEmailSubscription = isPrompt => {
   userData.promptEmailSubscription = isPrompt;
};

export const setSendEmails = isSendEmails => {
   userData.sendEmails = isSendEmails;
};

export const isFilesOnly = () => {
   return userData && userData.isFilesOnly;
};

export const isNormalUser = () => {
   return userData && !userData.isFilesOnly;
};

export const isAdminOnly = () => {
   return userData && userData.isAdmin;
};

export const isMultipleOrganization = () => {
   return userData && !userData.isAdmin && userData.multipleOrganizations;
};

const styles = {
   progress: {
      position: 'fixed',
      top: 120,
      left: 10,
      zIndex: 1001,
   },
};

class Request extends Component {

   static propTypes = {
      uri: PropTypes.string,
      children: PropTypes.func.isRequired,
      data: PropTypes.object,
      data2: PropTypes.object,
      method: PropTypes.oneOf(['get', 'post', 'put']),
      body: PropTypes.object,
      showOnLoad : PropTypes.bool,
      errorMessageId: PropTypes.string.isRequired,
      errorMessage: PropTypes.string,
      errorValues: PropTypes.string,
      showProgress: PropTypes.bool,
      refresh: PropTypes.any,
   };

   static defaultProps = {
      method: 'get',
      showOnLoad: true,
      showProgress: true,
      refresh: 0,
   };

   constructor(props) {
      super(props);

      this.state = {
         isLoading: false,
         data: undefined,
         data2: undefined,
         showError: false,
      };
   }

   /**
    * Load the data at the URI or the match.url. If data is supplied as a prop, the load is skipped and data is used. If
    * data isn't supplied, but data2 is, the data will be retrieved and data2 will be used instead of being retrieved.
    *
    * NOTE: data2 will NOT be requested from the server if data is supplied as a prop.
    *
    * PROPS:
    *    data, data2    -> returns data and data2
    *    data, !data2   -> returns data
    *    !data, data2   -> retrieves data and returns data2
    *    !data, !data2  -> retrieves data and retrieves data2
    *
    * @param props   The props to be used for the options.
    * @return {Promise<void>}
    */
   load = async (props) => {
      const { match, method, body, onNext, uri, onLoaded, data, data2} = props;

      if (!data) {
         this.setState({ isLoading: true });
         try {
            let useUri;
            if (uri) {
               useUri = typeof uri === 'string' ? uri : uri(props);
               if (useUri === undefined) {
                  this.setState({isLoading: false});
                  return;
               }
            } else {
               useUri = match.url
            }
            const result = await requestForServer(useUri, method, body);
            if (result.error) {
               this.setState({isLoading: false, showError: true, ...result});
            } else {
               let state = {
                  isLoading: false,
                  showError: false,
                  data: result.data || [],
                  errorMessage: result.errorMessage,
                  data2,
               };
               if (onNext && !data2) {
                  const nextUri = onNext(result, this);
                  if (nextUri) {
                     const result2 = await requestForServer(nextUri, undefined, undefined);
                     state.data2 = Array.isArray(result2.data) ? result2.data[0] : result2.data;
                  }
               }
               this.setState(state, () => {
                  if (onLoaded) {
                     onLoaded(result.data, state.data2);
                  }
               });
            }
         } catch (error) {
            console.log(error);
            this.setState({ isLoading: false, showError: true, ...error });
         }
      } else {
         this.setState({isLoading: false, showError: false, data, data2, errorMessage: undefined}, () => {
            if (onLoaded) {
               onLoaded(data, data2);
            }
         });
      }
   };

   /**
    * Load the data from the server using the match URL.
    * @return {Promise<void>}
    */
   componentWillMount() {
      this.load(this.props);
   }

   componentWillReceiveProps(nextProps) {
      const { refresh, method, body, match } = nextProps;
      if ((refresh && refresh > this.props.refresh) ||
         (match && this.props.match && (match.url !== this.props.match.url)) || method !==
         this.props.method || !isEqual(body, this.props.body)) {
            this.load(nextProps);
      } else if (!isEqual(nextProps.data, this.props.data)) {
         if (moment(nextProps.data.updated).isAfter(this.state.data.updated)) {
            this.setState({data: nextProps.data});
         }
      }
   }

   /**
    * Close the error snackbar.
    */
   handleErrorClose = () => {
      this.setState({ showError: false });
   };

   renderChildren = () => {
      const { propKey, propKeyNext, children } = this.props;
      const { isLoading, error, data, data2 } = this.state;

      if (typeof children === 'function') {
         return children(data, isLoading, data2, error);
      } else if (typeof children === 'object') {
         return React.Children.map(children, child => {
            return React.cloneElement(child, {
               isLoading,
               [ propKey ]: data,
               [ propKeyNext ]: data2,
               error,
            });
         });
      }
   };

   render() {
      const { classes={}, body, method, onNext, showOnLoad, showProgress, errorMessageId, onLoaded, isLoading:unused,
         errorMessage, errorValues:errorValuesProp, children, propKey, propKeyNext, staticContext, ...props} = this.props;
      const { isLoading, showError, errorValues, error, } = this.state;

      if (get(error, 'response.status') === 404) {
            return <Error404/>
      }

      props.classes = classes;

      return (
         <React.Fragment>
            {showError && (
               <ErrorSnackbar open={showError} onClose={this.handleErrorClose} errorId={errorMessageId}
                              values={errorValues || errorValuesProp} message={errorMessage}/>
            )}
            {showProgress && !showError && isLoading && <CircularProgress className={classes.progress}/>}
            {(showOnLoad || !isLoading) && this.renderChildren()}
         </React.Fragment>
      )
   }
}

export default withRouter(withStyles(styles)(Request));

/**
 *
 * @param WrappedComponent The component which will use the data.
 * @param options The options for the query
 *    method The method of the request (e.g. get, post, put). Default is get.
 *    propKey   The result property name of the data.
 *    propKeyNext   The result property name of the data requested onNext.
 *    errorMessageId The message id for the error message.
 *    errorMessage The message for the error message.
 *    showOnLoad True if the component handles loading indicator. Defaults to true.
 *    showProgress: True if the componenet should show the progress indicator. Defaults to true.
 * @return {<{}> | *} The HOC.
 */
export const withRequest = (options) => WrappedComponent => {
   if (!options.propKey) {
      options.propKey = 'data';
   }
   if (!options.propKeyNext) {
      options.propKeyNext = 'data2';
   }

   if (!options.errorMessageId) {
      options.errorMessageId = 'fetch.error';
   }

   class RequestHOC extends Component {
      state = {
         refresh: undefined,
      };

      handleRefresh = () => {
         this.setState({refresh: Date.now()});
      };

      render() {
         const { classes, ...props } = this.props;
         const { propKey, propKeyNext } = options;
         const { refresh } = this.state;

         let dataProps = {};

         return (
            <Request refresh={refresh} {...this.props} {...options}>
               {(data, isLoading, data2, error) => {
                  if (!isLoading) {
                     dataProps[propKey] = data;
                     if (data2) {
                        dataProps[propKeyNext] = data2;
                     }
                  }

                  return (
                     <WrappedComponent {...props} error={error} isLoading={isLoading} {...dataProps}
                                       onRefresh={this.handleRefresh}/>
                  )
               }}
            </Request>
         )
      }
   }

   return RequestHOC;
};


import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import MenuIcon from '@material-ui/icons/Menu';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import DocumentTitle from 'react-document-title';
import {withRouter} from 'react-router-dom';
import 'react-table/react-table.css';
import {
   APP_BAR_HEIGHT, REFRESH_DELAY, DEFAULT_LOCATION, SECONDARY_HEADER_HEIGHT, CATALOG_HEADER_HEIGHT,
} from '../../../Constants';
import AssetNavigationHeader from './AssetNavigationHeader';
import EquipmentImageDetail from './EquipmentImageDetail';
import {sortedItems} from '../list/EquipmentList';

const styles = theme => ({
   root: {
      height: `calc(100% - ${APP_BAR_HEIGHT}px) !important`,
   },
   rootNoAppBar: {
      height: `100% !important`,
   },
   inner: {
      backgroundColor: theme.palette.environment.light.level0.base,
      flex: '1 1',
      maxWidth: '100%',
      overflow: 'hidden',
   },
   menuButton: {
      color: theme.palette.button.standard.secondary,
      backgroundColor: 'white',
      '-webkit-filter': 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      '&:hover': {
         backgroundColor: 'white',
      },
   },
   progress: {
      position: 'fixed',
      top: APP_BAR_HEIGHT + SECONDARY_HEADER_HEIGHT + CATALOG_HEADER_HEIGHT + 10,
      left: 10,
      zIndex: 1001,
   },
});

/**
 * Component to show the equipment detail.
 */
class EquipmentDetailMain extends React.Component {

   static propTypes = {
      classes: PropTypes.any.isRequired,        // The component style.
      location: PropTypes.object.isRequired,    // The React Router location.
      match: PropTypes.object.isRequired,       // The Router match.
      history: PropTypes.object.isRequired,     // The Router history.
      evaluation: PropTypes.object,             // The evaluation containing the equipment.
      assets: PropTypes.array,                  // The list of assets (i.e. evaluation.items), but the sorted list.
      evalItem: PropTypes.object,                   // The equipment data.
      onRefresh: PropTypes.func.isRequired,     // Callback when the photos have been added and needs a refresh.
   };

   static defaultProps = {
      evaluation: {},
      evalItem: {},
   };

   constructor(props, context) {
      super(props, context);

      const {isZoomed, isEdit, isNew} = get(props, 'location.state', {});
      this.state = {
         isThumbnailsSelected: false,
      };

      document.addEventListener('keydown', this.handleKey, false);
      if (!isNew && !get(props, 'match.params.id')) {
         props.history.push(DEFAULT_LOCATION);
      } else if (isZoomed) {
         props.history.push(props.match.url, {isZoomed: false, isEdit, isNew});
      }
   }

   /**
    * Handle arrow keys for selecting thumbnails.
    * @param event The key event.
    */
   handleKey = (event) => {
      if (!event.defaultPrevented) {

         if ((event.key === 'Escape') && this.props.isDrawerOpen) {
            event.preventDefault();
            this.props.onDrawerClose();
         }
      }
   };

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   /**
    * Go back to the equipment list.
    */
   back = () => {
      this.props.history.push(this.props.evaluation.uri);
   };

   /**
    * When the photos are submitted, this view needs to be refreshed to use the new photos.
    *
    * @param item The item in the view. True if the view needs to be reloaded from the server.
    * @param isRefreshList Indicates if the evaluation list should be refreshed.
    * @param totalPhotos The total number of photos for the item.
    */
   onRefresh = (item, isRefreshList, totalPhotos) => {
      const {onRefresh} = this.props;

      if (totalPhotos) {
         setTimeout(() => {
            const imageCount = get(this.props, 'evalItem.image_data.length', 0);
            if (!imageCount || totalPhotos > imageCount) {
               console.log(
                  `Refreshing to get last images. imageCount = ${imageCount} and totalPhotos = ${totalPhotos}`);
               onRefresh(item, isRefreshList, totalPhotos);
            }
         }, REFRESH_DELAY);
      }
      return onRefresh(item, isRefreshList, totalPhotos);
   };

   /**
    * When the user selects to go to the next item.
    */
   onNext = () => {
      mixpanel.track('Next Item');
      const {history, evaluation} = this.props;
      const selectedIndex = this.getSelectedIndex();
      const list = sortedItems && sortedItems.length > 0 ? sortedItems : evaluation.items;

      history.push(list[selectedIndex + 1].uri);
   };

   /**
    * When the user selects to go to the previous item.
    */
   onPrevious = () => {
      mixpanel.track('Previous Item');
      const {history, evaluation} = this.props;
      const selectedIndex = this.getSelectedIndex();
      const list = sortedItems && sortedItems.length > 0 ? sortedItems : evaluation.items;

      history.push(list[selectedIndex - 1].uri);
   };

   /**
    * Get the selected index of the evaluation items by matching the URI to the current browser URL.
    *
    * @return {*} The index of the selected item, or 0 of no item is found.
    */
   getSelectedIndex = () => {
      const {evaluation: evaluationProp, match} = this.props;
      const evaluation = evaluationProp;
      const list = sortedItems && sortedItems.length > 0 ? sortedItems : (evaluation && evaluation.items) || [];

      if (list) {
         let selectedIndex = (list.findIndex((item1) => match.url.indexOf(item1.uri) >= 0)) || 0;
         return selectedIndex < 0 ? 0 : selectedIndex;
      }
      return 0;
   };

   /**
    * When the user turns on or off zoom.
    *
    * @param isZoomed True if the zoom is being turned on.
    */
   onZoom = (isZoomed) => {
      const {history, match, location} = this.props;
      history.push(match.url, {...location.state, isZoomed});
   };

   handleThumbnailsSelected = isThumbnailsSelected => {
      this.setState({isThumbnailsSelected})
   };

   render() {
      const {classes, location, evalItem, evaluation, error, isLoading, onDrawerOpen, isDrawerOpen} = this.props;
      const {isThumbnailsSelected} = this.state;

      const total = sortedItems && sortedItems.length ? sortedItems.length : get(evaluation, 'item_count', 1);
      let selectedIndex = this.getSelectedIndex();
      const {isNew, isZoomed} = get(location, 'state', {});

      const item = isNew ? {} : get(evalItem, 'item', {});
      let title = '';
      if (get(evaluation, 'evaluate', item.owner)) {
         title += ' - ' + get(evaluation, 'evaluation.owner_name', item.owner) + ' ';
      }
      title = (title || ' - ') + get(item, 'description', 'N/A');

      title = process.env.REACT_APP_SITE_TITLE + title;

      return (
         <DocumentTitle title={title}>
            <Fragment>
               {isLoading && <CircularProgress className={classes.progress}/>}
                  <Grid container className={classes.inner} name={'EquipmentDetailMain-container'} direction={'column'}
                        wrap={'nowrap'} spacing={0}>
                     {!isThumbnailsSelected && (
                        <AssetNavigationHeader zoomed={isZoomed} onBack={this.back} evaluation={evaluation}
                                               selectedIndex={selectedIndex} isNew={isNew} onPrevious={this.onPrevious}
                                               item={item} total={total} onNext={this.onNext} isDrawerOpen={isDrawerOpen} onDrawerOpen={onDrawerOpen}
                        />
                     )}
                     <EquipmentImageDetail key={item && item.SK} evalItem={!isNew && evalItem} isError={!!error}
                                           onZoom={this.onZoom} evaluation={evaluation} onBack={this.back}
                                           onThumbnailsSelected={this.handleThumbnailsSelected}
                                           onRefresh={this.onRefresh}>
                        {isZoomed &&
                        <IconButton className={classes.menuButton} aria-label='Open drawer'
                                    onClick={onDrawerOpen}
                                    style={{
                                       position: 'absolute', top: 16, right: 72, zIndex: 2000,
                                       display: isDrawerOpen ? 'none' : undefined
                                    }}>
                           <MenuIcon/>
                        </IconButton>
                        }
                     </EquipmentImageDetail>
                  </Grid>
            </Fragment>
         </DocumentTitle>
      );
   }
}

export default withRouter(withWidth()(withStyles(styles)(EquipmentDetailMain)));
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import findLastIndex from 'lodash/findLastIndex';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter, Link} from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
   CLICK_DELAY_FOR_SELECTION, DATE_FORMAT, STATUS_ORDER, HEADER_HEIGHT_EVAL_TABLE, UP_ARROW,
   HEADER_ICON_WIDTH_EVAL_TABLE, DOWN_ARROW, DEFAULT_STATUS_ORDER, GET_ARCHIVED_EVALUATIONS,
} from '../../../Constants';
import DisplayError from '../../../fhg/components/DisplayError';
import FHGTypography from '../../../fhg/components/Typography';
import Typography from '../../../fhg/components/Typography';
import {formatMessage, sortStatus} from '../../../fhg/utils/Utils';
import {withRequest, requestForServer, CUSTOMER_ENV, isFeatureEnabled} from '../../../Utils/ServerUtil';

const requestStyles = theme => ({
   progress: {
      position: 'absolute',
      marginLeft: '47%',
      top: 40,
   },
});

const styles = theme => ({
   table: {
      maxHeight: `calc(100% - ${theme.spacing(4) + 12}px)`,
      width: '100%',
      border: 'none',
      userSelect: 'none',
      overflow: 'auto',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         maxHeight: '100%',
         overflow: 'hidden',
         position: 'relative',
      },
      '@media print': {
         display: 'table',
      },
      '& .-sort-desc .offset.sortContent::after': {
         transform: 'translateY(-2px)',
      },
      '& .-sort-asc .offset.sortContent::after': {
         transform: 'translateY(-2px)',
      },
      '& .-sort-desc .sortContent::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         '@supports not (-ms-high-contrast: none)': {
            backgroundColor: theme.palette.button.standard.secondary,
            '-webkit-mask': `url(${DOWN_ARROW}) no-repeat`,
            mask: `url(${DOWN_ARROW}) no-repeat`,
         },
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.5,
            background: `url(${DOWN_ARROW}) no-repeat`,
         },
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
      '& .-sort-asc .sortContent::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         '@supports not (-ms-high-contrast: none)': {
            backgroundColor: theme.palette.button.standard.secondary,
            '-webkit-mask': `url(${UP_ARROW}) no-repeat`,
            mask: `url(${UP_ARROW}) no-repeat`,
         },
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.5,
            background: `url(${UP_ARROW}) no-repeat`,
         },
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
   },
   emptyHeight: {
      minHeight: 400,
      height: `calc(100% - ${theme.spacing(4) + 12}px)`,
   },
   progress: {
      position: 'absolute',
      marginLeft: '47%',
      top: 40,
   },
   columnTitle: {
      paddingTop: 3,
      color: theme.palette.content.nonessential, //'rgba(66,51,19,0.4)',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: '17px',
   },
   selected: {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
         backgroundColor: `${theme.palette.action.selected} !important`,
      },
   },
   menuText: {
      color: theme.palette.text.secondary,
      display: 'inline-block',
   },
   notSort: {
      '&.offset.sortContent:hover::after': {
         transform: 'translateY(-2px)',
      },
      '&.sortContent:hover::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         filter: 'opacity(30%)',
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.3,
         },
         background: `url(${UP_ARROW}) no-repeat`,
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
   },
   linkStyle: {
      marginTop: -8,
      height: 'calc(100% + 16px)',
      background: 'lightgrey',
      textDecoration: 'none',
   },
   defaultTextStyle: {
      fontSize: `${theme.size.font.text}rem !important`,
      color: theme.palette.text.secondary, //'rgba(66,51,19,0.8)',
   },
   dot: {
      height: 8,
      width: 8,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
   },
   capture_in_progress: {
      backgroundColor: theme.palette.status.captureInProgress, //'#7ED321',
   },
   eval_requested: {
      backgroundColor: theme.palette.status.evalRequested, //'#F8CE1C',
   },
   eval_in_progress: {
      backgroundColor: theme.palette.status.evalInProgress, //'#4A90E2',
   },
   in_review: {
      backgroundColor: theme.palette.status.inReview, //'#F57A23',
   },
   finalized: {
      backgroundColor: theme.palette.status.finalized, //'#406370',
   },
   archived: {
      backgroundColor: theme.palette.status.archived, //'#D8D8D8',
   },
});

/**
 * The evaluator dashboard table of evaluations.
 *
 * Last Reviewed 11/12/18
 */
class EvalDashboardTable extends React.PureComponent {
   static DATE_MISSING;
   blockScroll = false;

   static propTypes = {
      intl: PropTypes.any.isRequired,           // Localization object for localizing text.
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      history: PropTypes.object.isRequired,     // Browser location history.
      match: PropTypes.object.isRequired,       // Location matching and params information.
      search: PropTypes.string,                 // The search/filter text to limit rows to those containing search.
      filter: PropTypes.string,                 // The workflow status filter to limit rows to those with that status.
      data: PropTypes.any,                      // The list of evaluations.
      saveSort: PropTypes.bool,                 // Save the sort to session storage.
   };

   static defaultProps = {
      filter: DEFAULT_STATUS_ORDER,
      search: '',
      saveSort: true,
   };

   constructor(props, context) {
      super(props, context);

      // Read the table settings from session storage to restore when the user comes back to this page.
      const evalTableString = sessionStorage.evalTable;
      const evalTable = evalTableString ? JSON.parse(evalTableString) : {sort: [{id: 'evalUpdate', desc: true}]};

      // Find the last URL that isn't this page.
      const locations = props.history.getRecentLocations();
      const index = locations && findLastIndex(locations, (item) => {
         return item.pathname !== props.match.url
      });

      //Set the selected URI to the last URL that isn't this page.
      this.state = {
         selected: undefined,
         selectedUri: index >= 0 ? locations[index].pathname : undefined,
         defaultSort: evalTable.sort,
         filteredData: this.onSearch(props.search, props.filter, this.getColumns(evalTable.sort), props.data),
         sort: evalTable.sort,
         archivedData: [],
      };

      if (props.filter.indexOf('archived') >= 0) {
         setTimeout(async () => {
            let archivedData = await this.requestArchivedData(props.filter);

            const filteredData = this.onSearch(this.props.search, props.filter, this.getColumns(), this.props.data,
               archivedData);
            this.setState({filteredData, archivedData});
         }, 2);
      }
      EvalDashboardTable.DATE_MISSING = formatMessage(props.intl, 'date.missing', 'N/A');
   }

   componentWillReceiveProps(nextProps) {
      const {search, filter} = this.props;

      const isDataChanged = !isEqual(nextProps.data, this.props.data);
      if (filter !== nextProps.filter && nextProps.filter.indexOf('archived') >= 0 &&
         (!this.state.archivedData || this.state.archivedData.length <= 0)) {
         setTimeout(async () => {
            let archivedData = await this.requestArchivedData(nextProps.filter);

            const filteredData = this.onSearch(nextProps.search, nextProps.filter, this.getColumns(), nextProps.data,
               archivedData);
            this.setState({filteredData, archivedData});
         }, 2);
      } else if (filter !== nextProps.filter || search !== nextProps.search || isDataChanged) {
         const filteredData = this.onSearch(nextProps.search, nextProps.filter, this.getColumns(), nextProps.data,
            this.state.archivedData);
         this.setState({filteredData});
      }
   }

   componentDidUpdate() {
      this.scrollIntoView();
   }

   /**
    * Sort based on the two dates. Used to sort the date columns.
    *
    * NOTE: This is similar to the common sortDate, but uses a DATE_FORMAT.
    *
    * @param a The first date.
    * @param b The second date.
    * @return {number} 0, 1, -1 for equal, greater than, and less than respectively.
    */
   sortDate = (a, b) => {
      if (a === b) {
         return 0;
      }
      if (a === EvalDashboardTable.DATE_MISSING || b === EvalDashboardTable.DATE_MISSING) {
         return (a === EvalDashboardTable.DATE_MISSING) ? -1 : 1;
      }
      const aMoment = moment(a, DATE_FORMAT);
      const bMoment = moment(b, DATE_FORMAT);

      if (aMoment.isSame(bMoment)) {
         return 0;
      }
      return aMoment.isAfter(bMoment) ? 1 : -1;
   };

   /**
    * When the sort values change save them locally and in session storage.
    *
    * @param sort The new sort object from ReactTable.
    */
   onSortedChange = (sort) => {
      this.setState({ sort });
      if (this.props.saveSort) {
         sessionStorage.evalTable = JSON.stringify({ sort });
      }
   };

   /**
    * Indicates if the row is selected.
    *
    * @param row The row to check if it is selected.
    * @return {boolean} True if the row is selected and false otherwise.
    */
   isRowSelected = (row) => {
      const { selected, selectedUri } = this.state;

      if (row) {
         if (selected) {
            return selected === row.index;
         }
         if (selectedUri) {
            if (selectedUri.indexOf('items') >= 0) {
               if (row.original.items) {
                  const index = row.original.items.findIndex((item) => selectedUri.indexOf(item.uri) >= 0);
                  return index >= 0;
               }
            } else {
               return selectedUri.indexOf(decodeURI(row.original.uri)) >= 0;
            }
         }
      }

      return false;
   };

   /**
    * Scroll the selected table row into view. The selection is indicated by the 'this.props.classes.selected' class.
    */
   scrollIntoView = () => {
      if (this.blockScroll === false) {
         const elements = document.getElementsByClassName(this.props.classes.selected);
         let objDiv = document.getElementsByClassName('rt-tbody')[ 0 ];
         if (objDiv && elements.length > 0) {
            objDiv.scrollTop = elements[ 0 ].offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
               (elements[ 0 ].offsetHeight / 2);
         }
      }
   };

   /**
    * Get the status value from the item using the column accessor.
    *
    * @param item The data item to get the status
    * @param columns The table columns.
    * @return {*} The status value.
    */
   getStatus = (item, columns) => {
      const statusAccessor = columns.find(column => column.id === 'evalStatus').accessor;
      return get(item, statusAccessor);
   };

   /**
    * When the user searches the table.
    *
    * @param search The search text to find.
    * @param filter The filter for the table.
    * @param columns The table columns. Used for the accessor to get the data from the column to search.
    * @param data The table data.
    * @param archivedData The table archived data.
    * @return {*|Array} The array of filtered rows.
    */
   onSearch = (search, filter, columns, data = [], archivedData = []) => {
      let filteredData = [];
      let dateSearch = moment(search);
      const isSearchDate = dateSearch.isValid();
      let isSearchHour = dateSearch.get('hour');
      let isSearchMinute = dateSearch.get('minute');
      let sourceData = archivedData.concat(data);

      // If all the filters are off no data is displayed.
      if (filter && filter.length > 0) {
         if ((search.length > 0 || filter.length < STATUS_ORDER.length) && sourceData) {
            search = search.toLocaleLowerCase().trim();
            filteredData = sourceData.filter(item => {
               //If all the filters are on or the status is in the filter list.
               if (filter.length >= STATUS_ORDER.length || filter.indexOf(this.getStatus(item, columns)) >= 0) {
                  for (let column of columns) {
                     let columnAccessor = column.accessor;
                     let value = typeof columnAccessor === 'function' ? columnAccessor(item) :
                        get(item, columnAccessor);

                     if (column.searchText) {
                        value = column.searchText(value);
                     }

                     if (value !== undefined && value !== null && typeof value !== 'object') {
                        const valueMoment = moment(value, DATE_FORMAT, true);
                        // noinspection JSCheckFunctionSignatures
                        if (isSearchDate &&
                           valueMoment.isSame(dateSearch, isSearchMinute ? 'minute' : isSearchHour ? 'hour' : 'day')) {
                           return true;
                        }
                        if (typeof value === 'string') {
                           if (value.toLocaleLowerCase().indexOf(search) >= 0) {
                              return true;
                           }
                        } else if (typeof value === 'number') {
                           if (Number(value) === Number(search)) {
                              return true;
                           }
                        }
                     }
                  }
               }
               return false;
            });
         } else {
            filteredData = sourceData;
         }
      }

      return filteredData;
   };

   /**
    * Request the archived evaluations from the server.
    *
    * @param newFilter The changed filters.
    * @param existingFilter The existing filters before the change.
    * @return {Promise<*>} The promise for the archived data to be returned.
    */
   requestArchivedData = async (newFilter = [], existingFilter = []) => {
      const isArchivedSet = newFilter.indexOf('archived') >= 0;
      const wasArchivedSet = existingFilter.indexOf('archived') >= 0;
      let archivedData;

      if (isArchivedSet !== wasArchivedSet) {
         if (!isArchivedSet) {
            archivedData = [];
         } else {
            try {
               const result = await requestForServer(GET_ARCHIVED_EVALUATIONS);
               archivedData = result.data;
            } catch (error) {
               archivedData = [];
               this.setState({showError: true, errorId: 'evalDashboard.fetch.error', error});
            }
         }
      } else {
         archivedData = this.state.archivedData;
      }

      return archivedData;
   };

   /**
    * Get the table columns for the evaluations table. Used for the evaluations table, but also used for the filtering
    * and searches to access the column data.
    *
    * @param defaultSort The sort to initialize the table with. Used only in the constructor before the state is set.
    * @return {*[]} The array of evaluation table columns.
    */
   getColumns = (defaultSort) => {
      const { classes, intl } = this.props;

      const sortColumnId = get(this, 'state.sort[0].id', get(defaultSort, '[0].id'));

      return [
         {
            id: 'evalUser',
            Header: (
               <Typography className={classNames('sortContent', classes.columnTitle,
                  {[classes.notSort]: sortColumnId !== 'evalUser'})} id={'evalDashboard.primaryContact.column'}>
                  Primary Contact
               </Typography>
            ),
            accessor: 'evaluation.primary_contact',
            minWidth: 120,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalOwner',
            Header: (
               <Typography className={classNames('sortContent', classes.columnTitle,
                  {[classes.notSort]: sortColumnId !== 'evalOwner'})} id={'evalDashboard.equipmentOwner.column'}/>
            ),
            accessor: 'evaluation.owner_name',
            minWidth: 150,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalItems',
            Header: <Typography className={classNames('sortContent', classes.columnTitle,
               {[classes.notSort]: sortColumnId !== 'evalItems'})}
                                id={'evalDashboard.item.column'}>Items</Typography>,
            accessor: 'item_count',
            width: 90,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalPhotos',
            Header: <Typography className={classNames('sortContent', classes.columnTitle,
               {[classes.notSort]: sortColumnId !== 'evalPhotos'})}
                                id={'evalDashboard.photos.column'}>Photos</Typography>,
            accessor: 'image_count',
            width: 100,
            maxWidth: 100,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalDate',
            Header: <Typography className={classNames('sortContent', classes.columnTitle,
               {[classes.notSort]: sortColumnId !== 'evalDate'})}
                                id={'evalDashboard.dateCreated.column'}>Date
               Created</Typography>,
            minWidth: 120,
            accessor: EvalDashboardTable.getEvalDate,
            sortMethod: this.sortDate,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalUpdate',
            Header: <Typography className={classNames('sortContent', classes.columnTitle,
               {[classes.notSort]: sortColumnId !== 'evalUpdate'})}>Date
               Updated</Typography>,
            minWidth: 120,
            accessor: EvalDashboardTable.getEvalUpdate,
            sortMethod: this.sortDate,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalStatus',
            Header: (
               <Grid container
                     className={classNames('offset', 'sortContent',
                        {[classes.notSort]: sortColumnId !== 'evalStatus'})}
                     wrap={'nowrap'} alignItems={'center'}>
                  <Typography className={classes.columnTitle} id={'evalDashboard.status.column'}>Status</Typography>
               </Grid>
            ),
            accessor: 'evaluation.workflow_status',
            searchText: (value) => formatMessage(intl, value, ''),
            Cell: this.getEvalStatusCell,
            sortMethod: sortStatus,
            width: 225,
         },
      ];
   };

   getEvalStatusCell = row => {
      const {classes} = this.props;
      const status = get(row, 'original.evaluation.workflow_status');

      if (!isFeatureEnabled(CUSTOMER_ENV)) {
         return (
            <Grid container direction={'row'} alignItems={'center'} style={{height: '100%'}}>
               <span className={classNames(classes.dot, classes[status])}/>
               <Typography className={classes.menuText} color='inherit' variant='body1' id={status}/>
            </Grid>
         );
      } else {
         return (
            <Typography
               id={status !== 'capture_in_progress' ? 'customerInventory.sentToPurpleWave.status' :
                  'customerInventory.notSentToPurpleWave.status'}/>
         );
      }
   };

   static getEvalDate(row) {
      if (row.evaluation && row.evaluation.created) {
         return moment(row.evaluation.created).format(DATE_FORMAT);
      } else {
         return EvalDashboardTable.DATE_MISSING;
       }
   }

   static getEvalUpdate(row) {
      if (row.updated) {
         return moment(row.updated).format(DATE_FORMAT);
      } else {
         return EvalDashboardTable.DATE_MISSING;
      }
   }

   render() {
      const {defaultSort, filteredData, errorId, error, showError} = this.state;
      const { classes, isLoading, history, search } = this.props;

      return (
         <Fragment>
            {showError &&
            <DisplayError error={error} errorId={errorId} enableRefresh={errorId !== 'evalDashboard.consensus.error'}/>}
            <ReactTable defaultPageSize={10000} minRows={1} loading={isLoading}
                        LoadingComponent={() => isLoading && <CircularProgress className={classes.progress}/>}
                        multiSort={false} className={classNames(classes.table, '-highlight', 'eval-table',
               {[classes.emptyHeight]: !filteredData || filteredData.length <= 0})}
                        data={filteredData}
                        defaultSorted={defaultSort}
                        onSortedChange={this.onSortedChange}
                        showPagination={false}
                        noDataText={isLoading ? '' : (<FHGTypography id={search ? 'search.noCatalogsFound.text' : undefined}/>)}
                        getTrProps={(state, rowInfo) => {
                           return {
                              className: this.isRowSelected(rowInfo) ? classes.selected : undefined,
                              onClick: (e) => {
                                 e.preventDefault();
                                 e.stopPropagation();
                                 if (!this.blockScroll) {
                                    EvalDashboardTable.lastSelectedIndex = rowInfo.index;
                                    this.blockScroll = true;
                                    mixpanel.track('Equipment List View');
                                    this.setState({selected: rowInfo.index}, () => {
                                       window.setTimeout(() => {
                                          this.blockScroll = false;
                                          history.push(rowInfo.original.uri);
                                       }, CLICK_DELAY_FOR_SELECTION);
                                    });
                                 }
                              },
                           }
                        }}

                        columns={this.getColumns()}
            />
         </Fragment>
      );
   }
}

export default React.memo(withStyles(requestStyles)(withRouter(withRequest({
   showProgress: false,
   showOnLoad: true,
   errorMessageId: 'evalDashboard.fetch.error',
})(injectIntl(withStyles(styles)(EvalDashboardTable))))));

const googleConfig = {
   'client_id': '215642238947-utp3coq4stion4kd4hgqmsde7opuvkkn.apps.googleusercontent.com',
   'project_id': 'quickstart-1576697459038',
   'auth_uri': 'https://accounts.google.com/o/oauth2/auth',
   'token_uri': 'https://oauth2.googleapis.com/token',
   'auth_provider_x509_cert_url': 'https://www.googleapis.com/oauth2/v1/certs',
   'client_secret': 'vB4W2fIRAS7bpezXuPy7knhb',
   'redirect_uris': [
      'http://localhost:8080', 'https://app.staging.heavyworth.net', 'https://app.purplewave.heavyworth.net',
      'https://app.heavyworth.com', 'http://localhost:3000', 'https://dev3.heavyworth.com'
   ],
   'javascript_origins': [
      'http://localhost:8080', 'https://app.staging.heavyworth.net', 'https://app.purplewave.heavyworth.net',
      'https://app.heavyworth.com', 'http://localhost:3000', 'https://dev3.heavyworth.com'
   ],
   discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
   scope: "https://www.googleapis.com/auth/spreadsheets"
};

export default googleConfig;
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'react-table/react-table.css';
import { STATUS_ORDER } from '../../../Constants';
import Typography from '../../../fhg/components/Typography';

const styles = theme => ({
   dot: {
      height: 8,
      width: 8,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
   },
   'capture_in_progress': {
      backgroundColor: theme.palette.status.captureInProgress, //'#7ED321',
   },
   'eval_requested': {
      backgroundColor: theme.palette.status.evalRequested, //'#F8CE1C',
   },
   'eval_in_progress': {
      backgroundColor: theme.palette.status.evalInProgress, //'#4A90E2',
   },
   'in_review': {
      backgroundColor: theme.palette.status.inReview, //'#F57A23',
   },
   finalized: {
      backgroundColor: theme.palette.status.finalized, //'#406370',
   },
   archived: {
      backgroundColor: theme.palette.status.archived, //'#D8D8D8',
   },
   menuText: {
      display: 'inline-block',
   },
   image: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2) - 4,
   },
   clear: {
      borderTop: `1px solid ${theme.palette.environment.light.level1.accent}`,
      backgroundColor: theme.palette.environment.light.level1.base,
      marginBottom: theme.spacing(-1),
   },
   statusCheckbox: {
      color: `${theme.palette.button.standard.secondary} !important`,
   },
   mouseTracker: {
      '&:focus': {
         outline: 'none',
      }
   },
});

/**
 * Component to show the workflow status filter menu. (Not the workflow select menu).
 *
 * Last Reviewed: 11/12/18
 */
class StatusFilterMenu extends Component {

   static propTypes = {
      classes: PropTypes.object,          // The styles for the component.
      isOpen: PropTypes.bool,             // Indicates if the menu is open or closed.
      anchorEl: PropTypes.any,            // The anchor element for the menu (See Menu prop).
      onClose: PropTypes.func.isRequired, // The callback when the menu is closed.
      filters: PropTypes.array,           // The list of initial filters.
   };

   static defaultProps = {
      isOpen: false,
      filters: STATUS_ORDER,
   };

   constructor(props) {
      super(props);

      this.state = {
         capture_in_progress: props.filters.indexOf('capture_in_progress') >= 0,
         eval_requested: props.filters.indexOf('eval_requested') >= 0,
         eval_in_progress: props.filters.indexOf('eval_in_progress') >= 0,
         in_review: props.filters.indexOf('in_review') >= 0,
         finalized: props.filters.indexOf('finalized') >= 0,
         archived: props.filters.indexOf('archived') >= 0,
         selectAll: props.filters.length >= STATUS_ORDER.length,
      };
   }

   /**
    * Handle the filter menu close.
    */
   handleFilterClose = () => {
      let enabledFilters = this.getEnabledFilters();
      this.props.onClose(enabledFilters);
   };

   /**
    * Get the enabled filters from the state.
    *
    * @return {string[]} The array of enabled filters.
    */
   getEnabledFilters = () => {
      return STATUS_ORDER.filter(status => this.state[ status ] === true);
   };

   /**
    * When the user clicks the status menu item.
    * @param name The name of the status.
    * @return {Function} The callback for the onClick for the named status.
    */
   onClick = name => (event) => {
      event.stopPropagation();
      event.preventDefault();

      const countEnabled = this.getEnabledFilters().length;
      const checked = !this.state[ name ];
      if (checked || countEnabled > 1) {
         this.setState({ [ name ]: checked, selectAll: checked && countEnabled >= (STATUS_ORDER.length - 1) });
      }
   };

   /**
    * Select all the filters.
    */
   selectAllFilters = (event) => {
      event.stopPropagation();
      event.preventDefault();

      let state = this.state;

      state.selectAll = true;

      for (const status of STATUS_ORDER) {
         state[ status ] = true;
      }
      this.setState(state);
   };

   render() {
      const { classes, isOpen, anchorEl } = this.props;

      return (
         <Menu
            id='status-menu-id' style={{ userSelect: 'none' }}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={this.handleFilterClose}
            onClick={(e) => {
               e.stopPropagation();
               e.preventDefault();
            }}
         >
            <span className={classes.mouseTracker}>
               {STATUS_ORDER.map(status => (
                  <MenuItem key={status} onClick={this.onClick(status)}>
                     <Checkbox className={classes.statusCheckbox} checked={!!this.state[ status ]}/>
                     <span className={classNames(classes.dot, classes[ status ])}/>
                     <Typography className={classes.menuText} color='inherit' variant='body1' id={status}/>
                  </MenuItem>
               ))}
               <MenuItem className={classes.clear} TransitionComponent={null} onClick={this.selectAllFilters}
                         disabled={this.getEnabledFilters().length >= STATUS_ORDER.length}>
                     <Checkbox className={classes.statusCheckbox} checked={this.state.selectAll}
                               onChange={this.selectAllFilters}/>
                  <Typography className={classes.menuText} color='inherit' variant='body1' id={'selectAll.label'}>
                     Select All
                  </Typography>
               </MenuItem>
            </span>
         </Menu>
      );
   }
}

export default withStyles(styles)(StatusFilterMenu);


import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {sortBy, find} from 'lodash';
import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useState} from 'react';
import {GET_ORGANIZATIONS} from '../../../Constants';
import DisplayError from '../../../fhg/components/DisplayError';
import FHGTypography from '../../../fhg/components/Typography';
import {requestForServer} from '../../../Utils/ServerUtil';

const useStyles = makeStyles(theme => ({
   formControl: {
      color: 'inherit',
      backgroundColor: 'transparent',
      '&:focus': {
         backgroundColor: 'transparent',
      }
   },
   titleStyle: {
      fontSize: '14px !important',
      fontWeight: 500,
      lineHeight: '17px',
      textAlign: 'center',
      color: theme.palette.secondary.contrastText,
      textTransform: 'none',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
         whiteSpace: 'wrap',
      }
   },
   selectStyle: {
      color: theme.palette.environment.light.level3.base, //'#E6E3DC',
      '& svg': {
         fill: theme.palette.environment.light.level3.base, //'#E6E3DC'
      },
   },
   menuText: {
      display: 'inline',
      whiteSpace: 'normal',
   },
}), 'OrganizationStyles');

/**
 * Select component to select an organization to show data from that organization.
 *
 * @param onChange The callback when the organization changes.
 * @param userOrganizations list of user organization memberships (Optional)
 */
export default function OrganizationSelect({onChange, userOrganizations}) {
   const classes = useStyles();

   const [showError, setShowError] = useState(false);
   const [organizations, setOrganizations] = useState([]);
   const [selectedOrganization, setSelectedOrganization] = useState();

   const [status, setStatus] = useState();

   /**
    * Fetch the organizations for the select component. Set the selected organization to the option set in localStorage.
    * the key is 'hw.' added to 'organization' encoded.
    */
   useEffect(() => {
      const fetch = async () => {
         try {
            if (userOrganizations) {
               const defaultOrganization = userOrganizations[0];
               setOrganizations(userOrganizations);

               const organization = localStorage['hw.' + window.btoa('organization')];
               if (organization) {
                  const organizationId = window.atob(organization);
                  const foundOrganization = find(userOrganizations, {organization_id: organizationId})
                  if (foundOrganization) {
                     setSelectedOrganization(foundOrganization);
                  } else {
                     localStorage.removeItem('hw.' + window.btoa('organization'));
                     setSelectedOrganization(defaultOrganization);
                  }
               } else {
                  setSelectedOrganization(defaultOrganization);
               }
            } else {
               const result = await requestForServer(GET_ORGANIZATIONS);
               if (result.error) {
                  setShowError(true);
               } else {
                  if (result && result.data) {
                     const defaultOrganization = {organization_id: undefined, name: 'All Organizations'};
                     const sortedOrganizations = [defaultOrganization, ...sortBy(result.data, ['name'])];
                     setOrganizations(sortedOrganizations);

                     const organization = localStorage['hw.' + window.btoa('organization')];
                     if (organization) {
                        const organizationId = window.atob(organization);
                        setSelectedOrganization(find(sortedOrganizations, {organization_id: organizationId}))
                     } else {
                        setSelectedOrganization(defaultOrganization);
                     }
                  } else {
                     setOrganizations([]);
                  }
               }
            }
         } catch (e) {
            setShowError(true);
         }
      }
      fetch();
   }, []);

   /**
    * Handle changes to the organization.
    * @param event The change event.
    */
   const handleChange = (event) => {
      const value = event.target.value;
      setStatus(value);
      setSelectedOrganization(find(organizations, {organization_id: value}));
      if (value) {
         localStorage['hw.' + window.btoa('organization')] = window.btoa(value);
      } else {
         localStorage.removeItem('hw.' + window.btoa('organization'));
      }
      onChange && onChange();
   };

   return (
      <Fragment>
         {showError && <DisplayError errorId={'evalDashboard.loadOrganizations.error'} error={true} enableRefresh={true}
                                     onClose={() => setShowError(false)}/>}
         <FormControl className={classes.formControl}>
            <Select
               value={status || ''}
               className={classes.selectStyle}
               displayEmpty={true}
               renderValue={() => (
                  <Grid container wrap={'nowrap'} direction={'row'} alignItems={'center'}>
                     <FHGTypography variant={'button'} className={classes.titleStyle}>
                        {selectedOrganization ? selectedOrganization.name : 'All Organizations'}
                     </FHGTypography>
                  </Grid>
               )}
               onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
               }}
               onChange={handleChange}
               input={<Input name='status' disableUnderline={true}
                             style={{marginRight: 8, fontSize: 18}}/>}
            >
               {organizations.map((organization, index) => (
                  <MenuItem key={'Organization '+ index} value={organization.organization_id}>
                     <FHGTypography className={classes.menuText} color='inherit' variant='body1'>
                        {organization.name}
                     </FHGTypography>
                  </MenuItem>
               ))}
            </Select>
         </FormControl>
      </Fragment>
   );
}

OrganizationSelect.propTypes = {
   onChange: PropTypes.func,
};

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {isEmpty} from 'lodash';
import get from 'lodash/get';
import React, {useEffect} from 'react';
import {Route, Switch, Redirect, Link, useHistory, useLocation} from 'react-router-dom';
import 'react-table/react-table.css';
import UsersGroupsMain from '../../admin/UsersGroupsMain';
import {
   RELEASE_NOTES_LINK, APP_BAR_HEIGHT, SUPPORT_LINK, EDIT_ITEM_PATH, CATALOG_PATH, DEFAULT_LOCATION, GET_USER_SESSION
} from '../../Constants';
import Typography from '../../fhg/components/Typography';
import PrivilegeRoute from '../../fhg/security/PrivilegeRoute';
import {LARGE_LOGO} from '../../LogoConstants';
import {
   getUserData,
   isFilesOnly,
   isNormalUser,
   isAdminOnly,
   setEstimateConfig,
   setDefaultValuationType
} from '../../Utils/ServerUtil';
import useRequestForServer from '../../Utils/useRequestForServer';
import NavigationMenu from './components/NavigationMenu';
import OrganizationSelect from './components/OrganizationSelect';
import SimpleAppBar from './components/SimpleAppBar';
import UserMenu from './components/UserMenu';
import EvalDashboard from './dashboard/EvalDashboard';
import EquipmentListMain from './list/EquipmentListMain';
import ValuationMain from './list/ValuationMain';

const useStyles = makeStyles(theme => ({
   root: {
      width: '100%',
      height: '100vh',
      margin: 0,
      '@media print': {
         height: '100%',
      },
   },
   mainGrid: {
      height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
      margin: '0 auto',
      '@media print': {
         display: 'block',
      },
   },
   mainGridFullScreen: {
      height: `100%`,
      margin: '0 auto',
      '@media print': {
         display: 'block',
      },
   },
   button: {
      color: 'inherit !important',
      textTransform: 'none !important',
      fontSize: '18px !important',
      [theme.breakpoints.down('sm')]: {
         padding: theme.spacing(0.5, 0.5),
      }
   },
   titleStyle: {
      fontSize: '14px !important',
      fontWeight: 500,
      lineHeight: '17px',
      textAlign: 'center',
      color: theme.palette.secondary.contrastText,
      textTransform: 'none',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
         whiteSpace: 'wrap',
      }
   },
   close: {
      width: theme.spacing(4),
      height: theme.spacing(4),
   },
   list: {
      height: '100%',
      width: '100%',
   },
   version: {
      color: theme.palette.secondary.contrastText,
      fontSize: `${theme.size.font['item-title']}rem`,
      opacity: 0.8,
      fontStyle: 'italic',
      textAlign: 'right',
   },
   image: {
      height: 40,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
         marginLeft: theme.spacing(0),
         marginRight: theme.spacing(1),
      },
   },
}), {name: 'EvalMainStyles'});

/**
 * The main component for the evaluators. Handled evaluators dashboard and showing the open evaluation lists or capture
 * detail.
 */
export default function EvalMain() {
   const classes = useStyles();
   const history = useHistory();
   const location = useLocation();

   const {isAdmin, multipleOrganizations, organizations} = getUserData();
   const {isZoomed} = get(location, 'location.state', {});

   const {loading, error, data} = useRequestForServer({uri: GET_USER_SESSION});

   useEffect(() => {
       if (!isEmpty(data)) {
          setEstimateConfig(data);
          setDefaultValuationType(data);
       }
   }, [data]);


   const onAdminClick = () => {
      history.push('/admin');
   };

   /**
    * Handle a change to the organization to display. Reload all the data.
    */
   const handleChangeOrganization = () => {
      history.push(DEFAULT_LOCATION);
      window.location.reload();
   }

   return (
      <div className={classes.root}>
         <Route exact path={EDIT_ITEM_PATH}>
            {({location}) => (
               <SimpleAppBar logo={LARGE_LOGO()} color='secondary' style={{
                  display: get(location, 'state.isZoomed', false) ? 'none' : undefined
               }}>
                  <Hidden xsDown>
                     <Grid container direction={'row'} alignItems={'center'} justify={'space-between'} spacing={0}
                           wrap={'nowrap'}>
                        <Grid container item style={{width: 'unset'}} direction={'row'} spacing={4} wrap={'nowrap'}>
                           <Grid item>
                              <Button component={Link} to={DEFAULT_LOCATION}>
                                 <Typography className={classes.titleStyle} color='inherit' variant='h5'
                                             id={'evalDashboard.heading'}/>
                              </Button>
                           </Grid>
                           <Grid item style={{display: 'flex', flexWrap: 'nowrap'}}>
                              <Grid item>
                                 <Button target='_blank' href={SUPPORT_LINK} color='secondary'
                                         className={classes.button}>
                                    <Typography className={classes.titleStyle} color='inherit' variant='h5'
                                                id={'nav.support.link'}/>
                                 </Button>
                              </Grid>
                           </Grid>
                           <Grid item style={{display: 'flex', flexWrap: 'nowrap'}}>
                              <Grid item>
                                 <Button target='_blank' href={RELEASE_NOTES_LINK} color='secondary'
                                         className={classes.button}>
                                    <Typography className={classes.titleStyle} color='inherit' variant='h5'
                                                id={'nav.releaseLog.link'}/>
                                 </Button>
                              </Grid>
                           </Grid>
                           {isAdmin && (
                              <Grid item>
                                 <Button className={classes.button} onClick={onAdminClick}>
                                    <Typography className={classes.titleStyle} color='inherit' variant='h5'
                                                id={'nav.admin.link'}/>
                                 </Button>
                              </Grid>
                           )}
                           {isAdmin && (
                              <Grid item>
                                 <OrganizationSelect onChange={handleChangeOrganization}/>
                              </Grid>
                           )}
                           {!isAdmin && multipleOrganizations && (
                              <Grid item>
                                 <OrganizationSelect onChange={handleChangeOrganization}
                                                     userOrganizations={organizations}/>
                              </Grid>
                           )}
                        </Grid>
                        <Grid container item direction={'row'} alignItems={'center'} justify={'flex-end'}>
                           <UserMenu/>
                           <Typography className={classes.version}>v{process.env.REACT_APP_VERSION}</Typography>
                        </Grid>
                     </Grid>
                  </Hidden>
                  <Hidden smUp>
                     <NavigationMenu admin={isAdmin}/>
                  </Hidden>
               </SimpleAppBar>
            )}
         </Route>
         <Grid container className={isZoomed ? classes.mainGridFullScreen : classes.mainGrid}>
            <Switch>
               <Redirect from='/evaluations/owner/:owner?/id/:id?' to='/evaluations/:owner%7C:id'/>
               <PrivilegeRoute hasPrivilege={isNormalUser} exact path='/evaluations/all/open' component={EvalDashboard}/>
               <PrivilegeRoute hasPrivilege={isAdminOnly} exact path='/admin' component={UsersGroupsMain}/>
               <PrivilegeRoute hasPrivilege={isNormalUser} exact path={[CATALOG_PATH, EDIT_ITEM_PATH, '/new']}
                               component={EquipmentListMain}/>
               <PrivilegeRoute hasPrivilege={isFilesOnly} exact path='/valuation' component={ValuationMain}/>
            </Switch>
         </Grid>
      </div>
   );
}

import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import {Auth} from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import React, {PureComponent} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import Typography from '../../fhg/components/Typography';
import {isEmailValid, formatMessage} from '../../fhg/utils/Utils';
import FormattedTextField from '../eval/components/FormattedTextField';
import * as PropTypes from 'prop-types';

const styles = theme => ({
   mainGrid: {
      height: 'calc(100% - 80px)',
      width: '100%',
      minHeight: 460,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.brand.gray,//'#5D788F',
      overflow: 'hidden',
   },
   contentStyle: {
      width: 436,
      padding: theme.spacing(4.5, 7),
      overflow: 'auto',
      backgroundColor: theme.palette.environment.light.level1.base,
   },
   firstTitleStyle: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
   },
   buttonStyle: {
      color: theme.palette.common.white,
      textTransform: 'none',
      borderRadius: 4,
      backgroundColor: theme.palette.button.standard.primary,
      margin: `${theme.spacing(2)}px ${theme.spacing(0)} ${theme.spacing(0)}px !important`,
      padding: '14px 0',
      lineHeight: '20px !important',
      height: '43px !important',
      width: '100%',

      '&:hover': {
         backgroundColor: theme.palette.button.standard.primary,
      },
      '&:disabled': {
         color: `${theme.palette.button.standard.label.disabled} !important`,
         backgroundColor: `${theme.palette.button.standard.disabled} !important`,
      },
   },
   titleStyle: {
      margin: theme.spacing(2.5, 6, 4.5),
      textAlign: 'center',
   },
   emailStyle: {
      marginTop: theme.spacing(2),
   },
   contentFrame: {
      // margin: theme.spacing(0, 4),
      width: 'auto',
   },
   spinnerMargin: {
      marginLeft: theme.spacing(0.5),
   },
   linkStyle: {
      color: 'white',
      marginTop: theme.spacing(4),
      fontSize: 16,
      '&:hover': {
         backgroundColor: theme.palette.brand.gray,
      },
   }
});

/**
 * The component for the Custom Authorization workflow to send authorization code to entered email.
 */
class EmailPanel extends PureComponent {
   static propTypes = {
      onManualSignIn: PropTypes.func.isRequired,
   };

   constructor(props, context) {
      super(props, context);

      this.state = {
         isSaving: false
      };
   }

   /**
    * Handle changes to the item.
    * @param target The target of the event that triggered the change.
    */
   handleChange = ({target}) => {
      this.setState({[target.name]: target.value, showError: undefined, isChanged: true});
   };

   handleSignInSubmit = async event => {
      const {onSubmit} = this.props;
      const {email} = this.state;
      event.preventDefault();

      if (email && email !== '') {
         if (isEmailValid(email)) {
            try {
               this.setState({isSaving: true});
               const cognitoUser = await Auth.signIn(email);
               onSubmit && onSubmit(email, cognitoUser);
            } catch (error) {
               if (!process.env.REACT_APP_API) {
                  console.log(error);
               }
               onSubmit && onSubmit(email);
            } finally {
               this.setState({isSaving: false});
            }
         } else {
            this.setState({showError: 'signInEmail.emailInvalid.error'});
         }
      } else {
         this.setState({showError: 'signInEmail.emailMissing.error'});
      }
   };

   render() {
      const {classes, intl, onManualSignIn} = this.props;
      const {isSaving, email, showError} = this.state;

      return (
         <div className={classes.mainGrid}>
            <Grid container direction={'column'} wrap={'nowrap'} justify={'center'} alignItems={'center'}>
               <form onSubmit={this.handleSignInSubmit}>
                  <Grid container direction={'column'} className={classes.contentStyle} justify={'space-between'}
                        alignItems={'center'}>
                     <Typography className={`${classes.firstTitleStyle} level-2-heading`}
                                 id={'login.title'}/>
                     <Grid item className={classes.contentFrame}>
                        <Typography className={`${classes.titleStyle} level-3-heading`} id={'signInEmail.title'}/>
                        <Typography id='signInEmail.email.label' className={`${classes.emailStyle} label-text`}/>
                        <FormattedTextField
                           name='email'
                           type='text'
                           error={!!showError}
                           helperText={showError && formatMessage(intl, showError)}
                           autoFocus
                           fullWidth
                           value={email}
                           onChange={this.handleChange}
                           margin='none'
                           placeholder={'Your email address'}
                           inputProps={{className: classes.fullWidthInput}}
                           disabled={isSaving}
                        />
                     </Grid>
                     <Grid item style={{width: '100%'}}>
                        <Button className={classes.buttonStyle} type='submit' disabled={isSaving}>
                           <Typography color='inherit' className={'large-button-text'} id={'signInEmail.email.button'}/>
                           {isSaving &&
                           <CircularProgress className={classes.spinnerMargin} size={15} thickness={2.5}/>}
                        </Button>
                     </Grid>
                  </Grid>
               </form>
               <Grid container justify={'center'}>
                  <Link className={classes.linkStyle} component='button' variant="'body2" underline='always'
                        onClick={onManualSignIn}>
                     Sign in manually
                  </Link>
               </Grid>
            </Grid>
         </div>
      );
   }
}

export default injectIntl(withStyles(styles)(withRouter(EmailPanel)));

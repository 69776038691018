import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function SuccessCheck(props) {
   return (
      <SvgIcon {...props} style={{width: '1.1em', height: '1.1em'}}>
         <g id='Components/Icons/Success-Check-Active' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
            <path
               d='M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 Z'
               id='Combined-Shape' fill='#2DA970'></path>
            <polygon id='Path-2' fill='#FFFFFF' fill-rule='nonzero'
                     points='9.90190232 13.853925 17.9800504 6.8655828 19.9428011 9.1344172 9.55177246 18.1236141 4.84692535 12.4689061 7.15307465 10.5501369'></polygon>
         </g>
      </SvgIcon>
   );
}

export function RotateIcon(props) {
   return (
      <SvgIcon {...props} viewBox={'0 0 21 24'}>
         <path
            d='M 10.059 0 L 10.059 2.937 C 14.748 2.937 18.83 6.206 19.872 10.799 C 20.913 15.393 18.644 20.118 14.417 22.158 C 10.19 24.198 5.103 23.026 2.184 19.339 C -0.735 15.652 -0.727 10.405 2.203 6.727 L 4.168 8.306 C 1.962 11.074 1.954 14.991 4.151 17.766 C 6.348 20.542 10.148 21.417 13.329 19.882 C 16.511 18.346 18.203 14.819 17.419 11.362 C 16.635 7.904 13.588 5.464 10.058 5.464 L 10.058 8.493 L 5.029 4.247 Z'
            transform='translate(0.439 0.823)'
            fill='var(--token-24b1dd0d-a298-4aab-8436-c2bf6041c8ff, rgba(93, 120, 143, 0.7)) /* {&quot;name&quot;:&quot;Text Button/ Subtle&quot;,&quot;packageIdentifier&quot;:&quot;@framer/joshgoodell.hw-design-system&quot;} */'
            name='Path'></path>
      </SvgIcon>
   );
}

export function ZoomIcon(props) {
   return (
      <SvgIcon {...props} viewBox={'0 0 23 23'}>
         <path
            d='M 22.451 19.707 L 16.959 14.214 C 19.514 10.254 18.67 5.004 15.002 2.044 C 11.335 -0.916 6.026 -0.631 2.696 2.703 C -0.634 6.037 -0.913 11.348 2.049 15.013 C 5.012 18.678 10.262 19.517 14.219 16.958 L 19.708 22.451 C 20.446 23.183 21.636 23.183 22.374 22.451 L 22.451 22.373 C 23.183 21.635 23.183 20.445 22.451 19.707 Z M 14.178 14.181 C 12.17 16.189 9.151 16.79 6.528 15.704 C 3.905 14.617 2.194 12.057 2.194 9.217 C 2.194 6.378 3.905 3.817 6.528 2.731 C 9.151 1.644 12.17 2.245 14.178 4.254 C 16.918 6.995 16.918 11.439 14.178 14.181 Z'
            transform='translate(1 1)'
            fill='var(--token-24b1dd0d-a298-4aab-8436-c2bf6041c8ff, rgb(93, 120, 143, 0.7)) /* {&quot;name&quot;:&quot;Text Button/ Subtle&quot;,&quot;packageIdentifier&quot;:&quot;@framer/joshgoodell.hw-design-system&quot;} */'
            name='Shape'></path>
      </SvgIcon>
   );
}

export function CheckboxBackground(props) {
   const {stroke, ...someProps} = props;
   return (
      <SvgIcon style={{fill: 'white', stroke: '#bfbaae'}} {...someProps} viewBox={'0 0 24 24'} width={24} height={24}>
         <path d='M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z'/>
      </SvgIcon>
   )
}

export function PinIcon(props) {

   return (
      <SvgIcon viewBox={'0 0 24 24'} {...props} >
         <path d='M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z'/>
      </SvgIcon>
   )
}

export function CheckboxSelected(props) {
   const {stroke, ...someProps} = props;
   return (
      <SvgIcon style={{fill: 'white', stroke: 'white', isolation: 'isolate'}} {...someProps} viewBox={'0 0 24 24'}
               width={24} height={24}>
         <defs>
            <clipPath id='_clipPath_Ouc78Z2kE16S5X7Ir3YKGWCVMPUgRf76'>
               <rect width='24' height='24'/>
            </clipPath>
         </defs>
         <g clip-path='url(#_clipPath_Ouc78Z2kE16S5X7Ir3YKGWCVMPUgRf76)'>
            <rect x='3.984' y='3.375' width='16.141' height='17.109' transform='matrix(1,0,0,1,0,0)'
                  fill='rgb(255,255,255)'/>
            <path
               d=' M 10 17 L 5 12 L 6.41 10.58 L 10 14.17 L 17.59 6.58 L 19 8 M 19 3 L 5 3 C 3.89 3 3 3.89 3 5 L 3 19 C 3 20.105 3.895 21 5 21 L 19 21 C 20.105 21 21 20.105 21 19 L 21 5 C 21 3.89 20.1 3 19 3 Z '
               fill='#489AC7'/>
         </g>
      </SvgIcon>
   );
}
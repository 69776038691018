import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {Auth} from 'aws-amplify';
import {SignIn} from 'aws-amplify-react';
import React from 'react';
import DocumentTitle from 'react-document-title';
import {LOGO} from '../../LogoConstants';
import SimpleAppBar from '../eval/components/SimpleAppBar';
import CheckEmailPanel from './CheckEmailPanel';
import EmailPanel from './EmailPanel';
import SignInOptionsPanel from './SignInOptionsPanel';
import SignInPanel from './SignInPanel';
import {withRouter} from 'react-router-dom'
import get from 'lodash/get';

export const OPTIONS_STEP = 'options';
export const EMAIL_STEP = 'email';
const CHECK_STEP = 'symbolConfirm';
export const SIGN_IN_STEP = 'signIn';

const styles = theme => ({
   root: {
      height: '100vh',
      backgroundColor: theme.palette.brand.gray,
   },
});

/**
 * The custom AWS Cognito Sign In component.
 */
class MySignIn extends SignIn {
   constructor(props) {
      super(props);

      this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
      this.signInTime = Date.now();

      this.state = {
         email: '',
         step: OPTIONS_STEP,
      };

   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      // Make sure the location signInTime is from this signIn attempt. If not, go back to the beginning.
      const signInTime = get(this.props.location, 'state.signInTime');
      const step = get(this.props.location, 'state.step', OPTIONS_STEP);
      if (step !== OPTIONS_STEP && signInTime !== this.signInTime) {
         this.props.history.replace(undefined, {step: undefined});
      }
   }

   onResetPassword = () => {
      this.changeState('forgotPassword');
      document.removeEventListener('keydown', this.handleKey, false);
   };

   triggerAuthEvent = (event) => {
      const state = this.props.authState;

      if (this.props.onAuthEvent) {
         this.props.onAuthEvent(state, event);
      }
   };

   changeState = (state, data) => {
      if (this.props.onStateChange) { this.props.onStateChange(state, data); }

      this.triggerAuthEvent({
         type: 'stateChange',
         data: state
      });
   };

   optionsSubmit = (option) => {
      this.props.history.push(undefined, {step: option, signInTime: this.signInTime});
   };

   handleManualSignin = () => {
      this.props.history.replace(undefined, {step: SIGN_IN_STEP, signInTime: this.signInTime});
   };

   handleEmailSignin = () => {
      this.props.history.replace(undefined, {step: EMAIL_STEP, signInTime: this.signInTime});
   };

   emailSubmit = (email, cognitoUser) => {
      this.props.history.push(undefined, {step: CHECK_STEP, signInTime: this.signInTime});
      this.setState({email, cognitoUser});
   };

   symbolSubmit = async code => {
      const {cognitoUser} = this.state;

      if (code && cognitoUser) {
         try {
            await Auth.sendCustomChallengeAnswer(cognitoUser, code);
            // Auth.sendCustomChallengeAnswer is supposed to throw an error if the authentication doesn't work, but it
            // doesn't. Checking for an authenticated user, throws an error if the user isn't authenticated.
            await Auth.currentAuthenticatedUser();
            // User successfully authenticated with the code. Reload the app to display the current URL.
            window.location.reload();
         } catch (e) {
            console.log(e);
            throw e;
         }
      } else {
         console.log('Invalid authentication');
         throw new Error('Invalid authentication');
      }
   };

   render() {
      const {classes, authState, authData, hide, location} = this.props;
      const {email} = this.state;

      if (authState !== 'signIn') {
         return null;
      }

      if (hide && hide.includes(MySignIn)) {
         return null;
      }

      const step = get(location, 'state.step', OPTIONS_STEP);

      const showSuccess = authData && authData.showSuccess;
      const title = `${process.env.REACT_APP_SITE_TITLE} Sign In`;

      return (
         <DocumentTitle title={title}>
            <Grid container direction={'column'} className={classes.root} wrap={'nowrap'}>
               <SimpleAppBar logo={LOGO()} isSignIn color='secondary' position='sticky'/>
               {{
                  [OPTIONS_STEP]: <SignInOptionsPanel onSubmit={this.optionsSubmit}/>,
                  [EMAIL_STEP]: <EmailPanel onSubmit={this.emailSubmit} onManualSignIn={this.handleManualSignin}/>,
                  [CHECK_STEP]: <CheckEmailPanel email={email} onSubmit={this.symbolSubmit}
                                                 onManualSignIn={this.handleManualSignin}
                                                 onSubmitEmail={this.emailSubmit}/>,
                  [SIGN_IN_STEP]: <SignInPanel showSuccess={showSuccess} onResetPassword={this.onResetPassword}
                                               onEmailSignIn={this.handleEmailSignin}/>
               }[step]}
            </Grid>
         </DocumentTitle>
      );
   }
}

export default withStyles(styles)(withRouter(MySignIn));

import {Checkbox} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {withRouter} from 'react-router-dom';
import Typography from '../../../fhg/components/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {THUMBNAIL_SIZE, PLACEHOLDER_IMAGE, IMAGE_VIEW_KEYS, IMAGE_VIEW_CONVERT} from '../../../Constants';
import {CheckboxBackground, CheckboxSelected} from '../../../Icons';
import PhotoMoreMenu from '../components/PhotoMoreMenu';

const checkboxStyles = theme => ({
   root: {
      color: `${theme.palette.environment.light.divider} !important`,
      '&$checked': {
         color: `${theme.palette.brand.blue} !important`, //#489AC7
      },
   },
   checked: {},
});

function CustomCheckbox4(props) {
   return (
      <Checkbox color='default' checkedIcon={<CheckboxSelected/>}
                icon={<CheckboxBackground stroke={props.theme.palette.environment.light.divider}/>} {...props} />
   );
}

export const CustomCheckboxSolid = withStyles(checkboxStyles, {withTheme: true})(CustomCheckbox4);

const styles = theme => ({
   verticalThumbs: {
      height: '100%',
      width: THUMBNAIL_SIZE + 8,
      display: 'block',
      padding: '0 4px',
      marginLeft: '4px !important',
      marginTop: '0px !important',
      borderLeft: `1px solid ${theme.palette.divider}`,
   },
   horizontalThumbs: {
      height: THUMBNAIL_SIZE + 10,
      width: '100%',
      flexWrap: 'nowrap',
      flex: '0 0 auto',
      paddingTop: 4,
      marginLeft: '0px !important',
      marginTop: '4px !important',
      marginBottom: '7px !important',
      borderTop: `1px solid ${theme.palette.divider}`,
   },
   titleBar: {
      background:
         'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
   },
   moreTitleBar: {
      background: 'transparent',
      width: '100%',
   },
   titleText: {
      fontSize: `${theme.size.font['item-title']}rem`,
      lineHeight: `${theme.size.lineheight['item-title']}rem`,
      letterSpacing: `${theme.size.letterspacing['item-title']}rem`,
   },
   actionIconStyle: {
      width: '100%',
   },
   titleWrap: {
      display: 'none',
   },
   imageType: {
      background:
         'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      bottom: 0,
      position: 'absolute',
      color: 'white',
      textAlign: 'center',
      width: '100%',
      paddingBottom: 4,
      marginLeft: 4,
   }
});

class ThumbnailList extends PureComponent {
   static propTypes = {
      classes: PropTypes.any,
      thumbColumns: PropTypes.number,
      images: PropTypes.array,
      selectedImage: PropTypes.object,
      onDeletePhoto: PropTypes.func,
      onRefresh: PropTypes.func,
      onSelect: PropTypes.func,
      onSelectThumbnail: PropTypes.func,
      onMovePhoto: PropTypes.func,
      selected: PropTypes.array,
      onSortAllPhotos: PropTypes.func,
   };

   state = {};

   handleSelect = index => (event) => {
      const {onSelectThumbnail, selected} = this.props;
      event.stopPropagation();
      event.preventDefault();

      const selectedArray = selected.slice(0);
      selectedArray[index] = event.target.checked;
      onSelectThumbnail && onSelectThumbnail(selectedArray);
   };

   render() {
      const {
         classes, location, thumbColumns, images, selected, selectedImage, onDeletePhoto, onSelect, onRefresh,
         onMovePhoto, onSortAllPhotos
      } = this.props;
      const isEdit = get(location, 'state.isEdit', false);

      return (
         <GridList id='thumbnailListId'
                   className={thumbColumns === 1 ? classes.verticalThumbs : classes.horizontalThumbs}
                   cols={thumbColumns} cellHeight={THUMBNAIL_SIZE}>
            {images.map((image, index) => {
               const thumbnail = get(image, 'sizes.thumbnail', PLACEHOLDER_IMAGE);
               return (
                  // eslint-disable-next-line
                  <GridListTile key={index} className={classNames({['selected-thumbnail']: selectedImage === image},
                     classes.zoomThumb)}
                                style={{position: 'relative'}}>
                     <img src={thumbnail} onClick={onSelect(image)} alt=''
                          onError={(e) => {e.target.src = PLACEHOLDER_IMAGE}}/>
                     <Typography id={IMAGE_VIEW_KEYS[image.image_view] ||
                     IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[image.image_view]] || image.image_view ||
                     'photoDetail.changeCategory.additional'} className={classes.imageType}
                     />
                     {isEdit ? (
                        <GridListTileBar
                           classes={{title: classes.titleText}}
                           title={<Typography id={IMAGE_VIEW_KEYS[image.image_view] ||
                           IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[image.image_view]] || image.image_view ||
                           'photoDetail.changeCategory.additional'}/>}
                           titlePosition='top'
                           className={classes.titleBar}
                        />
                     ) : !image.__isPlaceholderImage && (
                        <GridListTileBar
                           titlePosition='top'
                           actionPosition='left'
                           className={classes.moreTitleBar}
                           classes={{actionIcon: classes.actionIconStyle, titleWrap: classes.titleWrap}}
                           // onClick={() => console.log(`Click on title bar - ${Date.now()}`)}
                           actionIcon={
                              <Grid container direction={'row'} justify={'space-between'}>
                                 <CustomCheckboxSolid key={selected[index] ? index + 'select' : index + 'not selected'}
                                                      checked={selected[index]} style={{zIndex: 1002}}
                                                      onClick={this.handleSelect(index)} icon={<CheckboxBackground/>}/>
                                 <PhotoMoreMenu image={image} onDeletePhoto={onDeletePhoto(image)} onMovePhoto={onMovePhoto(image)}
                                                onRefresh={onRefresh} isFirst={index === 0} isLast={index >= (images.length - 1)}
                                                onSortAllPhotos={onSortAllPhotos}
                                 />
                              </Grid>
                           }
                        />
                     )}
                  </GridListTile>
               )
            })}
         </GridList>
      );

   }
}

export default withRouter(withStyles(styles)(ThumbnailList));

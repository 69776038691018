import {withStyles} from '@material-ui/core/styles';
import mixpanel from 'mixpanel-browser';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ErrorSnackbar from '../components/eval/components/ErrorSnackbar';
import FormattedTextField from '../components/eval/components/FormattedTextField';
import {CREATE_USER, ADD_USER_TO_GROUP} from '../Constants';
import ModalDialog from '../fhg/components/dialog/ModalDialog';
import Typography from '../fhg/components/Typography';
import {requestForServer} from '../Utils/ServerUtil';
import PropTypes from 'prop-types';

const styles = theme => ({
   fullWidthInput: {
      marginTop: `${theme.spacing(0.5)}px !important`,
      height: 30,
      width: '100% !important',
   },
   mediumTopPad: {
      marginTop: theme.spacing(1.5),
   },
});

/**
 * The New User dialog which creates a new user.
 */
class NewUserDialog extends Component {
   static propTypes = {
      group: PropTypes.object.isRequired,   // The group to add the user to.
   };

   state = {
      name: '',
      isSaving: false,
      isChanged: false,
   };

   /**
    * Handle changes to the user.
    * @param target The target of the event that triggered the change.
    */
   handleChange = ({target}) => {
      this.setState({[target.name]: target.value, isChanged: true});
   };

   /**
    * Submits the changes to the user.
    */
   onSubmit = async (event) => {
      event.preventDefault();
      let error;
      let errorValues;
      let user = {};
      mixpanel.track('Save New User');

      const {isChanged, isSaving} = this.state;
      const {onClose, onSubmit, group} = this.props;

      if (isChanged && !isSaving) {

         this.setState({isSaving: true});
         try {
            user.name = this.state.name || undefined;
            user.email = this.state.email || undefined;
            user.password = this.state.password || undefined;

            const resultUser = await requestForServer(CREATE_USER, 'post', user);
            if (!resultUser.error) {
               user = resultUser.data;
               const resultGroup = await requestForServer(
                  ADD_USER_TO_GROUP.format({orgId: group.organization_id, userId:user.user_id}), 'put');
               if (resultGroup.error) {
                  error = resultGroup.error;
               }
            } else {
               error = resultUser.error;
               errorValues = resultUser.errorValues;
            }
         } catch (err) {
            error = err;
         }

         if (error) {
            if (errorValues) {
               this.setState({isSaving: false, showError: true, message: errorValues.message});
            } else {
               this.setState({isSaving: false, showError: true, message: error.message});
            }
         } else {
            this.setState({isSaving: false, isChanged: false}, () => {
               onSubmit && onSubmit(user);
            });
            onClose();
         }
      }
   };

   /**
    * Close the error snackbar.
    */
   handleErrorClose = () => {
      this.setState({showError: false});
   };

   render() {
      const {classes, open, onClose} = this.props;
      const {isSaving, showError, message} = this.state;

      return (
         <ModalDialog
            open={open}
            onClose={onClose}
            onSubmit={this.onSubmit}
            titleKey={'newUser.main.title'}
            isSaving={isSaving}
            submitKey={'newUser.submit.label'}
            isForm
         >
            {showError && (
               <ErrorSnackbar open={showError} onClose={this.handleErrorClose} errorId={'newUser.save.error'}
                              values={{message}}/>
            )}
            <Typography id='newUser.name.label' className={`${classes.mediumTopPad} label-text`}/>
            <FormattedTextField
               name='name'
               required
               autoFocus
               fullWidth
               value={this.state.name}
               onChange={this.handleChange}
               margin='none'
               placeholder={'Doug Jones'}
               inputProps={{className: classes.fullWidthInput}}
               disabled={isSaving}
            />
            <Typography id='newUser.email.label' className={`${classes.mediumTopPad} label-text`}/>
            <FormattedTextField
               name='email'
               type='email'
               required
               fullWidth
               value={this.state.email}
               onChange={this.handleChange}
               margin='none'
               placeholder={'doug@acme.com'}
               inputProps={{className: classes.fullWidthInput}}
               disabled={isSaving}
            />
            <Typography id='newUser.password.label' className={`${classes.mediumTopPad} label-text`}/>
            <FormattedTextField
               name='password'
               // type={'password'}
               fullWidth
               required
               disabled={isSaving}
               autoComplete='current-password'
               onChange={this.handleChange}
               value={this.state.password}
               inputProps={{className: classes.fullWidthInput}}
            />
         </ModalDialog>
      );
   }
}

export default withRouter(withStyles(styles)(NewUserDialog));

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import classNames from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import {stringify, parse} from 'query-string';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {
   MAX_CONTENT_WIDTH, STATUS_ORDER, DEFAULT_STATUS_ORDER, HEADER_HEIGHT_EVAL_TABLE, HEADER_ICON_WIDTH_EVAL_TABLE
} from '../../../Constants';
import SearchWithButton from '../../../fhg/components/SearchWithButton';
import Typography from '../../../fhg/components/Typography';
import NotFeature from '../../../fhg/security/NotFeature';
import {CUSTOMER_ENV} from '../../../Utils/ServerUtil';
import EmailSubscribeCard from '../../EmailSubscribeCard';
import './EvalDashboard.scss';
import EvalDashboardTable from './EvalDashboardTable';
import SearchPastCatalogs from './SearchPastCatalogs';
import StatusFilterMenu from './StatusFilterMenu';

const styles = theme => ({
   root: {
      height: '100%',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
         paddingLeft: theme.spacing(1),
         paddingRight: theme.spacing(1),
      },
      '@media print': {
         display: 'block !important',
         position: 'relative',
      },
   },
   content: {
      maxWidth: MAX_CONTENT_WIDTH,
      overflow: 'hidden',
      maxHeight: '100%',
      '@media print': {
         display: 'block !important',
         position: 'relative',
      },
   },
   table: {
      flex: '1 1 !important',
      overflow: 'hidden',
      '@media print': {
         display: 'block',
      },
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         display: '-ms-flexbox',
      },
   },
   heading: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
   },
   list: {
      height: '100%',
      width: '100%',
   },
   searchList: {
      '& eval-table': {
         maxHeight: '100%',
      },
   },
   search: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '@media print': {
         display: 'none !important',
      },
   },
   themeUnderline: {
      '&:hover:not(:disabled):before,&:before': {
         borderColor: `${theme.palette.divider} !important`
      },
      '&:after': {
         borderColor: `${theme.palette.environment.dark.divider} !important`
      }
   },
   secondaryColor: {
      color: theme.palette.button.standard.secondary,
   },
   textFieldInput: {
      color: `${theme.palette.text.primary} !important`,
      '&::-ms-clear': {
         display: 'none',
      },
   },
   print: {
      '@media print': {
         display: 'block !important',
      },
   },
   emailCardStyle: {
      margin: theme.spacing(2, 0),
      boxShadow: 'none',
   },
   buttonStyle: {
      alignSelf: 'flex-end',
   },
   filterIcon: {
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
      height: 22,
      width: 22,
   },
   activeFilterIcon: {
      height: HEADER_HEIGHT_EVAL_TABLE,
      width: HEADER_ICON_WIDTH_EVAL_TABLE,
      marginTop: 0,
      padding: '1px 1px 0 1px',
      paddingRight: 1,
      paddingTop: 1,
      borderRadius: '50%',
      backgroundColor: theme.palette.button.standard.secondary,
      color: 'white',
   },
   iconRoot: {
      fontSize: 14,
   },
   filterButtonStyle: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(2),
   },
   searchButtonStyle: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(2),
   },
   progress: {
      position: 'absolute',
      top: 'calc(50% - 20px)',
      left: 'calc(50% - 20px)',
      zIndex: 1001,
   },
});

/**
 * The evaluators dashboard will show a list of evaluations grouped by owner.
 *
 * Last Reviewed: 11/12/18
 */
class EvalDashboard extends Component {
   static propTypes = {
      classes: PropTypes.object.isRequired,  // The component styles.
      history: PropTypes.object.isRequired,  // The react routes history object.
   };

   constructor(props) {
      super(props);

      const evalTableLocal = localStorage.evalTable;
      const filter = evalTableLocal ? JSON.parse(evalTableLocal).filter || DEFAULT_STATUS_ORDER : DEFAULT_STATUS_ORDER;
      const searchParams = parse(props.location.search, {parseBooleans: false, parseNumbers: false});
      const search = !!searchParams.search ? searchParams.search : '';

      this.state = {
         search,
         showClose: false,
         filter,
         isFiltering: false,
         showSearchPast: !!search,
         searchType: searchParams.type || 'Catalog',
      };
   }

   componentDidUpdate(prevProps, prevState, snapshot) {

      if (!isEqual(prevProps.location.search, this.props.location.search)) {
         const searchParams = parse(this.props.location.search, {parseBooleans: false, parseNumbers: false});
         const search = !!searchParams.search ? searchParams.search : '';
         this.setState({showSearchPast: !!search, search});
      }
   }

   /**
    * When user selects to create a new evaluation.
    */
   onAdd = () => {
      mixpanel.track('New Evaluation');
      this.props.history.push('/evaluations/', {...this.props.location.state, isNewEval2: true});
   };

   /**
    * When search data has changed.
    * @param target The change event.
    */
   onSearchChange = ({target}) => {
      this.setState({search: target.value}, this.handleSearchChange);
   };

   /**
    * After debounce, perform the actual search of data for the search string the user entered.
    * @type {Function} the debounced function.
    */
   handleSearchChange = async (search, type) => {
      mixpanel.track('Searches');
      const state = {search, type};
      const searchState = stringify(state);
      // sessionStorage.evalDashboard = JSON.stringify(state);
      this.props.history.push({pathname: this.props.location.pathname, search:searchState}, this.props.location.state);
      this.setState({search, searchType: type});
   };

   /**
    * When the filter menu is closed, filter the data.
    *
    * @param filter The new filter array.
    */
   onFilterClose = (filter) => {
      localStorage.evalTable = JSON.stringify({filter});
      this.setState({anchorFilterEl: null, isFiltering: true}, () => {
         setTimeout(() => {
            this.setState({filter, isFiltering: false});
            if (!isEqual(this.trackFilter, filter)) {
               mixpanel.track('Filter Changed');
               this.trackFilter = null;
            }
         }, 500);
      });
   };

   /**
    * Handle the user click on the filter button.
    *
    * @param event The click event.
    */
   handleFilterClick = event => {
      event.stopPropagation();
      event.preventDefault();

      this.trackFilter = this.state.filter;
      this.setState({anchorFilterEl: event.currentTarget});
   };

   /**
    * Handle closing the search past catalogs dialog.
    */
   handleCloseSearchCatalog = () => {
      this.props.history.replace(this.props.location.pathname);
   };

   /**
    * When the Radio changes, set the new value and remove the search from the URI.
    * @param target The target of the radio change event.
    */
   handleRadioChange = ({target}) => {
      this.setState({[target.name]: target.value, isChanged: true});
      this.handleCloseSearchCatalog();
   };

   render() {
      const {classes} = this.props;
      const {search, filter, isFiltering, showSearchPast} = this.state;

      const catalogsInPlaceSearch = localStorage.searchCatalogInPlace === 'true' && this.state.searchType === 'Catalog' ? search : undefined;

      return (
         <Grid container className={classes.root} spacing={0} justify={'center'}>
            <Grid container className={classes.content} direction={'column'} wrap={'nowrap'} spacing={0}>
               <Grid container className={classes.print} direction={'column'}>
                  <Grid item style={{alignSelf: 'center'}}>
                     <EmailSubscribeCard className={classes.emailCardStyle}/>
                  </Grid>
                  <Grid item container direction={'row'} justify={'space-between'}>
                     <Grid item style={{justifyContent: 'end', display: 'flex'}}>
                        <Button variant='text' className={`primary-button ${classes.buttonStyle}`} onClick={this.onAdd}>
                           <Typography color='inherit' variant='button' id={'evalDashboard.new.label'}/>
                        </Button>
                     </Grid>
                     <Grid item container direction={'row'} style={{width: 'auto'}} alignItems={'center'}>
                        <NotFeature feature={CUSTOMER_ENV}>
                           {isFiltering && <CircularProgress className={classes.progress}/>}
                           <Button variant={'button'}
                                   className={`secondary-minimal-button-small no-print ${classes.filterButtonStyle}`}
                                   onClick={this.handleFilterClick}>
                              <FilterListIcon className={classNames(classes.filterIcon,
                                 {
                                    [classes.activeFilterIcon]: get(this.state, 'filter', []).length <
                                    STATUS_ORDER.length
                                 })}
                                              classes={{root: classes.iconRoot}}/>
                              <Typography color='inherit' variant='button' id={'evalDashboard.status.column'}/>
                           </Button>
                           <StatusFilterMenu isOpen={this.state && !!this.state.anchorFilterEl}
                                             filters={this.state && this.state.filter}
                                             anchorEl={this.state && this.state.anchorFilterEl}
                                             onClose={this.onFilterClose}/>
                        </NotFeature>
                        <Grid container item direction={'row'} spacing={3} alignItems={'center'} style={{width: 'auto'}}>
                           <Grid item>
                              <SearchWithButton onChange={this.handleSearchChange} search={search} />
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item className={classes.table}>
                  <EvalDashboardTable className={classes.list} filter={filter} search={catalogsInPlaceSearch}/>
               </Grid>
            </Grid>
            {showSearchPast && !catalogsInPlaceSearch && (
               <SearchPastCatalogs open={showSearchPast} onClose={this.handleCloseSearchCatalog}/>
            )}
         </Grid>
      );
   }
}

export default withRouter(withStyles(styles)(EvalDashboard));

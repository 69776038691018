import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { APP_BAR_HEIGHT } from '../../../Constants';

const styles = theme => ({
   appBar: {
      flexDirection: 'row',
      height: APP_BAR_HEIGHT,
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      boxShadow: 'none',
      [theme.breakpoints.down('xs')]: {
         paddingLeft: theme.spacing(1),
         paddingRight: theme.spacing(1),
      },
   },
   image: {
      height: 40,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
         marginRight: theme.spacing(1),
      },
   },
   signInImage: {
      height: 22,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
         marginRight: theme.spacing(1),
      },
   }
});

/**
 * The App Bar with a logo, title and log out.
 */
function SimpleAppBar(props) {
   const {classes, children, logo, color, position = 'static', isSignIn = false, ...otherProps} = props;
   return (
      <AppBar className={classes.appBar} position={position} color={color} {...otherProps}>
         {logo && (
            <img className={isSignIn ? classes.signInImage : classes.image} src={logo} alt=''/>
         )}
         {children}
      </AppBar>
   );
}

SimpleAppBar.propTypes = {
   classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleAppBar);

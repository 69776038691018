import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import EditIcon from '@material-ui/icons/Edit';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import 'react-table/react-table.css';
import {EQUIPMENT_DETAIL_PROPERTY_WIDTH, PLACEHOLDER_IMAGE, THUMBNAIL_SIZE, EDIT_ITEM} from '../../../Constants';
import ResponsiveDrawer from '../../../fhg/components/ResponsiveDrawer';
import Typography from '../../../fhg/components/Typography';
import {getProfileThumbnail} from '../../../fhg/utils/Utils';
import {getEquipmentDescription} from '../EquipmentUtil';
import EquipmentListDetailEstimate from '../list/EquipmentListDetailEstimate';
import AssetDetail from './AssetDetail';

const useStyles = makeStyles(theme => ({
   backgroundStyle: {
      backgroundColor: theme.palette.environment.light.level0.base,
      padding: theme.spacing(0, 3, 2),
      overflow: 'auto',
      height: '100%',
   },
   drawer: {
      height: '100%',
   },
   drawerPaper: {
      maxWidth: '100%',
      height: '100%',
      [theme.breakpoints.down(475)]: {
         width: '100%',
      },
   },
   imageFrame: {
      paddingTop: theme.spacing(1),
   },
   image: {
      maxHeight: THUMBNAIL_SIZE + theme.spacing(1) + 2,
      maxWidth: THUMBNAIL_SIZE + theme.spacing(1) + 2,
      border: `1px solid ${theme.palette.divider} !important`,
      padding: `${theme.spacing(0.5)}px !important`,
      position: 'relative',
   },
   indicatorStyle: {
      backgroundColor: theme.palette.button.standard.secondary,
   },
   tabsStyle: {
      backgroundColor: theme.palette.environment.light.level1.base,
      borderBottom: `1px solid ${theme.palette.environment.light.level1.accent}`,
   },
   descriptionStyle: {
      marginLeft: `${theme.spacing(1)}px !important`,
   }
}), {name: 'AssetPropertiesDrawer'});

AssetPropertiesDrawer.propTypes = {
   evalItem: PropTypes.object,
   evaluation: PropTypes.object,
   isOpen: PropTypes.bool,
   showThumbnail: PropTypes.bool,
   showEquipmentTitle: PropTypes.bool,
   width: PropTypes.number,
   breakpoint: PropTypes.string,
   onClose: PropTypes.func,
   onSaving: PropTypes.func,
};

/**
 * Drawer for displaying and editing asset properties.
 */
export default function AssetPropertiesDrawer({
   evalItem = {}, evaluation, isOpen, showThumbnail = true, showEquipmentTitle = true,
   width = EQUIPMENT_DETAIL_PROPERTY_WIDTH, breakpoint = 'xs', onClose, onSaving
}) {
   const classes = useStyles();
   const location = useLocation();
   const history = useHistory();
   const {isZoomed, isEdit, isNew} = get(location, 'state', {});

   const [selectedTabIndex, setSelectedTabIndex] = useState(0);

   const image = useMemo(() => showThumbnail && getProfileThumbnail(evalItem), [showThumbnail, evalItem]);

   /**
    * Handle the changes to the selected tab.
    * @param event The event that selected a new tab.
    * @param selectedTabIndex The selectedTabIndex of the selected tab.
    */
   const handleChange = (event, selectedTabIndex) => {
      setSelectedTabIndex(selectedTabIndex);
   };

   const onEdit = () => {
      mixpanel.track('Edit Item');

      history.push(EDIT_ITEM.format({itemId: get(evalItem, 'item.item_id')}),
         {...location.state, isEdit: true, backPath: location.pathname});
   };

   return (
      <ResponsiveDrawer isTemporary={isZoomed || isEdit || isNew} breakpoint={breakpoint}
                        isOpen={isOpen && !isEdit && !isNew} sizeWidth={width}
                        onClose={onClose} classes={{drawer: classes.drawer, drawerPaper: classes.drawerPaper}}>
         <Tabs className={classes.tabsStyle} value={selectedTabIndex} onChange={handleChange}
               variant='scrollable'
               scrollButtons='off' classes={{indicator: classes.indicatorStyle}}>
            <Tab label={<Typography className={'secondary-minimal-tab-small'}
                                    id={'equipmentList.equipment.label'}/>}/>
            <Tab label={<Typography className={'secondary-minimal-tab-small'}
                                    id={'equipmentList.evaluation.label'}/>}/>
         </Tabs>
         {selectedTabIndex === 0 && (
            <Grid container className={classes.backgroundStyle} wrap={'nowrap'} direction={'column'}>
               <Grid container direction={'row'} wrap={'nowrap'} justify={'space-between'}
                     style={{flex: '0 0 auto', marginTop: 8}}>

                  <Button className={'secondary-minimal-button-small no-print'} variant={'text'}
                          style={{width: 110, height: 36, marginLeft: -8}}
                          onClick={onEdit} disableRipple={true}
                          disabled={!evaluation || evaluation.item_count <= 0 || get(evalItem, 'item.item_id') ===
                          undefined}>
                     <EditIcon style={{marginRight: 4, fontSize: 18}}/>
                     <Typography color='inherit' variant='button' id={'equipmentDetail.editAsset.button'}/>
                  </Button>
               </Grid>
               {showThumbnail && (
                  <Grid container className={classes.imageFrame} direction={'row'} wrap={'nowrap'}>
                     <img src={image} alt='' className={classes.image}
                          onError={(e) => {e.target.src = PLACEHOLDER_IMAGE}}/>
                     <Grid container item direction={'column'} className={classes.descriptionStyle} spacing={1}>
                        <Grid item>
                           <Typography className={'level-3-heading'}>
                              {getEquipmentDescription(get(evalItem, 'item'), {})}
                           </Typography>
                        </Grid>
                        <Grid item className={classes.location}>
                           <Typography className={'subtle-text-small'} values={evalItem}>
                              {get(evalItem, 'item.location')}
                           </Typography>
                        </Grid>
                     </Grid>
                  </Grid>
               )}
               <AssetDetail key={`estimates-${evalItem.item_id}`} evalItem={evalItem} showEquipmentTitle={showEquipmentTitle}
                            width={width} onClose={isOpen && onClose} showLocation={false}
               />
            </Grid>
         )}
         {selectedTabIndex === 1 && (
            <EquipmentListDetailEstimate key={`estimates-${evalItem.item_id}`}
                                         evalItem={evalItem}
                                         evaluation={evaluation} showSaved
                                         onRefresh={onSaving}/>
         )}
      </ResponsiveDrawer>
   );
}

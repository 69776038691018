import {IconButton, Hidden, useTheme} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import {
   isEqual, castArray, isFunction, isNumber, map, findIndex, sortedIndexOf, pullAt, sortedIndex, filter, mean, compact,
   uniq, find,
} from 'lodash';
import findLastIndex from 'lodash/findLastIndex';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import React, {Fragment, useState, useEffect, useMemo, useCallback} from 'react';
import DocumentTitle from 'react-document-title';
import {useIntl} from 'react-intl';
import {Link, useRouteMatch, useLocation, useHistory} from 'react-router-dom';
import 'react-table/react-table.css';
import {
   DELETE_EVAL_ITEM, PLACEHOLDER_IMAGE, APP_BAR_HEIGHT, DATE_FORMAT, EDIT_ITEM, CATALOG_PATH
} from '../../../Constants';
import ModalDialog from '../../../fhg/components/dialog/ModalDialog';
import DisplayError from '../../../fhg/components/DisplayError';
import GridOverflow from '../../../fhg/components/GridOverflow';
import Typography from '../../../fhg/components/Typography';
import useWidthRule from '../../../fhg/hooks/useWidthRule';
import Feature from '../../../fhg/security/Feature';
import NotFeature from '../../../fhg/security/NotFeature';
import {getProfileThumbnail, formatMessage, sortDate, detectIE} from '../../../fhg/utils/Utils';
import {PinIcon} from '../../../Icons';
import {isFeatureEnabled, isAdminOnly, SUB_PACKAGES, SEE_ESTIMATES, getUserData} from '../../../Utils/ServerUtil';
import {submitItem, submitEvaluation} from '../../../Utils/SubmitUtil';
import useLazyRequestForServer from '../../../Utils/useLazyRequestForServer';
import EditableEstimate from '../components/EditableEstimate';
import {ALL_SUBPACKAGE_FILTER} from '../components/SubPackageFilterMenu';
import '../dashboard/EvalDashboard.scss';
import {
   getYourEstimate, getMeterFromAsset, getMoreInformationFromAsset, getOtherMetersFromAsset, getConsensusEstimate
} from '../EquipmentUtil';
import AssetTable from './AssetTable';
import AssetTableHeader from './AssetTableHeader';
import EquipmentListItemDescription from './EquipmentListItemDescription';
import './fixedColumnTableStyles.scss';
import MultiSelectItemBar from './MultiSelectItemBar';

const THUMBNAIL_SIZE = 75;
const PIN_WIDTH = 30;
const MARGIN_WIDTH = 4 * 2;
const CHAR_WIDTH_MULTIPLIER = 8.1;
const MIN_COLUMN_WIDTH = 96;

const useStyles = makeStyles(theme => ({
   root: {
      height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
      '@media print': {
         display: 'block !important',
      },
   },
   total: {
      '& p': {
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            margin: '0 0',
         },
         'margin-block-start': 0,
         'margin-block-end': 0,
         '-webkit-margin-start': 0,
         '-webkit-margin-end': 0,
         '-webkit-margin-before': 0,
         '-webkit-margin-after': 0,
      },
   },
   icon: {
      color: `${theme.palette.content.nonessential} !important`,
      paddingRight: theme.spacing(0.5),
      marginTop: 'auto',
      marginBottom: 'auto',
   },
   image: {
      // Add padding.
      maxHeight: THUMBNAIL_SIZE + theme.spacing(1) + 2,
      maxWidth: THUMBNAIL_SIZE + theme.spacing(1) + 2,
      position: 'relative',
      cursor: 'pointer',
   },
   chipStyle: {
      composes: 'default-item-title',
      // composes: 'no-print',
      zIndex: 1001,
      position: 'absolute',
      right: 2,
      bottom: 2,
      backgroundColor: 'rgba(255,255,255,0.85)',
      height: 14,
      width: 'auto',
      borderRadius: 2,
      '-webkit-filter': 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
   },

   expandIcon: {
      color: `${theme.palette.button.standard.secondary} !important`,
   },
   tableFrame: {
      backgroundColor: theme.palette.environment.light.level2.base,
      overflow: 'hidden',
      height: '100%',
      '@media print': {
         display: 'block',
      },
   },

   innerTableFrame: {
      [theme.breakpoints.up('sm')]: {
         margin: theme.spacing(1, 2.5),
      },
      overflow: 'hidden',
   },
   hourStyle: {
      fontSize: `${theme.size.font['text-small']}rem !important`,
   },
   fillerStyle: {
      width: '100%',
      height: '33px !important',
      backgroundColor: `${theme.palette.environment.light.level2.base} !important`,
   },
   defaultTextStyle: {
      fontSize: `${theme.size.font['text-small']}rem !important`,
      color: theme.palette.text.secondary, //'rgba(66,51,19,0.8)',
   },
   otherEstimatesStyle: {
      height: 'calc(100% + 16px)',
   },
   estimatesStyle: {
      marginLeft: -theme.spacing(1),
   },
   estimatesFrameStyle: {
      marginTop: theme.spacing(1),
   },
   evaluationNotesStyle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
   }
}), {name: 'EquipmentListStyles'});

export const CustomCheckbox = withStyles(theme => ({
   root: {
      color: `${theme.palette.environment.light.divider} !important`,
      '&$checked': {
         color: `${theme.palette.button.checkbox.checked} !important`, //#11A1FF
      },
      padding: 3,
   },
   checked: {},
}))(props => <Checkbox color='default' {...props} />);

const packageStyles = {
   totalValue: {
      // marginTop: theme.spacing(0.5),
   },
};
export const PackageTotal = withStyles(packageStyles)(({classes, totalLabel, total}) => (
   <Grid item container direction={'row'} alignItems={'center'} justify={'center'}
         wrap={'nowrap'}>
      <Typography variant='body1' className={'default-text-small-bold'}>{totalLabel}</Typography>
      &nbsp;
      <Typography variant='inherit' className={classNames(classes.totalValue, 'default-text-small-bold')}>
         {numberFormatter('$#,##0.', total)}
      </Typography>
   </Grid>
));

PackageTotal.propTypes = {
   classes: PropTypes.any,
   total: PropTypes.number,
   estimatesComplete: PropTypes.number,
   list: PropTypes.any,
};

PackageTotal.defaultProps = {
   total: 0,
};

export function ImageInfo(props) {
   return (
      <Grid container direction={'column'} justify={'flex-start'} alignItems={props.align}
            style={{height: '100%', position: 'relative'}}>
         <img src={props.src} alt='' className={props.classes.image}
              onError={props.onError} onClick={props.onClick}/>
         <Chip className={props.classes.chipStyle} label={props.imageCount}/>
      </Grid>
   );
}

ImageInfo.propTypes = {
   classes: PropTypes.any,
   align: PropTypes.string,
   src: PropTypes.any,
   onError: PropTypes.func,
   imageCount: PropTypes.any
};

ImageInfo.defaultProps = {
   align: 'center'
};

export let sortedItems = [];
export let currentSortKey = [];

EquipmentList.propTypes = {
   evaluation: PropTypes.object,       // The evaluation returned from the server.
   isLoading: PropTypes.bool,          //Indicates if the evaluation is loading.
   onRefresh: PropTypes.func,          // When the equipment list needs to be refreshed.
   isEstimateError: PropTypes.bool,    // Indicates if an estimate error occurred.
   isEstimateSaving: PropTypes.bool,    // Indicates if an estimate is saving.
};

/**
 * The component to show the equipment list.
 *
 */
export default function EquipmentList({tableRef, evaluation, isLoading = false, onRefresh, onSaving}) {
   const classes = useStyles();
   const theme = useTheme();
   const history = useHistory();
   const location = useLocation();
   const match = useRouteMatch({path: CATALOG_PATH, strict: false, sensitive: false});
   const intl = useIntl();
   const isNarrowView = useWidthRule('down', 'xs');
   const isSmallView = useWidthRule('down', 'sm');
   const isMediumUp = useWidthRule('up', 'md');
   const [subpackage, setSubpackage] = useState(localStorage.subpackage || ALL_SUBPACKAGE_FILTER);

   useEffect(() => {
      if (evaluation && localStorage.subpackage) {
         const list = get(evaluation, 'items') || [];
         const subpackageListIndex = findIndex(list, item => item.item.subpackage === localStorage.subpackage);
         const subpackage = localStorage.subpackage && (subpackageListIndex >= 0) ? localStorage.subpackage :
            ALL_SUBPACKAGE_FILTER;
         setSubpackage(subpackage);
      }
   }, [evaluation]);

   /**
    * Filter the items in the default list of assets by subpackage.
    *
    * @param subpackage The subpackage to filter by.
    * @param defaultList The list of assets to filter.
    * @return {[]} The filtered list of assets.
    */
   const filterSubpackages = (subpackage, defaultList = []) => {
      return (subpackage !== undefined && subpackage !== ALL_SUBPACKAGE_FILTER) ?
         filter(defaultList, item => item.item.subpackage === subpackage) :
         defaultList;
   };

   const sortedList = useMemo(() => filterSubpackages(subpackage, get(evaluation, 'items') || []),
      [subpackage, evaluation]);

   /**
    * Get the selected index by matching the list item with the URI that matches the recent location before the current.
    *
    * @param list The list of items to find the URI.
    * @return {*} The selected index.
    */
   const selectedSK = useMemo(() => {
      const list = sortedList;
      let selectedSK = undefined;

      if (list.length > 0) {
         const locations = history.getRecentLocations();
         //Find the location right before the current that has the item
         const index = findLastIndex(locations, (item) => {
            return item.pathname !== match.url && item.pathname.indexOf('items') >= 0;
         });

         //Find the item with the uri that matches the URL to be selected.
         if (index >= 0) {
            const itemPathname = locations[index].pathname;
            const selectedItem = list.find(item => itemPathname === item.uri);
            selectedSK = selectedItem && selectedItem.SK;
         }
      }

      return selectedSK || get(list, '[0].SK');
   }, [history, match.url, sortedList]);

   const [isSaving, setIsSaving] = useState(false);
   const [savedTime, setSavedTime] = useState();
   const [selected, setSelected] = useState([]);
   const [showNotes, setShowNotes] = useState(false);
   const [showError, setShowError] = useState(false);
   const [errorId, setErrorId] = useState();
   const [errorMessage, setErrorMessage] = useState();

   const [isSelectAll, setIsSelectAll] = useState(false);
   const [removeIndex, setRemoveIndex] = useState();

   const [deleteAsset, {error}] = useLazyRequestForServer();
   const [pinValues, setPinValues] = useState({});
   const [refresh, setRefresh] = useState();
   const [showConfirmRemove, setShowConfirmRemove] = useState(false);
   const [sortKey, setSortKey] = useState();

   /**
    * Only pin column initially for non-IE browsers.
    */
   useEffect(() => {
      if (!detectIE() && !!evaluation) {

         const pinValuesLocal = JSON.parse(localStorage.pinColumns || '{}');
         setPinValues(pinValuesLocal);
      }
   }, [evaluation]);

   useEffect(() => {
      if (!!evaluation) {
         setSelected([]);
      }
   }, [evaluation]);

   useEffect(() => {
      const tableRefCache = tableRef;
      return () => {
         sortedItems = map(tableRefCache.current.state.sortedData, row => row._original);
      }
   }, [tableRef]);

   /**
    * When the user clicks the "New Item" button, go to the "New Item" screen.
    */
   const onAdd = useCallback(() => {
      mixpanel.time_event('Save New Item');
      mixpanel.track('New Item');

      const owner = get(evaluation, 'evaluation.owner') || get(evaluation, 'items[0].owner');
      const ownerName = get(evaluation, 'evaluation.owner_name') || get(evaluation, 'items[0].owner_name') || '';

      history.push(location.pathname, {...location.state, isNew: true, owner, ownerName});
   }, [history, evaluation, location]);

   /**
    * When the user clicks the "Catalog Notes" button, show the notes dialog.
    */
   const handleShowNotes = useCallback(() => {
      setShowNotes(true);
   }, []);

   /**
    * When the user clicks the close button, close the notes dialog.
    */
   const closeShowNotes = () => {
      setShowNotes(false);
   };

   /**
    * Handle changes to the estimates.
    * @param name The name of the tab
    * @param index The index of the row.
    */
   const handleTab = (name, index) => (event) => {
      const newIndex = event.shiftKey ? index - 1 : index + 1;
      const newName = `${name} ${newIndex}`;
      const elements = document.getElementsByName(newName);

      if (elements && elements.length >= 1) {
         console.log('Element name = ', elements[0].name);

         elements[0].focus();
         if (elements[0].select) {
            elements[0].select();
         } else if (elements[0].click) {
            elements[0].click();
         }
         if (isFunction(elements[0].scrollIntoViewIfNeeded)) {
            elements[0].scrollIntoViewIfNeeded();
         } else {
            let objDiv = get(elements, '[0].parentElement.parentElement');
            if (objDiv) {
               objDiv.scrollTop = elements[0].offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
                  (elements[0].offsetHeight / 2);
            }
         }
      }
   };

   const getSelectedIndexes = () => {
      const selectedIndexes = [];

      if (isSelectAll) {
         for (let i = 0; i < selected.length; i++) {
            selectedIndexes.push(i);
         }
      } else {
         for (let itemSK of selected) {
            selectedIndexes.push(findIndex(sortedList, {SK: itemSK}));
         }
      }

      return selectedIndexes;
   }

   const getItemId = index => {
      const itemSK = get(sortedList, `[${index}].item.SK`);

      if (itemSK) {
         return itemSK.split('|')[1];
      }
      console.log(`Could not get item from evaluation`);
      return undefined;
   };

   /**
    * When the user removes the item from the evaluation. The index(es) of the item in the evaluation.
    * @return {Function} The callback to use for this index.
    */
   const onRemoveFromEvaluation = async () => {
      let indexes = isNumber(removeIndex) ? castArray(removeIndex) : getSelectedIndexes();
      const pk = get(evaluation, `PK`, '');
      const evaluationID = encodeURIComponent(pk.substr('evaluation|'.length));

      for (const index of indexes) {
         const item = get(sortedList, `[${index}].item`);
         const itemId = get(item, `item_id`, getItemId(index));

         if (itemId) {
            try {
               deleteAsset(DELETE_EVAL_ITEM.format({evaluationID, itemId}), 'del');
            } catch (error) {
               setShowError(true);
               setErrorId('equipmentList.removeItem.error');
            }
         }
      }
      cancelSelection();
      onRefresh && onRefresh(get(evaluation, `uri`));
   };

   /**
    * Add the subpackage to the selected assets. If the subpackage isn't in the catalog, it is also added to the
    * catalog.
    *
    * @param subpackage The subpackage to add to the asset, and possibly the catalog.
    */
   const handleAddSubpackages = async (subpackage) => {
      let indexes = isNumber(removeIndex) ? castArray(removeIndex) : getSelectedIndexes();
      const originalEvaluation = get(evaluation, 'evaluation') || {};
      const originalSubpackages = get(evaluation, 'evaluation.subpackages') || [];

      for (const index of indexes) {
         const item = get(sortedList, `[${index}].item`);
         const itemId = get(item, `item_id`, getItemId(index));

         if (itemId) {
            try {
               const subpackages = {subpackages: uniq([...originalSubpackages, subpackage])};

               if (!isEqual(subpackages.subpackages, originalSubpackages)) {
                  await submitEvaluation(subpackages, originalEvaluation);
               }

               const subpackageItem = {...item, subpackage: subpackage};
               await submitItem(itemId, subpackageItem, undefined, undefined, item, originalEvaluation);
            } catch (error) {
               setShowError(true);
               setErrorId('equipmentDetail.save.error');
               setErrorMessage(error && error.message);
               error && console.log(error);
            }
         }
      }
      cancelSelection();
      onRefresh && onRefresh(get(evaluation, `uri`));
   };

   /**
    * Remove the subpackage from the asset.
    */
   const handleClearSubpackages = async () => {
      let indexes = isNumber(removeIndex) ? castArray(removeIndex) : getSelectedIndexes();
      const originalEvaluation = get(evaluation, 'evaluation.evaluation') || {};

      for (const index of indexes) {
         const item = get(sortedList, `[${index}].item`);
         const itemId = get(item, `item_id`, getItemId(index));

         if (itemId) {
            try {
               await submitItem(itemId, {...item, subpackage: undefined}, undefined, undefined, item,
                  originalEvaluation);
            } catch (error) {
               setShowError(true);
               setErrorId('equipmentDetail.save.error');
               setErrorMessage(error && error.message);
               error && console.log(error);
            }
         }
      }
      cancelSelection();
      onRefresh && onRefresh(get(evaluation, `uri`));
   };

   /**
    * When the user removes the item from the evaluation. The index(es) of the item in the evaluation.
    * @return {Function} The callback to use for this index.
    */
   const handleUpdateValuationType = async (valuationType) => {
      let indexes = isNumber(removeIndex) ? castArray(removeIndex) : getSelectedIndexes();
      const originalEvaluation = get(evaluation, 'evaluation') || {};

      for (const index of indexes) {
         const item = get(sortedList, `[${index}].item`);
         const itemId = get(item, `item_id`, getItemId(index));

         if (itemId) {
            try {
               await submitItem(itemId, {...item, valuation_type: valuationType}, undefined, undefined, item,
                  originalEvaluation);
            } catch (error) {
               setShowError(true);
               setErrorId('equipmentDetail.save.error');
               setErrorMessage(error && error.message);
               error && console.log(error);
            }
         }
      }
      cancelSelection();
      onRefresh && onRefresh(get(evaluation, `uri`));
   };

   /**
    * Close the error.
    */
   const handleErrorClose = () => {
      setShowError(false);
   };

   /**
    * Determines if the row is selected.
    * @param row The row to check.
    * @return {boolean} True if the row is selected.
    */
   const isRowSelected = (row) => {

      if (row) {
         if (selectedSK !== undefined) {
            return selectedSK === row.original.SK;
         }
      }

      return false;
   };

   const getTrProps = (state, rowInfo) => {
      return {
         className: isRowSelected(rowInfo) ? 'selected' : undefined,
      }
   };

   const cancelSelection = () => {
      setSelected([]);
      setIsSelectAll(false);
      setRemoveIndex(undefined);
      setShowConfirmRemove(false);
      setRefresh(Date.now());
   };

   const handleConfirmRemoveFromEvaluation = (removeIndex) => {
      setShowConfirmRemove(true);
      setRemoveIndex(isNumber(removeIndex) ? removeIndex : undefined);
   };

   /**
    * When the subpackages are shown, calculate subpackage totals for all the subpackages. The property other contains
    * all the values not specifically in a subpackage.
    *
    * @return {{}} The object for the subpackages with the subpackages as the property name and the total as the value.
    */
   const handleShowSubpackages = useCallback(() => {
      const subpackages = {};

      // For all the assets in the catalog.
      for (const item of evaluation.items) {
         const key = item.item.subpackage || 'other';
         if (subpackages[key] === undefined) {
            subpackages[key] = 0;
         }
         subpackages[item.item.subpackage || 'other'] += Number(item.consensus) || 0;
      }
      return subpackages;
   }, [evaluation]);

   /**
    * Callback to filter the list by subpackage.
    *
    * @param subpackage The subpackage to filter by.
    */
   const handleFilterSubpackages = useCallback((subpackage) => {
      setSubpackage(subpackage);
   }, []);

   const handleCloseConfirm = () => {
      setShowConfirmRemove(false);
   };

   const handleSort = useCallback(sort => {
      setSortKey([{id: sort, desc: false}]);
   }, []);

   const estimateTotals = useMemo(() => {
      if (sortedList.length > 0 && evaluation) {
         const estimateTypes = getUserData().estimateTypes;
         const estimateTotalsLocal = [];
         if (estimateTypes && estimateTypes.length > 0) {
            for (const estimateType of estimateTypes) {
               let total;
               if (estimateType.active) {
                  if (estimateType.enable_workflow_columns) {
                     total = sumBy(sortedList, item => getYourEstimate(item, estimateType).value);
                  }
                  const consensus = sumBy(sortedList, item => getConsensusEstimate(item, estimateType).value);
                  estimateTotalsLocal.push({estimateType, total, consensus});
               }
            }
         }
         return estimateTotalsLocal;
      }

      return [];
   }, [sortedList, evaluation, savedTime]);

   const columns = useMemo(() => {
      /**
       * Listen for the save changes to update the total and saved time, etc.
       *
       * @param isSaving Indicates if the item is being saved.
       * @param isError Indicates if an error has occurred saving the item.
       */
      const onSave = (isSaving, isError) => {
         setIsSaving(isSaving);
         setSavedTime(new Date());
         onSaving && onSaving(isSaving, !isSaving && !isError ? new Date() : undefined);
      };

      /**
       * Navigate to the Equipment Detail screen.
       *
       * @param uri The URI of the asset.
       */
      const handleClick = (uri) => (event) => {
         event.preventDefault();
         mixpanel.track('Entered Photo View');
         history.push(uri);
      };

      const handleSelectAll = ({target}) => {
         const isSelectAll = target.checked;
         const selected = isSelectAll ? map(sortedList, 'SK').sort() : [];
         setSelected(selected);
         setIsSelectAll(isSelectAll);
         setRefresh(Date.now());
      };

      const handlePinUnpin = (name) => event => {
         event.stopPropagation();
         event.preventDefault();

         const newPinValues = {...pinValues, [name]: !pinValues[name]};
         setPinValues(newPinValues);
         localStorage.pinColumns = JSON.stringify(newPinValues);
         setRefresh(Date.now());
      };

      const getDate = (row) => {
         if (row.value) {
            return moment(row.value).format(DATE_FORMAT);
         } else {
            return formatMessage(intl, 'date.missing', 'N/A');
         }
      }

      const handleEdit = evalItem => () => {
         mixpanel.track('Edit Item');

         if (match.params.owner === undefined) {
            history.push(match.url, {...location.state, isEdit: true, backPath: location.pathname});
         } else {
            history.push(EDIT_ITEM.format({itemId: get(evalItem, 'item.item_id')}),
               {...location.state, isEdit: true, backPath: location.pathname});
         }
      };

      const handleSelect = ({target}) => {
         const id = target.name;
         let selectedCopy = (selected && selected.slice(0)) || [];
         const index = sortedIndexOf(selectedCopy, id);
         if (index >= 0) {
            pullAt(selectedCopy, [index]);
         } else {
            const sortIndex = sortedIndex(selectedCopy, id);
            selectedCopy.splice(sortIndex, 0, id);
         }
         const isSelectAllLocal = selectedCopy.length >= sortedList.length;
         setSelected(selectedCopy);
         setIsSelectAll(isSelectAllLocal);
         setRefresh(Date.now());
      };

      const getEstimateColumns = () => {
         const estimateColumns = [];
         const estimateTypes = getUserData().estimateTypes || [];

         for (const estimateType of estimateTypes) {
            if (estimateType.active) {
               if (estimateType.enable_workflow_columns) {
                  const otherDisplayName = formatMessage(intl, 'equipmentList.allEstimates.label', 'All {name}s',
                     estimateType);
                  const otherName = 'All' + estimateType.name;
                  estimateColumns.push({
                     id: otherName,
                     Header: (
                        <Grid container className='sortContent' direction={'row'} wrap={'nowrap'} alignItems={'center'}>
                           <Typography className={`editableTextStyle column-title `}>
                              {otherDisplayName}
                           </Typography>
                           <IconButton onClick={handlePinUnpin(otherName)} style={{padding: 6}}>
                              <PinIcon htmlColor={theme.palette.button.pin} style={{fontSize: 18}}/>
                           </IconButton>
                        </Grid>
                     ),
                     accessor: row => {
                        return filter(row.estimates, {'estimate_type_name': estimateType.name});
                     },
                     show: !isSmallView && (isFeatureEnabled(SEE_ESTIMATES) || isAdminOnly()),
                     fixed: pinValues[otherName] ? 'right' : undefined,
                     minWidth: Math.max(otherDisplayName.length * CHAR_WIDTH_MULTIPLIER + PIN_WIDTH + MARGIN_WIDTH, MIN_COLUMN_WIDTH),
                     maxWidth: Math.max(otherDisplayName.length * (CHAR_WIDTH_MULTIPLIER * 2) + PIN_WIDTH + MARGIN_WIDTH, MIN_COLUMN_WIDTH),
                     Cell: row => {
                        return (
                           <GridOverflow key={row.index + row.original.SK + 'gridOverflow1'}
                                         id={row.index + row.original.SK + 'gridOverflow1'} container direction={'row'}
                                         wrap={'nowrap'}
                                         paddingTop={pinOtherEstimates ? '20%' : 10}
                                         popupBackgroundColor={pinOtherEstimates ? 'white' :
                                            theme.palette.environment.light.level4.base}
                                         backgroundColor={pinOtherEstimates ?
                                            theme.palette.environment.light.level4.base :
                                            'white'}
                                         spacing={1} overflowStyle={{paddingTop: 4, paddingLeft: 4}}
                                         style={{height: 'calc(100% + 10px)', position: 'relative'}}>
                              {row.value && row.value.length > 0 && (row.value.map((estimate, index) => {
                                 const name = estimate.user_name || estimate.user_email || estimate.user;
                                 return (
                                    <Grid key={index + ' ' + row.index + row.original.SK + 'gridOverflow11'} container
                                          item
                                          direction={'column'}>
                                       <Grid item className={'right-align'}>
                                          <Typography key={row.index + otherName}
                                                      className={`${classes.defaultTextStyle} right-align`} noWrap>
                                             {name}&nbsp;
                                          </Typography>
                                       </Grid>
                                       <Grid item>
                                          <Typography key={row.index + 'otherEstimates'}
                                                      className={`${classes.defaultTextStyle} right-align`} noWrap>
                                             {numberFormatter('$#,###,##0.', estimate.value)}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 )
                              }))}
                           </GridOverflow>
                           // </Grid>
                        )
                     }
                  });
                  const meanDisplayName = formatMessage(intl, 'equipmentList.meanEstimates.label',
                     '{display_name} Mean',
                     estimateType);
                  const meanName = 'Mean' + estimateType.name;
                  estimateColumns.push({
                     id: meanName,
                     Header: (
                        <Grid container className='sortContent' direction={'row'} wrap={'nowrap'} alignItems={'center'}>
                           <Typography className={`editableTextStyle column-title `}>
                              {meanDisplayName}
                           </Typography>
                           <IconButton onClick={handlePinUnpin(meanName)} style={{padding: 6}}>
                              <PinIcon htmlColor={theme.palette.button.pin} style={{fontSize: 18}}/>
                           </IconButton>
                        </Grid>
                     ),
                     accessor: 'ignored',
                     show: !isSmallView && (isFeatureEnabled(SEE_ESTIMATES) || isAdminOnly()),
                     fixed: pinValues[meanName] ? 'right' : undefined,
                     minWidth: Math.max(meanDisplayName.length * CHAR_WIDTH_MULTIPLIER + PIN_WIDTH + MARGIN_WIDTH, MIN_COLUMN_WIDTH),
                     maxWidth: Math.max(meanDisplayName.length * (CHAR_WIDTH_MULTIPLIER * 2) + PIN_WIDTH + MARGIN_WIDTH, MIN_COLUMN_WIDTH),
                     Cell: row => {
                        const values = map(filter(row.original.estimates, {'estimate_type_name': estimateType.name}),
                           'value');
                        const value = mean(compact(values)) || '';
                        return (
                           <Grid key={meanName + 'key'} container direction={'row'} style={{height: 'calc(100% + 3px)'}}
                                 alignItems={'center'}
                                 justify={'flex-end'}>
                              <Typography key={row.index + 'meanEstimate'}
                                          className={`${classes.defaultTextStyle} right-align`}
                                          noWrap showFullOnHover backgroundColor={pinValues[meanName] ? 'white' :
                                 theme.palette.environment.light.level4.base}>
                                 {numberFormatter('$#,###,##0.', value)}
                              </Typography>
                           </Grid>
                        )
                     }
                  });
                  const estimateDisplayName = formatMessage(intl, 'equipmentList.yourEstimates.label',
                     'Your {display_name}',
                     estimateType);
                  const estimateName = 'Your' + estimateType.name;
                  estimateColumns.push({
                        id: estimateName,
                        Header: (
                           <Grid container className='sortContent' direction={'row'} wrap={'nowrap'} alignItems={'center'}>
                              <Typography className={`editableTextStyle column-title `}>
                                 {estimateDisplayName}
                              </Typography>
                              <IconButton onClick={handlePinUnpin(estimateName)} style={{padding: 6}}>
                                 <PinIcon htmlColor={theme.palette.button.pin} style={{fontSize: 18}}/>
                              </IconButton>
                           </Grid>
                        ),
                        accessor: row => {
                           return getYourEstimate(row, estimateType).value;
                        },
                        show: !isSmallView,
                        fixed: pinValues[estimateName] ? 'right' : undefined,
                        minWidth: Math.max(estimateDisplayName.length * CHAR_WIDTH_MULTIPLIER + PIN_WIDTH + MARGIN_WIDTH, MIN_COLUMN_WIDTH),
                        maxWidth: Math.max(estimateDisplayName.length * (CHAR_WIDTH_MULTIPLIER * 2) + PIN_WIDTH + MARGIN_WIDTH, MIN_COLUMN_WIDTH),
                        Cell: row => (
                           <EditableEstimate key={`${estimateName} ${row.original.SK}`} estimateType={estimateType}
                                             inputName={`${estimateName} ${row.viewIndex}`}
                                             value={row.value}
                                             evalItem={row.original} intl={intl} isConsensus={false}
                                             evaluation={evaluation} onSave={onSave}
                                             onTab={handleTab(estimateName, row.viewIndex)}/>
                        ),
                     },
                  );
               }
               const consensusDisplayName = estimateType.display_name;
               const consensusName = estimateType.name;
               estimateColumns.push(
                  {
                     id: consensusName,
                     Header: (
                        <Grid container className='sortContent' direction={'row'} wrap={'nowrap'} alignItems={'center'}>
                           <Typography className={`editableTextStyle column-title`}>
                              {consensusDisplayName}
                           </Typography>
                           <IconButton onClick={handlePinUnpin(consensusName)} style={{padding: 6}}>
                              <PinIcon htmlColor={theme.palette.button.pin} style={{fontSize: 18}}/>
                           </IconButton>
                        </Grid>
                     ),
                     accessor: 'consensus_values',
                     show: !isSmallView,
                     fixed: pinValues[consensusName] ? 'right' : undefined,
                     minWidth: Math.max(consensusDisplayName.length * CHAR_WIDTH_MULTIPLIER + PIN_WIDTH + MARGIN_WIDTH, MIN_COLUMN_WIDTH),
                     maxWidth: Math.max(consensusDisplayName.length * (CHAR_WIDTH_MULTIPLIER * 2) + PIN_WIDTH + MARGIN_WIDTH, MIN_COLUMN_WIDTH),
                     Cell: row => (
                        <EditableEstimate key={'consensus' + row.original.SK} estimateType={estimateType}
                                          inputName={`${consensusName} ${row.viewIndex}`}
                                          value={get(find(row.value, {name: estimateType.name}), 'value')}
                                          evalItem={row.original} intl={intl} isConsensus={true}
                                          evaluation={evaluation} onSave={onSave}
                                          label={estimateType.display_name}
                                          onTab={handleTab(consensusName, row.viewIndex)}/>
                     ),
                  },
               )
            }
         }
         return estimateColumns;
      };

      const {pinOtherEstimates} = pinValues;

      const hasSubpackages = isFeatureEnabled(SUB_PACKAGES) &&
         !!get(evaluation, 'evaluation.subpackages.length', 0) > 0;
      setRefresh(Date.now());
      return [
         {
            id: 'select',
            Header: () => {
               return (
                  <Grid container justify={'center'} alignItems={'center'} style={{height: '100%'}}>
                     <CustomCheckbox checked={isSelectAll} onClick={handleSelectAll}
                                     disabled={get(evaluation, 'items.length', 0) <= 0}/>
                  </Grid>
               )
            },
            Cell: row => (
               <Grid container justify={'center'} alignItems={'center'} style={{height: '100%'}}>
                  <CustomCheckbox name={row.original.SK}
                                  checked={isSelectAll || sortedIndexOf(selected, row.original.SK) >= 0}
                                  onClick={handleSelect}
                  />
               </Grid>
            ),
            sortable: false,
            fixed: 'left',
            width: isNarrowView ? 36 : 56,
         },
         {
            id: 'edit',
            Header: (<div className={classes.fillerStyle}/>),
            show: !isNarrowView,
            Cell: row => (
               <Grid container justify={'center'} alignItems={'center'} style={{height: '100%'}}>
                  <Button className={'secondary-minimal-button-small no-print'}
                          style={{marginLeft: isNarrowView ? undefined : 8}}
                          variant={'text'}
                          onClick={handleEdit(row.original)} disableRipple={true}
                  >
                     <Typography color='inherit' variant='button' id={'equipmentList.edit.button'}/>
                  </Button>
               </Grid>
            ),
            sortable: false,
            fixed: 'left',
            width: 75,
         },
         {
            id: 'image',
            Header: (<div className={classes.fillerStyle}/>),
            width: THUMBNAIL_SIZE + 23,
            fixed: isNarrowView ? undefined : 'left',
            sortable: false,
            Cell: row => {
               let image = getProfileThumbnail(row.original);
               const imageCount = filter(get(row.original, 'image_data', []), {deleted: false}).length;
               return <>
                  <Link className={classes.linkStyle} to={row.original.uri}>
                     <ImageInfo key={row.index + 'image'} classes={classes} src={image}
                                onError={(e) => {e.target.src = PLACEHOLDER_IMAGE}}
                                imageCount={imageCount} onClick={handleClick(row.original.uri)}/>
                  </Link>
                  <Hidden smUp>
                     <Grid container justify={'center'} alignItems={'center'}
                           style={{display: isNarrowView ? undefined : 'none', height: '100%', paddingTop: 8}}
                     >
                        <Button className={'secondary-minimal-button-small no-print'}
                                style={{marginLeft: isNarrowView ? undefined : 8}}
                                variant={'text'}
                                onClick={handleEdit(row.original)} disableRipple={true}
                        >
                           <Typography color='inherit' variant='button' id={'equipmentList.edit.button'}/>
                        </Button>
                     </Grid>
                  </Hidden>
               </>
            },
         },
         {
            id: 'info',
            minWidth: 50,
            sortable: false,
            show: isNarrowView,
            Cell: row => {
               return <EquipmentListItemDescription evalItem={row.original.item} eval={row.original}/>
            },
         },
         {
            id: 'subpackage',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.subpackage.column'}/>
            ),
            accessor: 'item.subpackage',
            show: hasSubpackages && !isNarrowView,
            isShow: hasSubpackages && !isNarrowView,
            width: 125,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'valuationType',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.valuationType.column'}/>
            ),
            accessor: 'item.valuation_type',
            show: !isNarrowView,
            isShow: !isNarrowView,
            width: 125,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'year',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.year.column'}/>
            ),
            accessor: 'item.year',
            show: !isNarrowView,
            isShow: !isNarrowView,
            width: 75,
         },
         {
            id: 'make',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.make.column'}/>
            ),
            accessor: 'item.make',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 100,
            maxWidth: 260,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'model',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.model.column'}/>
            ),
            accessor: 'item.model',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 100,
            maxWidth: 260,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'description',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.description.column'}/>
            ),
            accessor: 'item.description',
            show: !isNarrowView,
            minWidth: 160,
            maxWidth: 260,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'hours',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.hours.column'}/>
            ),
            accessor: row => getMeterFromAsset(row, 'Hours'),
            show: !isNarrowView,
            isShow: !isNarrowView,
            Cell: row => (
               <Typography className={`${classes.hourStyle} hours`} noWrap showFullOnHover>
                  {numberFormatter('#,###.', row.value)}
               </Typography>
            ),
            width: 126,
         },
         {
            id: 'miles',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.miles.column'}/>
            ),
            accessor: row => getMeterFromAsset(row, 'Miles'),
            show: !isNarrowView,
            isShow: !isNarrowView,
            Cell: row => (

               <Typography className={`${classes.hourStyle} hours`}>
                  {numberFormatter('#,###.', row.value, 'Miles')}
               </Typography>
            ),
            width: 126,
         },
         {
            id: 'otherMeters',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.otherMeters.column'}/>
            ),
            accessor: row => getOtherMetersFromAsset(row),
            show: !isNarrowView,
            isShow: !isNarrowView,
            width: 126,
            Cell: row => {
               return (
                  <GridOverflow key={row.index + row.original.SK + 'gridOverflowOtherMeters'}
                                id={row.index + row.original.SK + 'gridOverflowOtherMeters'} container direction={'row'}
                                wrap={'nowrap'}
                                paddingTop={10}
                                popupBackgroundColor={theme.palette.environment.light.level4.base}
                                backgroundColor={'white'}
                                spacing={1} overflowStyle={{paddingTop: 4, paddingLeft: 4}}
                                style={{height: 'calc(100% + 10px)', position: 'relative'}}>
                     {row.value && row.value.length > 0 && (row.value.map((meter, index) => {
                        return (
                           <Grid key={index + ' ' + row.index + row.original.SK + 'gridOverflowOtherMeters'} container
                                 item
                                 direction={'column'}>
                              <Grid item className={'right-align'}>
                                 <Typography key={row.index + 'otherMetersName'}
                                             className={`${classes.defaultTextStyle} right-align`} noWrap>
                                    {meter.name || meter.units}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 <Typography key={row.index + 'otherMeter'}
                                             className={`${classes.hourStyle} hours  right-align`} noWrap>
                                    {numberFormatter('#,###.', meter.value)}
                                 </Typography>
                              </Grid>
                           </Grid>
                        )
                     }))}
                  </GridOverflow>
                  // </Grid>
               )
            }
         },
         {
            id: 'serial',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.serial.column'}/>
            ),
            accessor: 'item.serial',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 160,
            maxWidth: 260,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'vin',
            Header: (
               <Typography className={`column-title sortContent`} id={'equipmentList.vin.column'}/>
            ),
            accessor: 'item.vin',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 160,
            maxWidth: 260,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'uniqueId',
            Header: <Typography className={`column-title sortContent`} id={'equipmentList.uniqueId.column'}/>,
            accessor: 'item.unique_id',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 160,
            maxWidth: 260,
            Cell: row => (
                <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'sticker',
            Header: <Typography className={`column-title sortContent`} id={'equipmentList.sticker.column'}/>,
            accessor: 'item.sticker_id',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 160,
            maxWidth: 260,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'contract',
            Header: <Typography className={`column-title sortContent`} id={'equipmentList.contract.column'}/>,
            accessor: 'item.pw_contract_price',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 160,
            maxWidth: 260,
            Cell: row => (
               <a href={row.original.purplewave_url}>
                  <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>
                     {numberFormatter('$#,###,##0.', row.value)}
                  </Typography>
               </a>
            ),
         },
         {
            id: 'current',
            Header: <Typography className={`column-title sortContent`} id={'equipmentList.current.column'}/>,
            accessor: 'item.latest_auction_price',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 160,
            maxWidth: 260,
            Cell: row => (
               <a href={row.original.purplewave_url}>
                  <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>
                     {numberFormatter('$#,###,##0.', row.value)}
                  </Typography>
               </a>
            ),
         },
         {
            id: 'inventory',
            Header: <Typography className={`column-title sortContent`} id={'equipmentList.inventoryId.column'}/>,
            accessor: 'item.personal_id',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 160,
            maxWidth: 260,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         {
            id: 'location',
            Header: <Typography className={`column-title sortContent`} id={'equipmentList.location.column'}/>,
            accessor: 'item.location',
            show: !isNarrowView,
            isShow: !isNarrowView,
            minWidth: 160,
            maxWidth: 260,
            Cell: row => (
               <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>{row.value}</Typography>
            ),
         },
         ...getEstimateColumns(),
         {
            id: 'created',
            Header: <Typography className={`column-title sortContent`} id={'equipmentList.created.column'}/>,
            accessor: 'added',
            show: !isNarrowView,
            Cell: row => <Typography>{getDate(row)}</Typography>,
            sortMethod: sortDate,
            minWidth: 136,
         },
         {
            id: 'updated',
            Header: <Typography className={`column-title sortContent`} id={'equipmentList.updated.column'}/>,
            accessor: 'item.updated',
            show: !isNarrowView,
            Cell: row => <Typography>{getDate(row)}</Typography>,
            sortMethod: sortDate,
            minWidth: 136,
         },
         {
            id: 'moreInformation',
            Header: <Typography className={`column-title sortContent`}>More Info</Typography>,
            accessor: 'item.notes',
            show: !isNarrowView,
            Cell: row => <Typography showFullOnHover>{getMoreInformationFromAsset(row.original)}</Typography>,
            minWidth: 80,
         },
      ];
   }, [
      sortedList, evaluation, history, classes, intl, isNarrowView, isSelectAll, isSmallView,
      pinValues, selected, theme.palette.button.pin, theme.palette.environment.light.level4.base, location.pathname,
      location.state, match.params.owner, match.url
   ]);

   const handleSortChange = (sortKey) => {
      setSortKey(sortKey);
      currentSortKey = sortKey;
   };

   let ownerName;
   let title;
   let notes;
   let valuation_notes;
   let subpackages;

   const selectedCount = selected.length;
   const selectedLength = isNumber(removeIndex) ? 1 : selectedCount;

   if (evaluation) {
      notes = get(evaluation, 'evaluation.notes') || 'None';
      valuation_notes = get(evaluation, 'evaluation.valuation_notes');
      ownerName = get(evaluation, 'evaluation.owner_name',
         get(evaluation, 'evaluation.owner', get(evaluation, 'items[0].owner')));
      title = `${process.env.REACT_APP_SITE_TITLE} - ${ownerName}`;
      subpackages = get(evaluation, 'evaluation.subpackages') || [];
   } else {
      title = process.env.REACT_APP_SITE_TITLE;
      subpackages = [];
   }

   const estimateTotal = 0;
   const total = 0;
   return (
      <DocumentTitle title={title}>
         <Fragment>
            {showError && <DisplayError error={error} errorId={errorId} onClose={handleErrorClose}/>}
            {showConfirmRemove && (
               <ModalDialog onClose={handleCloseConfirm}
                            onSubmit={onRemoveFromEvaluation}
                            messageKey={'equipmentList.removeItem.confirm'}
                            titleKey={'equipmentList.removeItem.confirm.title'}
                            values={{itemCount: selectedLength}}
                            titleValues={{itemCount: selectedLength}}
                            maxWidth={'xs'}
                            submitKey={'delete.button'} submitColorStyle={'destroy-button'}
                            cancelColorStyle={'secondary-color'}
               />
            )}
            {showNotes && (
               <ModalDialog onClose={closeShowNotes}
                            message={notes}
                            titleKey={isMediumUp ? 'equipmentList.showNotes.title' :
                               'equipmentList.showDetails.title'}
                            maxWidth={'xs'}
                            fullWidth
                            cancelKey={'close.button'}
                            cancelColorStyle={'primary-color-button'}
               >
                  {valuation_notes && <>
                     <Typography className={`nonessential-title-caps ${classes.evaluationNotesStyle}`}>
                        {'Valuation Notes'}
                     </Typography>
                     <Typography className={'normal-default-text'}>
                        {valuation_notes}
                     </Typography>
                  </>}
                  <Hidden mdUp>
                     <Grid className={classes.estimatesFrameStyle} container direction={'column'} wrap={'nowrap'}>
                        {estimateTotals.map(estimateTotal => (
                           <Fragment>
                              {(estimateTotal.estimateType?.active) && (
                                 <Grid className={classes.estimatesStyle} container direction={'row'} spacing={2}
                                       wrap={'nowrap'} alignItems={'center'}>
                                    {(estimateTotal.estimateType?.enable_workflow_columns) && (
                                       <Grid item>
                                          <PackageTotal total={estimateTotal.total}
                                                        totalLabel={'Your ' + estimateTotal.estimateType.display_name}/>
                                       </Grid>
                                    )}
                                    <Grid item>
                                       <Feature feature={SUB_PACKAGES}>
                                          <PackageTotal classes={classes} total={estimateTotal.consensus}
                                                        totalLabel={estimateTotal.estimateType.display_name +
                                                        ' Total'}/>
                                       </Feature>
                                       <NotFeature feature={SUB_PACKAGES}>
                                          <PackageTotal classes={classes} total={estimateTotal.consensus}
                                                        totalLabel={estimateTotal.estimateType.display_name +
                                                        ' Total'}/>
                                       </NotFeature>
                                    </Grid>
                                 </Grid>
                              )}
                           </Fragment>
                        ))}
                     </Grid>
                  </Hidden>
               </ModalDialog>
            )}

            <Grid container item direction={'column'} className={classes.innerTableFrame}>
               <Grid item style={{flex: '0 0 auto', width: '100%'}}>
                  {selectedCount > 0 ? (
                     <MultiSelectItemBar onCancel={cancelSelection} onAddSubpackages={handleAddSubpackages}
                                         onClearSubpackages={handleClearSubpackages} subpackages={subpackages}
                                         onRemove={handleConfirmRemoveFromEvaluation}
                                         onShowSubpackages={handleShowSubpackages}
                                         onSelectValuationType={handleUpdateValuationType}
                                         selectedCount={selectedCount} total={total} estimateTotal={estimateTotal}
                                         isWideView={isMediumUp}
                     />
                  ) : (
                     <AssetTableHeader
                        isWideView={isMediumUp} isNarrowView={isNarrowView} saving={isSaving}
                        total={total}
                        evaluation={evaluation}
                        onAdd={onAdd}
                        onShowNotes={handleShowNotes}
                        estimateTotals={estimateTotals}
                        onShowSubpackages={handleShowSubpackages}
                        onSort={handleSort}
                        sortBy={sortKey}
                        onFilterSubpackages={handleFilterSubpackages}
                     />
                  )}
               </Grid>
               <Grid item style={{
                  overflow: 'hidden',
                  flex: '1 1',
                  position: 'relative',
                  display: '-ms-flexbox',
                  maxWidth: '100%'
               }}>
                  <AssetTable key={'Assets' + refresh} tableRef={tableRef} list={sortedList} loading={isLoading}
                              defaultSort={sortKey}
                              trProps={getTrProps}
                              columns={columns}
                              onSortedChange={handleSortChange}
                  />
               </Grid>
            </Grid>
         </Fragment>
      </DocumentTitle>
   );
}

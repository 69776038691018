import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import {every} from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {PureComponent, Fragment} from 'react';
import {SAVE_WORKFLOW_STATUS} from '../../../Constants';
import ModalDialog from '../../../fhg/components/dialog/ModalDialog';
import DisplayError from '../../../fhg/components/DisplayError';
import Typography from '../../../fhg/components/Typography';
import {requestForServer} from '../../../Utils/ServerUtil';

const styles = theme => ({
   formControl: {
      color: 'inherit',
      backgroundColor: 'transparent',
      '&:focus': {
         backgroundColor: 'transparent',
      }
   },
   select: {
      background: 'transparent',
      '&:focus': {
         background: 'transparent',
      }
   },
   dot: {
      height: 8,
      width: 8,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
   },
   captureInProgress: {
      backgroundColor: theme.palette.status.captureInProgress, //'#7ED321',
   },
   evalRequested: {
      backgroundColor: theme.palette.status.evalRequested, //'#F8CE1C',
   },
   evalInProgress: {
      backgroundColor: theme.palette.status.evalInProgress, //'#4A90E2',
   },
   inReview: {
      backgroundColor: theme.palette.status.inReview, //'#F57A23',
   },
   finalized: {
      backgroundColor: theme.palette.status.finalized, //'#406370',
   },
   archived: {
      backgroundColor: theme.palette.status.archived, //'#D8D8D8',
   },
   menuText: {
      display: 'inline',
      whiteSpace: 'normal',
   },
});

class WorkflowStatusSelect extends PureComponent {

   state = {};

   constructor(props, context) {
      super(props, context);

      this.state = {
         status: WorkflowStatusSelect.getNewStatus(props),
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      const newStatus = WorkflowStatusSelect.getNewStatus(this.props, prevProps, this.state);
      if (newStatus) {
         this.setState({status: newStatus});
      }
   }

   static getNewStatus(nextProps, prevProps = {}, currentState = {}) {
      const {status} = nextProps;
      let newStatus;
      if (status && (status !== prevProps.status || status !== currentState.status)) {
         newStatus = status;
      } else if (nextProps.evaluation) {
         const nextStatus = get(nextProps, 'evaluation.evaluation.workflow_status');
         const previousStatus = get(prevProps, 'evaluation.evaluation.workflow_status');
         if (nextStatus !== previousStatus) {
            newStatus = nextStatus;
         }
      }
      return newStatus;
   }

   /**
    * Handle changes to the status.
    * @param event The change event.
    */
   handleChange = (event) => {
      const value = event.target.value;
      this.confirmFinalize(value, this.props.evaluation, () => {
         this.setState({[event.target.name]: value}, this.setStatus(value));
         this.props.onChange && this.props.onChange(event)
      });
   };

   confirmFinalize = (status, evaluation, submit) => {
      if (status === 'finalized' && !every(evaluation.items, value => Number(value.consensus) > 0)) {
         this.resolveConfirmFinalize = event => {
            event.stopPropagation();
            event.preventDefault();
            this.setState({showConfirmFinalize: false}, submit);
         };
         this.rejectConfirmFinalize = event => {
            event.stopPropagation();
            event.preventDefault();
            this.setState({showConfirmFinalize: false});
         };
         this.setState({showConfirmFinalize: true});
      } else {
         submit();
      }
   };

   /**
    * Set the status of the evaluation.
    * @param status The new status.
    * @return {Function} Set status callback.
    */
   setStatus = (status) => async () => {
      if (this.props.doHandleChanges && get(this.props, 'evaluation.evaluation')) {
         await requestForServer(SAVE_WORKFLOW_STATUS.format({id: this.props.evaluation.evaluation.PK, status}), 'put');
         this.props.evaluation.evaluation.workflow_status = status;
      } else {
         console.log('Status could not be saved.');
      }
   };

   render() {
      const {classes, onChange, doHandleChanges, ...otherProps} = this.props;
      const {status, showError, showConfirmFinalize} = this.state;

      return (
         <Fragment>
            {showError && <DisplayError errorId={'evalDashboard.consensus.error'} error={true} enableRefresh={false}
                                        onClose={() => {this.setState({showError: false})}}/>}
            {showConfirmFinalize && (
               <ModalDialog open={showConfirmFinalize} onClose={this.rejectConfirmFinalize}
                            onSubmit={this.resolveConfirmFinalize}
                            messageKey={'evalDashboard.consensus.error'}
                            titleKey={'evalDashboard.consensus.title'}
                            maxWidth={'xs'}
                            submitKey={'evalDashboard.consensus.submit'}
               />
            )}
            <FormControl className={classes.formControl} {...otherProps}>
               <Select
                  value={status || ''}
                  classes={{
                     select: classes.select,
                  }}
                  onClick={(e) => {
                     e.stopPropagation();
                     e.preventDefault();
                  }}
                  onChange={this.handleChange}
                  input={<Input name='status' disableUnderline={true}
                                style={{marginRight: 8, fontSize: 18}}/>}
               >
                  <MenuItem value={'capture_in_progress'}>
                     <span className={classNames(classes.dot, classes.captureInProgress)}/>
                     <Typography className={classes.menuText} color='inherit' variant='body1'
                                 id={'capture_in_progress'}/>
                  </MenuItem>
                  <MenuItem value={'eval_requested'}>
                     <span className={classNames(classes.dot, classes.evalRequested)}/>
                     <Typography className={classes.menuText} color='inherit' variant='body1'
                                 id={'eval_requested'}/>
                  </MenuItem>
                  <MenuItem value={'eval_in_progress'}>
                     <span className={classNames(classes.dot, classes.evalInProgress)}/>
                     <Typography className={classes.menuText} color='inherit' variant='body1'
                                 id={'eval_in_progress'}/>
                  </MenuItem>
                  <MenuItem value={'in_review'}>
                     <span className={classNames(classes.dot, classes.inReview)}/>
                     <Typography className={classes.menuText} color='inherit' variant='body1'
                                 id={'in_review'}/>
                  </MenuItem>
                  <MenuItem value={'finalized'}>
                     <span className={classNames(classes.dot, classes.finalized)}/>
                     <Typography className={classes.menuText} color='inherit' variant='body1'
                                 id={'finalized'}/>
                  </MenuItem>
                  <MenuItem value={'archived'}>
                     <span className={classNames(classes.dot, classes.archived)}/>
                     <Typography className={classes.menuText} color='inherit' variant='body1'
                                 id={'archived'}/>
                  </MenuItem>
               </Select>
            </FormControl>
         </Fragment>
      );
   }
}

WorkflowStatusSelect.propTypes = {
   classes: PropTypes.any,
   status: PropTypes.any,
   onChange: PropTypes.func,
   doHandleChanges: PropTypes.bool,
   evaluation: PropTypes.object,
};

WorkflowStatusSelect.defaultProps = {
   doHandleChanges: false,
};

export default withStyles(styles)(WorkflowStatusSelect);
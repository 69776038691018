import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import FormattedTextField from './FormattedTextField';

/**
 * Render the suggestions.
 *
 * @param props The props for the suggestion container.
 * @return {*} JSX representing the suggestions.
 */
function renderSuggestionsContainer(props) {
   const { containerProps, children } = props;

   return (
      <Paper {...containerProps} square>
         {children}
      </Paper>
   );
}

/**
 * Render the Input component.
 *
 * @param inputProps The props to pass to the input component.
 * @return {*} The JSX for the input component.
 */
function renderInput(inputProps) {
   const {classes, ref, error, helperText, fullWidth, label, ...other} = inputProps;

   let inputPropsClass = {
      inputRef: ref,
      ...other,
   };

   if (classes.input) {
      inputPropsClass.classes = { input: classes.input };
   }

   return (
      <FormattedTextField
         fullWidth={fullWidth}
         label={label}
         error={error}
         helperText={helperText}
         InputProps={inputPropsClass}
      />
   );
}

const styles = theme => ({
   container: {
      position: 'relative',
   },
   suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      maxHeight: 200,
      overflowY: 'auto',
   },
   suggestion: {
      display: 'block',
   },
   suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
   },
});

/**
 * The Formatted Text component which can have suggestions.
 */
class FormattedTextAutoSuggest extends Component {

   static propTypes = {
      classes: PropTypes.object.isRequired,
      onFetchSuggestions: PropTypes.func,
      onClearSuggestions: PropTypes.func,
      getSuggestionValue: PropTypes.func,
      suggestions: PropTypes.array,
   };

   static defaultProps = {
      suggestions: [],
   };

   state = {
      isBlurred: false,
   };

   /**
    * Scroll when the user uses keys to navigate the menu.
    * @param event The Key event.
    */
   handleKey = (event) => {
      if (!this.state.isBlurred) {
         if (event.keyCode === 39 || event.keyCode === 40 || event.keyCode === 38 || event.keyCode === 37) {
            setTimeout(() => {
               this.scrollIntoView();
            }, 0);
         } else if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
         }
      }
   };

   /**
    * Render a single suggestion with highlighting for the matched portion.
    *
    * @param suggestion The suggestion to render.
    * @param query The text for auto suggest.
    * @param isHighlighted Indicates if the suggestion is highlighted.
    * @return {*} The JSX with hightlighted suggestion.
    */
   renderSuggestion = (suggestion, { query, isHighlighted }) => {
      const suggestionValue = this.props.getSuggestionValue(suggestion);
      const matches = match(suggestionValue, query);
      const parts = parse(suggestionValue, matches);

      if (this.state.isBlurred) {
         return null;
      }


      return (
         <MenuItem id={isHighlighted ? 'suggestionSelectedId' : undefined} selected={isHighlighted} component='div'>
            <div>
               {parts.map((part, index) => {
                  return part.highlight ? (
                     <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                     </span>
                  ) : (
                     <strong key={String(index)} style={{ fontWeight: 300 }}>
                        {part.text}
                     </strong>
                  );
               })}
            </div>
         </MenuItem>
      );
   };

   /**
    * Scroll the selected menu item into view.
    */
   scrollIntoView = () => {
      const element = document.getElementById('suggestionSelectedId');
      if (element) {
         let objDiv = document.getElementsByClassName(this.props.classes.suggestionsContainerOpen)[ 0 ];
         if (objDiv) {
            objDiv.scrollTop = element.offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
               (element.offsetHeight / 2) + element.offsetHeight + 12;
         }
      }
   };

   /**
    * Mark the menu blurred so it can be hidden.
    */
   onBlur = () => {
      this.setState({ isBlurred: true })
   };

   /**
    * Mark the menu not blurred so it can be shown.
    */
   onFocus = () => {
      this.setState({ isBlurred: false });
   };

   render() {
      const { classes, label, helperText, onFetchSuggestions, onClearSuggestions, getSuggestionValue, suggestions, highlightFirstSuggestion, ...props } = this.props;

      return (
         <Autosuggest
            theme={{
               container: classes.container,
               suggestionsContainerOpen: classes.suggestionsContainerOpen,
               suggestionsList: classes.suggestionsList,
               suggestion: classes.suggestion,
            }}
            renderInputComponent={renderInput}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onFetchSuggestions}
            onSuggestionsClearRequested={onClearSuggestions}
            renderSuggestionsContainer={renderSuggestionsContainer}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            highlightFirstSuggestion={highlightFirstSuggestion}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            inputProps={{
               classes,
               label,
               helperText,
               onBlur: this.onBlur,
               onFocus: this.onFocus,
               onKeyDown: this.handleKey,
               ...props,
            }}
         />
      );
   }
}

export default withStyles(styles)(FormattedTextAutoSuggest);

import {isEmpty} from '@aws-amplify/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import {makeStyles} from '@material-ui/core/styles';
import {find} from 'lodash';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import React, {useRef, useEffect, useState, useCallback, Fragment} from 'react';
import {Route, Switch, useParams, useRouteMatch, useHistory, useLocation} from 'react-router-dom';
import 'react-table/react-table.css';
import {
   DEFAULT_LOCATION, APP_BAR_HEIGHT, EDIT_ITEM_PATH, CATALOG_PATH, EQUIPMENT_LIST_PROPERTY_WIDTH
} from '../../../Constants';
import DisplayError from '../../../fhg/components/DisplayError';
import Grid from '../../../fhg/components/Grid';
import useLazyRequestForServer from '../../../Utils/useLazyRequestForServer';
import '../dashboard/EvalDashboard.scss';
import AssetPropertiesDrawer from '../detail/AssetPropertiesDrawer';
import EquipmentDetailMain from '../detail/EquipmentDetailMain';
import EquipmentList from './EquipmentList';
import EquipmentListHeader from './EquipmentListHeader';
import NewItemDialog from './NewItemDialog';

const useStyles = makeStyles(theme => ({
   list: {
      height: '100%',
      width: '100%',
   },
   progress: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      zIndex: 1001,
   },
   root: {
      height: `calc(100% - ${APP_BAR_HEIGHT + theme.spacing(2)}px) !important`,
      [theme.breakpoints.down('xs')]: {
         height: '100% !important',
      },
   },
   rootNoAppBar: {
      height: `100% !important`,
   },
   inner: {
      flex: '1 1',
      maxWidth: '100%',
      overflow: 'auto',
      backgroundColor: theme.palette.environment.light.level2.base,
      // backgroundColor: 'green',
      '@media print': {
         display: 'block',
      },
   },
}));

/**
 * The component to show the equipment list. Handled requesting information from server so it only needs to be requested
 * once.
 */
export default function EquipmentListMain() {
   const classes = useStyles();
   const location = useLocation();
   const history = useHistory();
   const {id, owner} = useParams();
   let catalogMatch = useRouteMatch({path: CATALOG_PATH, strict: false, sensitive: false});

   const tableRef = useRef();

   const [evaluation, setEvaluation] = useState();
   const [evalItem, setEvalItem] = useState({});

   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

   const [fetchCatalog, {error: errorCatalog, loading: loadingCatalog, data: dataCatalog}] = useLazyRequestForServer();
   const [fetchAsset, {error: errorAsset, loading: isEvalItemLoading, data: dataAsset}] = useLazyRequestForServer();

   const [doSetEvalItem, setDoSetEvalItem] = useState(false);
   const [doSetEvaluation, setDoSetEvaluation] = useState(false);

   const [isSaving, setIsSaving] = useState(false);
   const [savedTime, setSavedTime] = useState();
   const [isEstimateError, setIsEstimateError] = useState(false);

   const isNew = get(location, 'state.isNew');
   const isZoomed = get(location, 'state.isZoomed');

   /**
    * Track the notification events for mixpanel.
    */
   useEffect(() => {
      if (location.search === '?from=notification') {
         mixpanel.track('Notification Open');
         location.search = undefined;
         history.replace(location);
      }
   }, [location.search, history, location]);

   /**
    * If the uri owner/uuid changed, fetch the catalog.
    */
   useEffect(() => {
      if (owner) {
         const existingEvaluationUri = evaluation && evaluation.uri && evaluation.uri.replace &&
            evaluation.uri.replace('%7C', '');
         const newEvaluationUri = catalogMatch && catalogMatch.url && catalogMatch.url.replace('|', '');
         if (newEvaluationUri !== existingEvaluationUri) {
            fetchCatalog(catalogMatch.url);
            setEvalItem({});
         }
      }
      // eslint-disable-next-line
   }, [owner, evaluation, fetchCatalog]);
   // }, [owner, catalogMatch, evaluation, fetchCatalog]);

   /**
    * When the catalog data is returned from the server, set the evaluation and the evalItem.
    */
   useEffect(() => {
      if (!isEmpty(dataCatalog)) {
         const evaluationData = Array.isArray(dataCatalog) ? dataCatalog[0] : dataCatalog;
         setEvaluation(evaluationData);
         if (doSetEvalItem) {
            if (!!evaluationData) {
               const evalItemFound = find(evaluationData.items, {item_id: evalItem.item_id});
               if (evalItemFound) {
                  setEvalItem(evalItemFound);
               }
            }
            setDoSetEvalItem(false);
         }
      }
   }, [dataCatalog, doSetEvalItem, evalItem.item_id]);

   /**
    * If the asset ID changed in the URL, find the asset in the catalog. If not found, fetch the asset from the server.
    */
   useEffect(() => {
      if (id) {
         let foundAsset = false;
         if (evaluation) {
            const evalItem = evaluation.items.find(item => get(item, 'item.item_id') === id);
            if (evalItem) {
               foundAsset = true;
               setEvalItem(evalItem);
            }
         }
         if (!foundAsset) {
            setDoSetEvaluation(true);

            fetchAsset(`/items/${id}`);
         }
      }
   }, [id, fetchAsset, evaluation]);

   /**
    * Load the evaluation from the server.
    */
   const loadEvaluation = useCallback((uri, doSetEvalItem = false) => {
      setDoSetEvalItem(doSetEvalItem);
      return fetchCatalog(uri);
   }, [fetchCatalog]);

   /**
    * When the asset is returned, set the evalItem. If the evaluation is needed, fetch the evaluation.
    */
   useEffect(() => {
      if (!isEmpty(dataAsset)) {
         const asset = Array.isArray(dataAsset) ? dataAsset[0] : dataAsset;
         setEvalItem(asset);

         if (doSetEvaluation) {
            const evaluationUri = get(asset, 'open_evaluations[0].uri');
            if (evaluationUri) {
               loadEvaluation(evaluationUri, true);
               setDoSetEvaluation(false);
            } else {
               console.log(`The evaluation for the item couldn't be found. No open evaluations.`);
               if (!evaluation) {
                  console.log(`The evaluation for the item couldn't be found. No open evaluations.`);
                  history.push(DEFAULT_LOCATION);
               }
            }
         }
      }
   }, [dataAsset, doSetEvaluation, evaluation, history, loadEvaluation]);

   /**
    * When the data has changed for the item, force a refresh.
    *
    * @param item The item to refresh.
    * @param isRefreshList Is the item added or the profile image has changed, etc..
    */
   const onRefresh = useCallback((item, isRefreshList) => {
      if (item && item.item_id && id === item.item_id) {
         setDoSetEvaluation(isRefreshList);
         return fetchAsset(`/items/${id}`);
      } else if (isRefreshList) {
         const uri = evaluation ? evaluation.uri : get(item, 'open_evaluations[0].uri');
         return fetchCatalog(uri);
      } else {
         return Promise.resolve();
      }
   }, [id, evaluation, fetchAsset, fetchCatalog]);

   /**
    * Indicate the saving and saved time for estimates.
    *
    * @param isSaving Indicates if the estimates are being saved.
    * @param savedTime The time of the save estimates.
    */
   const handleSaving = (isSaving, savedTime) => {
      setIsSaving(isSaving);

      if (isSaving) {
         setTimeout(() => {
            if (isSaving) {
               setIsSaving(false);
            }
         }, 6000);
      }

      setSavedTime(savedTime);
   };

   /**
    * When the user closes the sidebar, mark the sidebar closed.
    */
   const onDrawerClose = () => {
      setIsDrawerOpen(false);
   };

   /**
    * When the user opens the sidebar, mark the sidebar open.
    */
   const onDrawerOpen = () => {
      setIsDrawerOpen(true);
   };

   /**
    * When the detail is being refreshed, update the isSaving and isError.
    *
    * @param isSaving Indicates if the estimates are being saved.
    * @param isError Indicates if an error has occurred.
    */
   const onDetailRefresh = (isSaving, isError) => {
      setIsSaving(isSaving);
      setSavedTime(new Date());
      setIsEstimateError(isError);

      if (!isSaving && !isError) {
         onRefresh(undefined, true);
      }
   };

   return (
      <Fragment>
         {(errorAsset || errorCatalog) && <DisplayError error={errorAsset || errorCatalog}/>}
         <Hidden xsDown>
            <EquipmentListHeader evaluation={evaluation} allowExport={!isNew}
                                 saving={isSaving} savedTime={savedTime}
                                 onDrawerOpen={onDrawerOpen} isDrawerOpen={isDrawerOpen}
                                 smBreakpoint={false} tableRef={tableRef}
            />
         </Hidden>
         <Grid container className={isZoomed ? classes.rootNoAppBar : classes.root} name={'EquipmentListMain-root'}
               direction={'column'}
               wrap={'nowrap'}
               spacing={0}>
            {isEvalItemLoading && <CircularProgress className={classes.progress}/>}

            <Grid container className={classes.inner} name={'EquipmentListMain-inner'} direction={'row'}
                  wrap={'nowrap'} spacing={0}>

               <Switch>
                  <Route exact path={CATALOG_PATH} render={routeOptions => {
                     if (get(routeOptions, 'location.state.isNew') !== true) {
                        return (
                           //@TODO Remove innerRef when all EquipmentList HOCs support forwardRef.
                           <EquipmentList tableRef={tableRef} evaluation={evaluation}
                                          onRefresh={loadEvaluation}
                                          isEstimateSaving={isSaving}
                                          isEstimateError={isEstimateError}
                                          isLoading={loadingCatalog} onSaving={handleSaving}
                           />
                        );
                     } else {
                        return (
                           <NewItemDialog key='NewItemDialog-New' evaluation={evaluation} onUpdate={onRefresh}/>
                        );
                     }
                  }}/>
                  <Route exact path={EDIT_ITEM_PATH} render={routeOptions => {
                     if (get(routeOptions, 'location.state.isEdit') === true) {
                        return (
                           <NewItemDialog evalItem={evalItem} evaluation={evaluation} onUpdate={onRefresh}/>
                        );
                     } else {
                        return <>
                           <EquipmentDetailMain evalItem={evalItem} onRefresh={onRefresh}
                                                evaluation={evaluation}
                                                isLoading={isEvalItemLoading}
                                                isDrawerOpen={isDrawerOpen}
                                                onDrawerClose={onDrawerClose} onDrawerOpen={onDrawerOpen}/>
                           <AssetPropertiesDrawer evalItem={evalItem} evaluation={evaluation} breakpoint={'sm'}
                                                  width={EQUIPMENT_LIST_PROPERTY_WIDTH} showEquipmentTitle={false}
                                                  onSaving={onDetailRefresh} isOpen={isDrawerOpen}
                                                  onClose={onDrawerClose}/>
                        </>;
                     }
                  }}/>
               </Switch>
            </Grid>
         </Grid>
      </Fragment>
   );
}

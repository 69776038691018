import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import Typography from '../fhg/components/Typography';
import WithPrivilege from '../fhg/security/WithPrivilege';
import {detectIE} from '../fhg/utils/Utils';
import NewOrganizationDialog from './NewOrganizationDialog';
import {sortBy, memoize, isFunction} from 'lodash';

export const DRAWER_WIDTH = 200;
export const PROGRESS_WIDTH = 40;
const ALL_ORGANIZATIONS = {organization_id: 0};

const styles = theme => ({
   root: {
      width: DRAWER_WIDTH,
      height: '100%',
      flex: '0 0 auto',
      borderRight: `1px solid ${theme.palette.environment.light.level1.accent}`,
   },
   drawerStyle: {
      padding: theme.spacing(2),
      overflow: 'hidden',
      height: '100%',
   },
});

/**
 * The component to display all the Groups (Organizations).
 */
class GroupsPanel extends Component {
   static propTypes = {
      classes: PropTypes.object.isRequired,
      groups: PropTypes.array,
      onSelect: PropTypes.func,
   };

   /**
    * Memoize the function so that sort returns the cache if groups hasn't changed. If the groups have changed the
    * groups are sorted and returned.
    *
    * @type {memoized}
    */
   sort = memoize(
      groups => sortBy(groups, ['name'])
   );

   state = {
      isShowNewOrganization: false,
      selectedGroup: undefined,
   };

   componentDidUpdate(prevProps, prevState, snapshot) {
      const {groups = [], selectFirst} = this.props;
      const elements = document.getElementsByName('selected');
      if (elements && elements[0]) {
         if (!detectIE() && isFunction(elements[0].scrollIntoViewIfNeeded)) {
            elements[0].scrollIntoViewIfNeeded();
         } else {
            this.scrollIntoView(elements);
         }
      }
      if (selectFirst && groups.length > 0) {
         const sortedGroups = this.sort(groups);
         this.handleSelectGroup(sortedGroups[0])();
      }
   }

   /**
    * Scroll to the selected item.
    */
   scrollIntoView = (selectedElements) => {
      let viewElement = document.getElementsByName('list')[0];
      if (viewElement && selectedElements.length > 0) {

         // If the top of the selected element < the top of the view || the bottom of the selected > bottom of the view
         // scrolling is needed.
         if (selectedElements[0].offsetTop < viewElement.scrollTop ||
            (selectedElements[0].offsetTop + selectedElements[0].offsetHeight) >
            (viewElement.scrollTop + viewElement.offsetHeight)) {

            // If the top of the selected is < top of the view, move view top to selected top.
            if (selectedElements[0].offsetTop < viewElement.scrollTop) {
               viewElement.scrollTop = selectedElements[0].offsetTop;
            } else {
               // If the bottom of the selected is > bottom of the view, move view top to the selected bottom - view
               // height.
               viewElement.scrollTop =
                  (selectedElements[0].offsetTop + selectedElements[0].offsetHeight) - viewElement.offsetHeight;
            }
         }
      }
   };

   /**
    * Handle showing the new organization dialog.
    * @param event The event triggering the show.
    */
   handleNewOrganization = (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.setState({isShowNewOrganization: true})
   };

   /**
    * Handle closing the new organization dialog.
    */
   handleCloseNewOrganization = () => {
      this.setState({isShowNewOrganization: false});
   };

   /**
    * Handle the selecting a group.
    *
    * @param selectedGroup
    * @return {Function}
    */
   handleSelectGroup = (selectedGroup) => () => {
      const {onSelect} = this.props;

      this.setState({selectedGroup}, () => {
         onSelect && onSelect(selectedGroup);
      });
   };

   /**
    * Handle submitting the organization.
    * @param org
    */
   handleSubmitOrganization = org => {
      const {onAddGroup} = this.props;

      onAddGroup(org);
      this.handleSelectGroup(org)();
   };

   render() {
      const {classes, groups = []} = this.props;
      const {isShowNewOrganization, selectedGroup} = this.state;
      const sortedGroups = this.sort(groups);

      return (
         <WithPrivilege requiresPlatformAdmin>
            <Grid item className={classes.root}>
               <Grid container direction={'column'} className={classes.drawerStyle} wrap={'nowrap'}>
                  <Grid item direction={'column'} wrap={'nowrap'}>
                     {isShowNewOrganization && (
                        <NewOrganizationDialog open={isShowNewOrganization} onClose={this.handleCloseNewOrganization}
                                               onSubmit={this.handleSubmitOrganization}/>
                     )}
                     <Typography variant='inherit' className={'nonessential-title-caps'}
                                 id={'admin.organizations.title'}>
                        Organizations
                     </Typography>
                     <Button className={'secondary-minimal-button-small'} style={{marginLeft: 8}}
                             onClick={this.handleNewOrganization}>New Organization</Button>
                  </Grid>
                  <Grid item name='list' style={{overflow: 'auto'}}>
                     <List>
                        <ListItem button onClick={this.handleSelectGroup(ALL_ORGANIZATIONS)}
                                  selected={selectedGroup && (selectedGroup.organization_id === 0)}>
                           <Typography variant={'inherit'} className={'normal-default-text'}
                                       id={'admin.allOrganizations.label'}>
                              All Organizations
                           </Typography>
                        </ListItem>
                        {sortedGroups.map((group) => (
                           <ListItem name={selectedGroup && (selectedGroup.organization_id === group.organization_id) ?
                              'selected' : undefined} button
                                     onClick={this.handleSelectGroup(group)}
                                     selected={selectedGroup &&
                                     (selectedGroup.organization_id === group.organization_id)}>
                              <Typography variant={'inherit'}
                                          className={'normal-default-text'}>{group.name}</Typography>
                           </ListItem>
                        ))}
                     </List>
                  </Grid>
               </Grid>
            </Grid>
         </WithPrivilege>
      );
   }
}

export default injectIntl(withStyles(styles)(GroupsPanel));

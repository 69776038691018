import {Hidden} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {PureComponent, Fragment} from 'react';
import ReactTableOriginal from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Typography from '../../../fhg/components/Typography';
import Feature from '../../../fhg/security/Feature';
import NotFeature from '../../../fhg/security/NotFeature';
import {SUB_PACKAGES} from '../../../Utils/ServerUtil';
import SubPackageFilterMenu from '../components/SubPackageFilterMenu';
import SubpackagesPopover from '../components/SubpackagesPopover';
import {PackageTotal} from './EquipmentList';

const MAP_SORT_TO_LABEL = {
   created: 'equipmentListSort.created.label',
   updated: 'equipmentListSort.updated.label',
   location: 'equipmentListSort.location.label',
   make: 'equipmentListSort.make.label',
   model: 'equipmentListSort.model.label',
   serial: 'equipmentListSort.serial.label',
   vin: 'equipmentListSort.vin.label',
   year: 'equipmentListSort.year.label',
   inventoryId: 'equipmentListSort.inventoryId.label',
   stickerId: 'equipmentListSort.stickerId.label',
   miles: 'equipmentListSort.miles.label',
   hours: 'equipmentListSort.hours.label',
};

// withFixedColumns doesn't handle ref and/or innerRef correctly. Use tableRef as the ref and innerRef.
function Table({tableRef, ...props}) {
   return <ReactTableOriginal ref={tableRef} innerRef={tableRef} {...props}/>;
}

Table.propTypes = {
   ...ReactTableOriginal.propTypes,
};
Table.defaultProps = {
   ...ReactTableOriginal.defaultProps,
};
const ReactTable = withFixedColumns(Table);

const styles = theme => ({
   equipmentHeaderStyle: {
      maxHeight: '100%',
      border: 'none',
      paddingLeft: 2,
      overflow: 'hidden',
      '@media print': {
         display: 'table',
      },
      '& .rt-tbody': {
         height: 0,
      },
      '& .rt-th div': {
      },
      '& .rt-tr': {
         marginLeft: '4px !important',
         marginTop: 1,
         marginBottom: 1,
         flex: '0 0 auto',
      },
      '& .rt-tbody .rt-tr': {
         // Needed to make the :after 'absolute' position work for the selection borders.
         position: 'relative',
      },
      '& .rt-tbody .rt-tr-group': {
         borderBottom: `1px solid ${theme.palette.environment.light.level0.accent} !important;`,
      },
      '& .rt-resizable-header-content': {
         height: '100%',
         paddingBottom: theme.spacing(1),
      },
   },
   titleStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
   },
   titleFrame: {
      height: 'auto !important',
      marginLeft: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
         marginLeft: theme.spacing(0.5),
      }
   },
   buttonFrame: {
      height: 'auto !important',
      marginLeft: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
         marginLeft: 0,
      }
   },
   paper: {
      padding: theme.spacing(1),
      // margin: theme.spacing(-1, 0, 0, -1),
   },
   defaultTextStyle: {
      color: theme.palette.text.secondary, //'rgba(66,51,19,0.8)',
   },
   menuText: {
      color: theme.palette.text.secondary,
      display: 'inline-block',
   },
});

class AssetTableHeader extends PureComponent {
   static propTypes = {
      classes: PropTypes.any,
      saving: PropTypes.any,
      evaluation: PropTypes.object,
      total: PropTypes.number,
      estimateTotals: PropTypes.number,
      onAdd: PropTypes.func.isRequired,
      onShowNotes: PropTypes.func.isRequired,
      onShowSubpackages: PropTypes.func.isRequired,
      onFilterSubpackages: PropTypes.func.isRequired,
      onSort: PropTypes.func.isRequired,
      sortBy: PropTypes.string,
      isWideView: PropTypes.bool,
      isNarrowView: PropTypes.bool,
   };

   static defaultProps = {
      sortBy: 'created',
   };

   constructor(props, context) {
      super(props, context);

      this.state = {
         anchorEl: undefined,
         subpackageTotals: undefined,
         sort: get(props, 'sortBy[0].id'),
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.sortBy !== this.props.sortBy) {
         this.setState({sort: get(this.props, 'sortBy[0].id')});
      }
   }

   /**
    * Show the subpackage totals for the catalog.
    *
    * @param event The event that triggers the show collaborators.
    */
   handleShowSubpackages = event => {
      const subpackageTotals = this.props.onShowSubpackages();
      this.setState({subpackageTotals, anchorEl: event.currentTarget});
   };

   /**
    * Close the subpackage totals popover.
    */
   handlePopoverClose = () => {
      this.setState({subpackageTotals: undefined, anchorEl: undefined});
   };

   /**
    * Handle changes to the sort.
    * @param event The change event.
    */
   handleChange = ({target}) => {
      setTimeout(() => {
         this.setState({[target.name]: target.value}, () => {
            this.props.onSort && this.props.onSort(target.value);
         });
      }, 1);
   };

   getColumns = (total, estimateTotals) => {
      const {
         classes,
         evaluation,
         width,
         onAdd,
         onShowNotes,
         onFilterSubpackages,
         isWideView,
         isNarrowView
      } = this.props;

      const list = (evaluation && evaluation.items) || [];
      const subpackages = get(evaluation, 'evaluation.subpackages') || [];

      let columns = [
         {
            id: 'image',
            minWidth: 500,
            maxWidth: 600,
            Header: (
               <Grid container direction='row' alignItems={'center'} wrap={'nowrap'}
                     justify={isNarrowView ? 'space-between' : undefined} style={{minWidth: 'fit-content'}}>
                  <Grid item>
                     <Button variant={'text'} className={'secondary-button'}
                             style={{height: 33}} onClick={onAdd} disabled={!evaluation}>
                        <Typography color='inherit' variant='button' id={'equipmentList.newItem.label'}/>
                     </Button>
                  </Grid>
                  <Grid item className={classes.titleFrame}>
                     <Typography className={`${classes.titleStyle} default-text-small-bold`}
                                 id={'equipmentList.headerText.label'}
                                 values={{count: list.length}}
                     />
                  </Grid>
                  <Hidden smUp>
                     <Grid container item alignItems={'center'} justify={'center'}
                           style={{marginLeft: 4, height: '100%', width: 'unset'}}>
                        <Select
                           name={'sort'}
                           value={this.state.sort || ''}
                           classes={{
                              select: classes.select,
                           }}
                           style={{height: 'auto'}}
                           onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                           }}
                           onChange={this.handleChange}
                           input={<Input name='status' disableUnderline={true}
                                         style={{marginRight: 8, fontSize: 18}}/>}
                           displayEmpty
                           renderValue={value => (
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={MAP_SORT_TO_LABEL[value] || 'equipmentListSort.placeholder.label'}/>)}
                        >
                           <Typography className={'nonessential-title-caps'} color='inherit' variant='body1'
                                       id={'equipmentListSort.title.label'}
                                       style={{marginLeft: 8, marginTop: 8, width: 175}}/>
                           <MenuItem value={'updated'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.updated.label'}/>
                           </MenuItem>
                           <MenuItem value={'created'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.created.label'}/>
                           </MenuItem>
                           <MenuItem value={'location'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.location.label'}/>
                           </MenuItem>
                           <MenuItem value={'serial'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.serial.label'}/>
                           </MenuItem>
                           <MenuItem value={'vin'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.vin.label'}/>
                           </MenuItem>
                           <MenuItem value={'inventoryId'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.inventoryId.label'}/>
                           </MenuItem>
                           <MenuItem value={'stickerId'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.stickerId.label'}/>
                           </MenuItem>
                           <MenuItem value={'year'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.year.label'}/>
                           </MenuItem>
                           <MenuItem value={'make'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.make.label'}/>
                           </MenuItem>
                           <MenuItem value={'model'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.model.label'}/>
                           </MenuItem>
                           <MenuItem value={'hours'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.hours.label'}/>
                           </MenuItem>
                           <MenuItem value={'miles'}>
                              <Typography className={classes.menuText} color='inherit' variant='body1'
                                          id={'equipmentListSort.miles.label'}/>
                           </MenuItem>
                        </Select>
                     </Grid>
                  </Hidden>
                  <Grid item className={classes.buttonFrame}>
                     <Button className={'secondary-minimal-button-small no-print'}
                             onClick={onShowNotes} disabled={!evaluation} style={{padding: 2}}>
                        <Typography
                           color='inherit'
                           variant='button'
                           style={{whiteSpace: 'normal'}}
                           id={isWideView ? 'equipmentList.notes.label' : 'equipmentList.details.label'}
                        />
                     </Button>
                  </Grid>
                  <Feature feature={SUB_PACKAGES}>
                     <Grid item className={classes.buttonFrame} wrap={'nowrap'} style={{minWidth: 'fit-content'}}>
                        <SubPackageFilterMenu subpackages={subpackages} onFilter={onFilterSubpackages}/>
                     </Grid>
                  </Feature>
               </Grid>
            ),
         },
      ];

      if (isWideView) {
         const value= undefined;
         const handleChange = () => {}
         columns.push({
            id: 'expanding column',
               minWidth: 1,
         });
         columns.push({
            id: 'estimates',
            minWidth: 200,
            Header: (
               <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable estimates"
               >
                  {estimateTotals.map(estimateTotal => (
                     <div>
                        {(estimateTotal?.estimateType.active) && (
                           <Grid item container direction={'column'} wrap={'nowrap'} alignItems='flex-end'
                                 justify={'flex-end'}>
                              <Grid item style={{minHeight: 28}}>
                                 {(estimateTotal?.estimateType.enable_workflow_columns) && (
                                    <Button className={'secondary-minimal-button-small no-print'} size='small'
                                            disabled={true}>
                                       <PackageTotal total={estimateTotal.total}
                                                     totalLabel={'Your ' + estimateTotal.estimateType.display_name}/>
                                    </Button>
                                 )}
                              </Grid>
                              <Grid item wrap={'nowrap'}>
                                 <Feature feature={SUB_PACKAGES}>
                                    <Button className={'secondary-minimal-button-small no-print'} size='small'
                                            onClick={this.handleShowSubpackages}>
                                       <PackageTotal classes={classes} total={estimateTotal.consensus}
                                                     totalLabel={estimateTotal.estimateType.display_name + ' Total'}/>
                                    </Button>
                                 </Feature>
                                 <NotFeature feature={SUB_PACKAGES}>
                                    <PackageTotal classes={classes} total={estimateTotal.consensus}
                                                  totalLabel={estimateTotal.estimateType.display_name + ' Total'}/>
                                 </NotFeature>
                              </Grid>
                           </Grid>
                        )}
                     </div>
                  ))}
               </Tabs>
            ),
         });
         // columns.push({
         //       id: 'space for column',
         //       width: 16,
         //    }
         // );
      }
      return columns;
   };

   render() {
      const {classes, total, estimateTotals} = this.props;
      const {anchorEl, subpackageTotals} = this.state;
      const open = Boolean(anchorEl);

      return (
         <Fragment>
            {open && (
               <SubpackagesPopover
                  open={open}
                  classes={{
                     paper: this.props.classes.paper,
                  }}
                  anchorEl={anchorEl}
                  onClose={this.handlePopoverClose}
                  subpackageTotals={subpackageTotals}
               />
            )}
            <AppBar position='static' color='inherit' elevation={2}>
               <ReactTable className={classNames(classes.equipmentHeaderStyle, 'equipment-list')}
                           defaultPageSize={10000} minRows={0}
                           sortable={false}
                           resizable={false}
                           multiSort={false}
                           noDataText={''}
                           getNoDataProps={() => ({style: {height: 0, width: 0, padding: 0}})}
                           data={[]}
                           showPagination={false}
                           columns={this.getColumns(total, estimateTotals)}
               />
            </AppBar>
         </Fragment>
      );
   }
}

export default withStyles(styles)(AssetTableHeader);

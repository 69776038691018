import {Button} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import {Info} from '@material-ui/icons';
import Search from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import {parse} from 'query-string';
import React, {useEffect, useRef} from 'react';
import useKeyDown from '../useKeyDown';
import useEditData from './edit/useEditData';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from './Typography';
import {useLocation} from 'react-router-dom';

const LightTooltip = withStyles((theme) => ({
   tooltip: {
      composes: 'subtle-text-small',
      backgroundColor: theme.palette.environment.light.level4.base, //theme.palette.common.white,
      boxShadow: theme.shadows[1],
      fontSize: 11,
   },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
   searchStyle: {
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(2),
   },
   secondaryColor: {
      color: theme.palette.button.standard.secondary,
   },
   themeUnderline: {
      '&:hover:not(:disabled):before,&:before': {
         borderColor: `${theme.palette.divider} !important`
      },
      '&:after': {
         borderColor: `${theme.palette.environment.dark.divider} !important`
      }
   },
   radioDense: {
      padding: theme.spacing(0.5),
      color: theme.palette.content.dark, //'rgba(104,91,66,0.8)',
   },
   radioColor: {
      color: `${theme.palette.button.standard.primary} !important`,
   },
   buttonStyle: {
      marginTop: '18px !important',
   }
}), {name: 'SearchWithButtonStyles'});

SearchWithButton.propTypes = {
   search: PropTypes.string,
   placeholder: PropTypes.string,
   onChange: PropTypes.func,
};

/**
 * Text component for entering a search string.
 *
 * @param search The search string to search for the initial search
 * @param placeholder The placeholder text for the search input.
 * @param onChange Callback when the search string is submitted.
 * @return {JSX.Element}
 * @constructor
 */
export default function SearchWithButton({search:searchProp, onChange, placeholder}) {
   const classes = useStyles();
   const location = useLocation();

   const searchParams = useRef(parse(location.search, {parseBooleans: false, parseNumbers: false})).current;
   const search = searchProp || !!searchParams.search ? searchParams.search : '';

   const [editValues, handleChange, {setDefaultValues, defaultValues, resetValues}] = useEditData({search, searchType: searchParams.type || 'Catalog'});

   /**
    * Update the default search string when the search property changes.
    */
   useEffect(() => {
      setDefaultValues({search, searchType: searchParams.type || 'Catalog'});
   }, [search, searchParams, setDefaultValues]);

   /**
    * Submit the current search text. If the search text hasn't changes, the search is ignored.
    */
   const handleSubmit = () => {
      onChange && onChange(editValues.search || defaultValues.search, editValues.searchType || defaultValues.searchType);
   };

   //Handle escape and enter keys.
   const [handleKeyDown] = useKeyDown(resetValues, handleSubmit, true);

   return (
      <Grid container alignItems={'center'} style={{marginTop: 32}}>
         <Grid item>
            <TextField
               name={'search'}
               className={classes.searchStyle}
               onChange={handleChange}
               autoFocus
               placeholder={placeholder || 'Search'}
               defaultValue={defaultValues.search}
               value={editValues.search}
               onKeyDown={handleKeyDown}
               InputProps={{
                  'aria-label': 'Search',
                  classes: {
                     input: classes.textFieldInput,
                     underline: classes.themeUnderline,
                  },
               }}
            />
         </Grid>
         <RadioGroup
            aria-label='Search Type'
            name='searchType'
            defaultValue={defaultValues.searchType}
            value={editValues.searchType}
            onChange={handleChange}
         >
            <FormControlLabel
               value={'Catalog'}
               control={<Radio className={classes.radioDense}
                               classes={{checked: classes.radioColor}} size={'small'}/>}
               label={(
                  <Grid container direction={'row'} alignItems={'center'} spacing={1}>
                     <Grid item>
                        <Typography color='textSecondary' variant={'body1'}
                                    id={'evalDashboard.catalog.label'}/>
                     </Grid>
                     <Grid item>
                        <LightTooltip
                           disableFocusListener
                           title={'Use any catalog sort field for searching (i.e. asset owner, primary contact, catalog status, etc.)'}>
                           <Info fontSize={'small'} className={classes.radioColor}/>
                        </LightTooltip>
                     </Grid>
                  </Grid>
               )}
            />
            <FormControlLabel
               value={'Asset'}
               control={<Radio className={classes.radioDense}
                               classes={{checked: classes.radioColor}} size={'small'}/>}
               label={(
                  <Grid container direction={'row'} alignItems={'center'} spacing={1}>
                     <Grid item>
                        <Typography color='textSecondary' variant={'body1'}
                                    id={'evalDashboard.asset.label'}/>
                     </Grid>
                     <Grid item>
                        <LightTooltip
                           disableFocusListener
                           title={'Use any asset sort field for searching (i.e. make, model, asset type, miles, etc.)'}>
                           <Info fontSize={'small'} className={classes.radioColor}/>
                        </LightTooltip>
                     </Grid>
                  </Grid>
               )}
            />
         </RadioGroup>
         <Grid item>
            <Button
               className={`no-print subtle-button ${classes.buttonStyle}`}
               onClick={handleSubmit}
               disabled={!editValues.search && !defaultValues.search}
            >
               <Search className={classes.secondaryColor}/>
               <Typography color='inherit' variant='button' id={'evalDashboard.search.label'}/>
            </Button>
         </Grid>
      </Grid>
   );
}

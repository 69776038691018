import {withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import {Auth} from 'aws-amplify';
import classNames from 'classnames';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {LOGIN_FAILED} from '../../Constants';
import {isLocalhost} from '../../fhg/utils/Utils';
import {requestForServer} from '../../Utils/ServerUtil';
import Typography from '../../fhg/components/Typography';

const styles = theme => ({
   mainGrid: {
      height: 'calc(100% - 80px)',
      width: '100%',
      minHeight: 580,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.brand.gray,//'#5D788F',
      overflow: 'hidden',
   },
   content: {
      width: 436,
      margin: 'auto',
      backgroundColor: theme.palette.environment.light.level1.base,
      padding: theme.spacing(4, 6),
   },
   textFieldRoot: {
      padding: 0,
      'label + &': {
         marginTop: -4,
      },
   },
   textFieldInput: {
      backgroundColor: `${theme.palette.common.white} !important`,
      border: `1px solid ${theme.palette.divider} !important`,
      borderRadius: 4,
      padding: '11px !important',
      marginTop: `${theme.spacing(1)}px !important`,
      width: `calc(100% - ${theme.spacing(3)}px)`,
      '&:focus': {
         border: `1px solid ${theme.palette.content.default} !important`,
      },
   },
   textFieldInputError: {
      border: `1px solid ${theme.palette.error.main} !important`,
      color: `${theme.palette.error.main} !important`,
      '&:focus': {
         border: `1px solid ${theme.palette.error.main} !important`,
      },
   },
   button: {
      color: theme.palette.common.white,
      textTransform: 'none',
      borderRadius: 4,
      backgroundColor: theme.palette.button.standard.primary,
      marginTop: `${theme.spacing(3)}px !important`,
      padding: '14px 0',
      lineHeight: '20px !important',
      height: '48px !important',
      width: 'unset',

      '&:hover': {
         backgroundColor: theme.palette.button.standard.primary,
      },
   },
   error: {
      width: '100%',
      marginTop: `&{theme.spacing(4)}px !important`,
   },
   link: {
      color: theme.palette.common.white,
      width: '100%',
      textDecoration: 'underline',
      fontSize: 16,
      marginTop: 14,
      '&:hover': {
         backgroundColor: theme.palette.brand.gray,
      },
   },
   success: {
      width: '100%',
      marginTop: '32px !important',
      color: theme.palette.button.minimal.success,
   },
   formControl: {
      position: 'inherit',
      transform: 'none',
   },
   spinnerMargin: {
      marginLeft: theme.spacing(0.5),
   },
   whiteLabel: {
      color: theme.palette.common.white,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
      '&:hover': {
         backgroundColor: theme.palette.brand.gray,
      },
   },
   topWhiteLabel: {
      composes: '$whiteLabel',
      marginTop: theme.spacing(5),
   },
   bottomWhiteLabel: {
      composes: '$whiteLabel',
      marginTop: theme.spacing(4),
   },
   linkStyle: {
      color: 'white',
      marginTop: theme.spacing(1),
      fontSize: 16,
      '&:hover': {
         backgroundColor: theme.palette.brand.gray,
      },
   }
});

/**
 * The component for the Standard Authorization workflow to sign in with the email and password.
 */
class SignInPanel extends Component {
   static propTypes = {
      classes: PropTypes.any,
      showSuccess: PropTypes.bool,
      error: PropTypes.any,
      onChange: PropTypes.func,
      onResetPassword: PropTypes.func,
      onEmailSignIn: PropTypes.func
   };

   constructor(props, context) {
      super(props, context);

      if (process.env.REACT_APP_ENVIRONMENT_NAME === "purplewave.net") {
         window.Intercom('update', {
            hide_default_launcher: true
         });
      }

      this.state = {
         email: '',
         password: '',
         error: undefined,
         purplewave: (process.env.REACT_APP_ENVIRONMENT_NAME === "purplewave.net")
      };
   }

   handleChange = ({target}) => {
      this.setState({[target.name]: target.value});
   };

   handleSubmit = event => {
      event.preventDefault();

      console.log('handleSubmit for login');

      this.setState({isSaving: true});
      const email = get(this.state, 'email', '').toLowerCase();
      Auth.signIn(email, this.state.password).then(() => {
         window.location.reload();
      }).catch((error) => {
         console.log(error);
         this.setState({error: 'login.error'});
         if (!isLocalhost) {
            console.log('Failed Login');
            mixpanel.track('Failed Login');
            requestForServer(LOGIN_FAILED, 'post', {email: email, platform: 'Web'});
         }
      }).finally(() => {
         this.setState({isSaving: false});
      });
   };

   render() {
      const {classes, showSuccess, onResetPassword, onEmailSignIn} = this.props;
      const {error, isSaving, purplewave} = this.state;

      return (
         <div className={classes.mainGrid}>
            <form onSubmit={this.handleSubmit}>
               <Grid container style={{overflow: 'auto'}} direction={'column'} wrap={'nowrap'} alignItems={'center'}>
                  <Grid container direction={'column'} className={classes.content}>
                     <Typography variant={'h4'} className={'level-2-heading'} id={'login.title'}/>
                     {(showSuccess && !error) && (
                        <Typography className={classes.success} variant={'body1'} id={'login.success'}/>
                     )}
                     {error && (
                        <Typography className={classes.error} color={'error'} variant={'body1'} id={error}>
                           Password or username did not match
                        </Typography>
                     )}
                     <TextField
                        name='email'
                        label={<Typography color='textSecondary' variant='body2' id={'login.email.label'}/>}
                        onChange={this.handleChange}
                        value={this.state.email}
                        autoFocus
                        margin='normal'
                        InputProps={{
                           'aria-label': 'Email',
                           disableUnderline: true,
                           classes: {
                              root: classes.textFieldRoot,
                              input: classNames({[classes.textFieldInputError]: error},
                                 classes.textFieldInput,
                                 'additional'),
                           },
                        }}
                        InputLabelProps={{
                           shrink: true,
                           focused: false,
                           classes: {
                              formControl: classes.formControl,
                           }
                        }}
                     />
                     <TextField
                        name='password'
                        label={<Typography color='textSecondary' variant='body2'
                                           id={'login.password.label'}>Password</Typography>}
                        onChange={this.handleChange}
                        type='password'
                        autoComplete='current-password'
                        value={this.state.password}
                        margin='normal'
                        InputProps={{
                           'aria-label': 'Password',
                           disableUnderline: true,
                           classes: {
                              root: classes.textFieldRoot,
                              input: classNames({[classes.textFieldInputError]: error},
                                 classes.textFieldInput,
                                 'additional'),
                           },
                        }}
                        InputLabelProps={{
                           shrink: true,
                           focused: false,
                           classes: {
                              formControl: classes.formControl,
                           }
                        }}
                     />
                     <Button variant={'text'} disabled={isSaving || !this.state.email || !this.state.password}
                             color='secondary'
                             className={classNames(classes.button, 'button',
                                {disabled: isSaving || !this.state.email || !this.state.password})} type='submit'>
                        <Typography color='inherit' id={'login.signIn.button'}/>
                        {isSaving &&
                        <CircularProgress className={classes.spinnerMargin} size={15} thickness={2.5}/>}
                     </Button>
                  </Grid>
                  <Grid item container direction={'column'} wrap={'nowrap'}>
                     <Typography className={classes.topWhiteLabel} id={'login.forgot.label'}/>
                     <Link className={classes.linkStyle} component='button' variant="'body2" underline='always'
                           onClick={onResetPassword}>
                        <Typography color='inherit' id={'login.reset.link'}/>
                     </Link>
                     <Typography className={classes.bottomWhiteLabel} id={'login.codeSignIn.label'}/>
                     <Link className={classes.linkStyle} component='button' variant="'body2" underline='always'
                           onClick={onEmailSignIn}>
                        <Typography color='inherit' id={'login.codeSignIn.link'}/>
                     </Link>
                     {purplewave && (
                         <p className={classes.bottomWhiteLabel}>
                            <Typography color='inherit' id={'login.supportInstructions'}/>
                         </p>
                     )}
                  </Grid>
               </Grid>
            </form>
         </div>
      );
   }
}

export default withStyles(styles)(withRouter(SignInPanel));
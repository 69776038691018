import Amplify, { Storage } from 'aws-amplify';
import { ConfirmSignIn, ConfirmSignUp, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'
import EvalMain from './components/eval/EvalMain';
import MySignIn from './components/security/MySignIn';
import ResetPassword from './components/security/ResetPassword';
import {DEFAULT_LOCATION} from './Constants';
import {isLocalhost} from './fhg/utils/Utils';
import {setUserData, isFilesOnly, getUserData} from './Utils/ServerUtil';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';

console.log("USER POOL: " + process.env.REACT_APP_USER_POOL);
console.log("IDENTITY POOL: " + process.env.REACT_APP_IDENTITY_POOL);
console.log("REGION: " + process.env.REACT_APP_REGION);
console.log("WEB CLIENT: " + process.env.REACT_APP_WEB_CLIENT);
console.log("INGEST POINT: " + process.env.REACT_APP_INGEST_POINT );
console.log("MIXPANEL: " + process.env.REACT_APP_MIXPANEL );

if (!process.env.REACT_APP_API) {
   mixpanel.init('0fcb9865467e738cc859de7e4f42ed5d', {
      debug: true,
      opt_out_tracking_by_default: false,
   });

   if (isLocalhost) {
      console.log('Log out of analytics checking');
      mixpanel.opt_out_tracking();
   }

   Amplify.configure({
      Auth: {
         // REQUIRED - Amazon Cognito Identity Pool ID
         identityPoolId: 'us-east-1:e839f475-0266-4f7c-ba54-c6fb378169c7',
         // REQUIRED - Amazon Cognito Region
         region: 'us-east-1',
         // OPTIONAL - Amazon Cognito User Pool ID
         userPoolId: 'us-east-1_azTJlZeRb',
         // OPTIONAL - Amazon Cognito Web Client ID
         userPoolWebClientId: '7onkvofvfuirh2ak2t86hdv5cb',
      },
      Storage: {
         bucket: 'dev-heavyworth-ingestion', //REQUIRED -  Amazon S3 bucket
         region: 'us-east-1',
         customPrefix: {
            public: '',
            protected: '',
            private: ''
         }
      },
      API: {
         endpoints: [
            {
               name: 'api',
               endpoint: 'https://dev-api.heavyworth.com/v1'
            },
         ]
      }
   });
} else {
   mixpanel.init(process.env.REACT_APP_MIXPANEL || '699e085bb3e38800ebf5ea62d37e4bb0', {
      opt_out_tracking_by_default: false,
   });
   /*
   Identity pool id: us-east-1:e839f475-0266-4f7c-ba54-c6fb378169c7
   User Pool Id: us-east-1_azTJlZeRb
   User Poot ARN: arn:aws:cognito-idp:us-east-1:019868025223:userpool/us-east-1_azTJlZeRb
   App Id: 4cdtf6udj7bp23ulo90vicbs67
    */
   Amplify.configure({
      Auth: {
         identityPoolId: process.env.REACT_APP_IDENTITY_POOL || 'us-east-1:e839f475-0266-4f7c-ba54-c6fb378169c7',
         region: process.env.REACT_APP_REGION || 'us-east-1',
         userPoolId: process.env.REACT_APP_USER_POOL || 'us-east-1_azTJlZeRb',
         userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT || '7onkvofvfuirh2ak2t86hdv5cb',
         authenticationFlowType: process.env.REACT_APP_AUTH_TYPE || 'USER_SRP_AUTH',
      },
      Storage: {
         bucket: process.env.REACT_APP_INGEST_POINT || 'prd-heavyworth-ingestion',
         region: process.env.REACT_APP_REGION || 'us-east-1',
         customPrefix: {
            public: '',
            protected: '',
            private: ''
         }
      },
      API: {
         endpoints: [
            {
               name: 'api',
               endpoint: process.env.REACT_APP_API_ENDPOINT || 'https://api.heavyworth.com/v1'
            },
         ]
      }
   });
}
Storage.configure({level: 'private'});

class Main extends Component {

   constructor(props, context) {
      super(props, context);

      setUserData(get(props, 'authData'));
      mixpanel.identify(props.authData.signInUserSession.idToken.payload.sub);
      // Show in MixPanel a property for FilesOnly customers.
      if (isFilesOnly()) {
         mixpanel.register({
            'CustomerType': 'FilesOnly',
         });
      }
      const userData = getUserData();

      window.Intercom('update', {
         hide_default_launcher: false,
         name: userData.name, // Full name
         email: userData.email, // Email address
      });

      Sentry.configureScope(function(scope) {
        scope.setUser({"id": props.authData.signInUserSession.idToken.payload.sub, 
          "email": userData.email});
      });
   }

   static childContextTypes = {
      authData: PropTypes.object,
   };

   getChildContext() {
      return {
         authData: this.props.authData,
      };
   }

   render() {
      const redirectPath = isFilesOnly() ? '/valuation' : DEFAULT_LOCATION;

      return (
         <div className={'main-style'}>
            <Switch>
               <Route exact path='/' render={() => <Redirect to={redirectPath}/>}/>
               <Route exact path='/login' component={MySignIn}/>
               <Route path='/*' component={EvalMain}/>
            </Switch>
         </div>
      )
   }
}

export default withAuthenticator(Main, false, [
   <MySignIn/>,
   <ConfirmSignIn/>,
   <VerifyContact/>,
   <SignUp/>,
   <ConfirmSignUp/>,
   <ResetPassword/>
]);

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Fragment, PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ErrorSnackbar from '../components/eval/components/ErrorSnackbar';
import {
   DATE_FORMAT, HEADER_HEIGHT_EVAL_TABLE, UP_ARROW, HEADER_ICON_WIDTH_EVAL_TABLE, DOWN_ARROW,
   GET_USERS, GET_USERS_ALL, DELETE_USER,
} from '../Constants';
import ModalDialog from '../fhg/components/dialog/ModalDialog';
import Typography from '../fhg/components/Typography';
import {removeOne} from '../fhg/utils/Utils';
import {withRequest, requestForServer} from '../Utils/ServerUtil';

const requestStyles = theme => ({
   progress: {
      position: 'absolute',
      marginLeft: '47%',
      top: 40,
   },
});

const styles = theme => ({
   table: {
      maxHeight: `calc(100% - ${theme.spacing(4) + 12}px)`,
      width: '100%',
      border: 'none',
      userSelect: 'none',
      zIndex: 100,
      overflow: 'auto',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         maxHeight: '100%',
         overflow: 'hidden',
         position: 'relative',
      },
      '@media print': {
         display: 'table',
      },
      '& .-sort-desc .offset.sortContent::after': {
         transform: 'translateY(-2px)',
      },
      '& .-sort-asc .offset.sortContent::after': {
         transform: 'translateY(-2px)',
      },
      '& .-sort-desc .sortContent::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         '@supports not (-ms-high-contrast: none)': {
            backgroundColor: theme.palette.button.standard.secondary,
            '-webkit-mask': `url(${DOWN_ARROW}) no-repeat`,
            mask: `url(${DOWN_ARROW}) no-repeat`,
         },
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.5,
            background: `url(${DOWN_ARROW}) no-repeat`,
         },
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
      '& .-sort-asc .sortContent::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         '@supports not (-ms-high-contrast: none)': {
            backgroundColor: theme.palette.button.standard.secondary,
            '-webkit-mask': `url(${UP_ARROW}) no-repeat`,
            mask: `url(${UP_ARROW}) no-repeat`,
         },
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.5,
            background: `url(${UP_ARROW}) no-repeat`,
         },
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
      '& .rt-thead': {
         paddingLeft: theme.spacing(4),
         height: 42,
         borderTop: `1px solid ${theme.palette.environment.light.level1.accent}`,
         backgroundColor: theme.palette.environment.light.level1.base,
      },
      '& .rt-tbody .rt-tr': {
         paddingLeft: theme.spacing(4),
      },
      '& .rt-noData': {
         padding: theme.spacing(1),
      },
      '& .rt-th': {
         display: 'flex',
         alignItems: 'center',
      }
   },
   emptyHeight: {
      height: `calc(100% - ${theme.spacing(4) + 12}px)`,
      minHeight: 122,
   },
   progress: {
      position: 'absolute',
      marginLeft: '47%',
      top: 40,
   },
   columnTitle: {
      paddingTop: 3,
      color: theme.palette.content.nonessential,
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: '17px',
   },
   selected: {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
         backgroundColor: `${theme.palette.action.selected} !important`,
      },
   },
   menuText: {
      color: theme.palette.text.secondary,
      display: 'inline-block',
   },
   iconRoot: {
      fontSize: 14,
   },
   filterIcon: {
      display: 'inline-block',
      marginLeft: theme.spacing(0.5),
      height: 22,
      width: 22,
      marginTop: -2,
   },
   activeFilterIcon: {
      height: HEADER_HEIGHT_EVAL_TABLE,
      width: HEADER_ICON_WIDTH_EVAL_TABLE,
      marginTop: 0,
      padding: '1px 1px 0 1px',
      paddingRight: 1,
      paddingTop: 1,
      borderRadius: '50%',
      backgroundColor: theme.palette.button.standard.secondary,
      color: 'white',
   },
   notSort: {
      '&.offset.sortContent:hover::after': {
         transform: 'translateY(-2px)',
      },
      '&.sortContent:hover::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         filter: 'opacity(30%)',
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.3,
         },
         background: `url(${UP_ARROW}) no-repeat`,
         '-webkit-mask': `none`,
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
   },
});

/**
 * The users table component.
 *
 * Last Reviewed 4/18/19
 */
class UsersTable extends PureComponent {
   blockScroll = false;

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      evaluations: PropTypes.array,             // Evaluations of items.
      search: PropTypes.string,                 // The search/filter text to limit rows to those containing search.
      data: PropTypes.any,                      // The list of evaluations.
      selected: PropTypes.string,               // The user_id of the selected user.
   };

   constructor(props, context) {
      super(props, context);

      // Read the table settings from session storage to restore when the user comes back to this page.
      const userTableString = sessionStorage.userTable;
      const userTable = userTableString ? JSON.parse(userTableString) : {sort: [{id: 'Name', desc: false}]};

      const userTableLocal = localStorage.userTable;
      const filter = userTableLocal ? JSON.parse(userTableLocal).filter : {sort: [{id: 'Name', desc: false}]};

      //Set the selected URI to the last URL that isn't this page.
      this.state = {
         selected: undefined,
         defaultSort: userTable.sort,
         filteredData: this.onSearch(props.search, filter, this.getColumns(userTable.sort), props.data),
         filter,
         sort: userTable.sort,
         showConfirmDelete: false,
      };
   }

   componentWillReceiveProps(nextProps) {
      const {search, selected} = this.props;

      const isDataChanged = !isEqual(nextProps.data, this.props.data);
      if (isDataChanged) {
         this.requestOrganizations(nextProps.groups);
      }
      if (search !== nextProps.search || isDataChanged) {
         const filteredData = this.onSearch(nextProps.search, this.state.filter, this.getColumns(), nextProps.data);
         this.setState({filteredData});
      }
      if (selected !== this.props.selected) {
         this.setState({selected});
      }
   }

   componentDidUpdate() {
      this.scrollIntoView();
   }

   /**
    * Request for each organization
    * @param allGroups
    */
   requestOrganizations(allGroups = []) {
      const groups = {};
      //TODO this needs to be queried for to get permissions.
      const isUserSuperAdmin = true;

      if (isUserSuperAdmin) {
         this.setState({groups});
         for (const group of allGroups) {
            requestForServer(GET_USERS.format({orgId: group.organization_id})).then(result => {
               // setTimeout(() => {
               for (const user of result.data) {
                  if (groups[user.user_id]) {
                     groups[user.user_id].push(group);
                  } else {
                     groups[user.user_id] = [group];
                  }
               }
               this.setState({groups: {...groups}});
               // }, 5);
            });
         }
      }
   }

   /**
    * When the sort values change save them locally and in session storage.
    *
    * @param sort The new sort object from ReactTable.
    */
   onSortedChange = (sort) => {
      this.setState({sort});
      sessionStorage.userTable = JSON.stringify({sort});
   };

   /**
    * Determines if the row is selected.
    * @param row The row to check.
    * @return {boolean} True if the row is selected.
    */
   isRowSelected = (row) => {
      const {selected} = this.state;

      return row && selected !== undefined && selected === get(row, 'original.user_id');
   };

   /**
    * Get the name of the selected user.
    * @return {string} The selected user's name.
    */
   getSelectedUserName = () => {
      const selectedUser = find(this.state.filteredData, {user_id: this.state.selected});
      return selectedUser ? selectedUser.name : 'N/A';
   };

   /**
    * Scroll the selected table row into view. The selection is indicated by the 'this.props.classes.selected' class.
    */
   scrollIntoView = () => {
      const elements = document.getElementsByClassName(this.props.classes.selected);
      let objDiv = document.getElementsByClassName('rt-tbody')[0];
      if (objDiv && elements.length > 0) {
         objDiv.scrollTop = elements[0].offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
            (elements[0].offsetHeight / 2);
      }
   };

   /**
    * When the user searches the table.
    *
    * @param search The search text to find.
    * @param filter The filter for the table.
    * @param columns The table columns. Used for the accessor to get the data from the column to search.
    * @param data The table data.
    * @return {*|Array} The array of filtered rows.
    */
   onSearch = (search = [], filter, columns, data = []) => {
      let filteredData = [];
      let dateSearch = moment(search);
      const isSearchDate = dateSearch.isValid();
      let isSearchHour = dateSearch.get('hour');
      let isSearchMinute = dateSearch.get('minute');
      let sourceData = data;

      if (search.length > 0 && sourceData) {
         search = search.toLocaleLowerCase().trim();
         filteredData = sourceData.filter(item => {
            //If all the filters are on or the status is in the filter list.
            for (let column of columns) {
               let columnAccessor = column.accessor;
               let value = typeof columnAccessor === 'function' ? columnAccessor(item) :
                  get(item, columnAccessor);

               if (column.searchText) {
                  value = column.searchText(value);
               }

               if (value !== undefined && value !== null && typeof value !== 'object') {
                  const valueMoment = moment(value, DATE_FORMAT, true);
                  // noinspection JSCheckFunctionSignatures
                  if (isSearchDate &&
                     valueMoment.isSame(dateSearch, isSearchMinute ? 'minute' : isSearchHour ? 'hour' : 'day')) {
                     return true;
                  }
                  if (typeof value === 'string') {
                     if (value.toLocaleLowerCase().indexOf(search) >= 0) {
                        return true;
                     }
                  } else if (typeof value === 'number') {
                     if (Number(value) === Number(search)) {
                        return true;
                     }
                  }
               }
            }
            return false;
         });
      } else {
         filteredData = sourceData;
      }

      return filteredData;
   };

   /**
    * Show the delete confirmation.
    */
   onDelete = () => {
      this.setState({showConfirmDelete: true})
   };

   /**
    * Clsoe the delete confirmation.
    */
   closeConfirm = () => {
      this.setState({showConfirmDelete: false})
   };

   /**
    * Delete the selected user after confirmation.
    */
   onConfirmedDelete = () => {
      if (this.state.selected) {
         requestForServer(DELETE_USER.format({userId: this.state.selected}), 'del').then(() => {
            const filteredData = this.state.filteredData.slice(0);
            const {data} = this.props;
            removeOne(filteredData, findIndex(filteredData, {user_id: this.state.selected}));
            removeOne(data, findIndex(data, {user_id: this.state.selected}));
            this.setState({filteredData, showConfirmDelete: false});
         }).catch(error => {
            this.setState({showError: true, message: error.message});
         });
      } else {
         console.log('A user id is required for delete user.');
      }
   };

   /**
    * Get the table columns for the user table. Used for the user table, but also used for the filtering
    * and searches to access the column data.
    *
    * @param defaultSort The sort to initialize the table with. Used only in the constructor before the state is set.
    * @return {*[]} The array of user table columns.
    */
   getColumns = (defaultSort) => {
      const {classes} = this.props;

      const sortColumnId = get(this, 'state.sort[0].id', get(defaultSort, '[0].id'));

      //TODO this needs to be queried for to get permissions.
      const isUserSuperAdmin = true;

      const columns = [
         {
            id: 'Name',
            Header: (
               <Typography className={classNames('sortContent', classes.columnTitle,
                  {[classes.notSort]: sortColumnId !== 'evalUser'})} id={'admin.name.column'}/>
            ),
            accessor: 'name',
            minWidth: 120,
         }, {
            id: 'Email',
            Header: (
               <Typography className={classNames('sortContent', classes.columnTitle,
                  {[classes.notSort]: sortColumnId !== 'evalOwner'})} id={'admin.email.column'}/>
            ),
            accessor: 'email',
            minWidth: 150,
         }
      ];

      if (isUserSuperAdmin) {
         columns.push({
            id: 'Organization',
            Header: (
               <Typography className={classNames('sortContent', classes.columnTitle,
                  {[classes.notSort]: sortColumnId !== 'evalOwner'})} id={'admin.organization.column'}/>
            ),
            accessor: row => get(this.state, `groups[${row.user_id}]`, []),
            Cell: row => {
               const length = get(row, 'value.length');
               if (length > 1) {
                  return <span>Multiple</span>
               } else if (length === 1) {
                  return <span>{get(row, 'value[0].name', '-')}</span>
               } else {
                  return <span>-</span>
               }
            },
            minWidth: 150,
         });
      }
      columns.push({
         id: 'delete',
         accessor: 'organizations',
         Cell: row => (
            <Button className={'minimal-destroy-button no-print'} variant={'text'}
                    onClick={this.onDelete} disableRipple={true}>
               <Typography color='inherit' variant='button' id={'admin.deleteUser.label'}>Delete User</Typography>
            </Button>

         ),
         width: 168,
      });

      return columns;
   };

   /**
    * Close the error snackbar.
    */
   handleErrorClose = () => {
      this.setState({showError: false});
   };

   render() {
      const {defaultSort, filteredData, showConfirmDelete, showError, message} = this.state;
      const {classes, isLoading} = this.props;

      return (
         <Fragment>
            {showConfirmDelete && (
               <ModalDialog open={showConfirmDelete} onClose={this.closeConfirm} onSubmit={this.onConfirmedDelete}
                            messageKey={'admin.deleteUser.text'} titleKey={'admin.deleteUser.title'} maxWidth={'xs'}
                            submitKey={'delete.button'} submitColorStyle={'destroy-button'}
                            values={{user: this.getSelectedUserName()}}
               />
            )}
            {showError && (
               <ErrorSnackbar open={showError} onClose={this.handleErrorClose} errorId={'admin.deleteUser.error'}
                              values={{message}}/>
            )}

            <ReactTable defaultPageSize={10000} minRows={1} loading={isLoading}
                        LoadingComponent={() => isLoading && <CircularProgress className={classes.progress}/>}
                        multiSort={false} className={classNames(classes.table, '-highlight', 'eval-table',
               {[classes.emptyHeight]: !filteredData || filteredData.length <= 0})}
                        data={filteredData}
                        defaultSorted={defaultSort}
                        onSortedChange={this.onSortedChange}
                        showPagination={false}
                        getTrProps={(state, rowInfo) => {
                           return {
                              className: this.isRowSelected(rowInfo) ? classes.selected : undefined,
                              onClick: (e) => {
                                 e.preventDefault();
                                 e.stopPropagation();
                                 this.setState({selected: get(rowInfo, 'original.user_id')});
                              },
                           }
                        }}

                        columns={this.getColumns()}
            />
         </Fragment>
      );
   }
}

export default withStyles(requestStyles)(withRouter(withRequest({
   uri: props => props.groupId === undefined ? undefined :
      (props.groupId === 0 ? GET_USERS_ALL : GET_USERS.format({orgId: props.groupId})),
   showProgress: false,
   showOnLoad: true,
   errorMessageId: 'evalDashboard.fetch.error',
})(withStyles(styles)(UsersTable))));

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Auth} from 'aws-amplify';
import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {PASSWORD_RESET} from '../../Constants';
import {LOGO} from '../../LogoConstants';
import Typography from '../../fhg/components/Typography';
import {requestForServer} from '../../Utils/ServerUtil';
import SimpleAppBar from '../eval/components/SimpleAppBar';

const styles = theme => ({
   root: {
      width: '100%',
      height: '100vh',
      backgroundColor: theme.palette.brand.gray,
   },
   mainGrid: {
      height: 'calc(100% - 80px)',
      width: '100%',
      minHeight: 480,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.brand.gray,//'#5D788F',
      overflow: 'hidden',
   },
   titleStyle: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
   },
   content: {
      width: 436,
      margin: 'auto',
      backgroundColor: theme.palette.environment.light.level1.base,
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
   },
   textFieldRoot: {
      padding: 0,
      'label + &': {
         marginTop: -4,
      },
   },
   textFieldInput: {
      backgroundColor: `${theme.palette.common.white} !important`,
      border: `1px solid ${theme.palette.divider} !important`,
      borderRadius: 4,
      padding: '11px !important',
      marginTop: `${theme.spacing(1)}px !important`,
      width: `calc(100% - ${theme.spacing(3)}px)`,
      '&:focus': {
         border: `1px solid ${theme.palette.content.default} !important`,
      },
   },
   textFieldInputError: {
      border: `1px solid ${theme.palette.error.main} !important`,
      color: `${theme.palette.error.main} !important`,
      '&:focus': {
         border: `1px solid ${theme.palette.error.main} !important`,
      },
   },
   button: {
      color: theme.palette.common.white,
      textTransform: 'none',
      borderRadius: 4,
      backgroundColor: theme.palette.button.standard.primary,
      marginTop: `${theme.spacing(3)}px !important`,
      padding: '14px 0',
      lineHeight: '20px !important',
      height: '48px !important',
      width: 'unset',

      '&:hover': {
         backgroundColor: theme.palette.button.standard.primary,
      },
   },
   error: {
      width: '100%',
      marginTop: `&{theme.spacing(4)}px !important`,
   },
   buttonText: {
      fontSize: 16,
      fontWeight: 'bold',
   },
   formControl: {
      position: 'inherit',
      transform: 'none',
   },
   firstTitleStyle: {
      margin: theme.spacing(2.5, 3, 4.5),
      textAlign: 'center',
   },
});

/**
 * The custom AWS Cognito Forgot or Reset Password component.
 */
class ResetPassword extends Component {

   constructor(props) {
      super(props);

      this.state = {
         passwordCode: '',
         email: '',
         password: '',
         error: undefined,
      };
   }

   handleChange = ({target}) => {
      this.setState({[target.name]: target.value});
   };

   handleSubmit = async event => {
      event.preventDefault();

      try {
         const data = await Auth.forgotPassword(this.state.email);
         mixpanel.track('Password Reset');
         requestForServer(PASSWORD_RESET, 'post', {email: this.state.email, platform: 'Web'});
         this.setState({delivery: data.CodeDeliveryDetails, error: undefined});
      } catch (e) {
         this.setState({error: 'reset.error'});
      }
   };

   handleSubmitPassword = async event => {
      const {email, passwordCode, password} = this.state;

      event.preventDefault();

      try {
         await Auth.forgotPasswordSubmit(email, passwordCode, password);
         this.changeState('signIn', {showSuccess: true});
         document.removeEventListener('keydown', this.handleKey, false);
         this.setState({delivery: null, error: undefined});
      } catch (e) {
         this.setState({error: 'submit.error'});
      }
   };

   triggerAuthEvent = (event) => {
      const state = this.props.authState;

      if (this.props.onAuthEvent) {
         this.props.onAuthEvent(state, event);
      }
   };

   changeState = (state, data) => {
      if (this.props.onStateChange) { this.props.onStateChange(state, data); }

      this.triggerAuthEvent({
         type: 'stateChange',
         data: state,
      });
   };

   sendView = () => {
      const {classes} = this.props;
      const {email, error} = this.state;

      return (
         <Grid container direction={'column'}>
            <Typography variant='body1' id={'reset.subheading'}
                        className={`${classes.firstTitleStyle} level-3-heading`}>
               Type your email below. We will email you a code that you can use in the next step to reset your
               password.
            </Typography>
            {error &&
            <Typography className={classes.error} color={'error'} variant={'body1'} id={error}>Error
               Message.</Typography>}
            <TextField
               name='email'
               label={<Typography color='textSecondary' variant='body2' id={'login.email.label'}>Email</Typography>}
               onChange={this.handleChange}
               value={email}
               margin='normal'
               autoFocus
               InputProps={{
                  'aria-label': 'Email',
                  disableUnderline: true,
                  classes: {
                     root: classes.textFieldRoot,
                     input: classNames({[classes.textFieldInputError]: error}, classes.textFieldInput,
                        'additional'),
                  },
               }}
               InputLabelProps={{
                  shrink: true,
                  focused: false,
                  classes: {
                     formControl: classes.formControl,
                  }
               }}
            />

            <Button variant={'text'} disabled={!email} color='secondary' type='submit'
                    className={classNames(classes.button, 'button', {disabled: !email})}>
               <Typography className={classes.buttonText} color='inherit' variant={'button'}
                           id={'reset.button.label'}>
                  Reset Password
               </Typography>
            </Button>
         </Grid>
      )
   };

   submitView = () => {
      const {classes} = this.props;
      const {passwordCode, password, error, email} = this.state;
      return (
         <Grid container direction={'column'}>
            <Typography variant={'body1'} id={'submit.subheading'} values={{email}}
                        className={`${classes.firstTitleStyle} level-3-heading`}>
               Enter the code we sent to nick@mail.com and then type your new password.
            </Typography>
            {error &&
            <Typography className={classes.error} color={'error'} variant={'body1'} id={error}>Error
               Message.</Typography>}
            <TextField
               name='passwordCode'
               label={<Typography color='textSecondary' variant='body2' id={'submit.code.label'}>Code</Typography>}
               onChange={this.handleChange}
               value={passwordCode}
               autoFocus
               className={classes.textField}
               margin='normal'
               InputProps={{
                  'aria-label': 'Password Code',
                  disableUnderline: true,
                  classes: {
                     root: classes.textFieldRoot,
                     input: classNames({[classes.textFieldInputError]: error}, classes.textFieldInput,
                        'additional'),
                  },
               }}
               InputLabelProps={{
                  shrink: true,
                  focused: false,
                  classes: {
                     formControl: classes.formControl,
                  }
               }}
            />
            <TextField
               name='password'
               label={<Typography color='textSecondary' variant='body2'
                                  id={'login.password.label'}>Password</Typography>}
               className={classes.textField}
               onChange={this.handleChange}
               type='password'
               autoComplete='current-password'
               value={password}
               margin='normal'
               InputProps={{
                  'aria-label': 'Email',
                  disableUnderline: true,
                  classes: {
                     root: classes.textFieldRoot,
                     input: classNames({[classes.textFieldInputError]: error}, classes.textFieldInput,
                        'additional'),
                  },
               }}
               InputLabelProps={{
                  shrink: true,
                  focused: false,
                  classes: {
                     formControl: classes.formControl,
                  }
               }}
            />
            <Button variant={'text'} disabled={!passwordCode || !password} color='secondary' type='submit'
                    className={classNames(classes.button, 'button', {disabled: !passwordCode || !password})}>
               <Typography className={classes.buttonText} color={'inherit'} variant={'button'}
                           id={'submit.button.label'}>
                  Set New Password
               </Typography>
            </Button>
         </Grid>
      )
   };

   render() {
      const {classes, authState,} = this.props;

      if (authState !== 'forgotPassword') { return null; }

      return (
         <form onSubmit={this.state.delivery ? this.handleSubmitPassword : this.handleSubmit}>
            <div className={classes.root}>
               <SimpleAppBar logo={LOGO()} isSignIn color='secondary' position='sticky'/>
               <div className={classes.mainGrid}>
                  <div style={{overflow: 'auto'}}>
                     <Grid container direction={'column'} className={classes.content}>
                        <Typography className={`${classes.titleStyle} level-2-heading`} variant={'h4'}
                                    id={'reset.title'}>Reset Your
                           Password</Typography>
                        {this.state.delivery ? this.submitView() : this.sendView()}
                     </Grid>
                  </div>
               </div>
            </div>
         </form>
      );
   }

}

export default withRouter(withStyles(styles)(ResetPassword));

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import {defer, find, cloneDeep} from 'lodash';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pullAt from 'lodash/pullAt';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import 'react-table/react-table.css';
// noinspection ES6CheckImport
import {FIRST_VALID_YEAR, LAST_VALID_YEAR, GET_USERS_EMAIL, EQUIPMENT_DETAIL_PROPERTY_WIDTH} from '../../../Constants';
import ReactSelect from '../../../fhg/components/ReactSelect';
import Typography from '../../../fhg/components/Typography';
import {formatMessage} from '../../../fhg/utils/Utils';
import {getUserData, requestForServer} from '../../../Utils/ServerUtil';
import FormattedTextAutoSuggest from '../components/FormattedTextAutoSuggest';
import FormattedTextField from '../components/FormattedTextField';
import {
   HEAVYWORTH_VALUATION_TYPE, INTERNAL_VALUATION_TYPE, PROFILE_VALUATION_TYPE, NONE_VALUATION_TYPE, SELF_VALUATION_TYPE
} from '../components/ValuationTypeMenu';

const SUMMARY_PANEL_WIDTH = EQUIPMENT_DETAIL_PROPERTY_WIDTH + 50;

const styles = theme => ({
   description: {
      marginLeft: 0,
      top: 0,
      // top: -24,
      // paddingTop: 16,
      backgroundColor: 'white',
      maxWidth: `calc(100% - ${SUMMARY_PANEL_WIDTH}px)`,
      [theme.breakpoints.down('sm')]: {
         maxWidth: '100% !important',
      },
      zIndex: 1,
      width: '100%',
   },
   descriptionStyle: {
      marginLeft: 0,
      maxWidth: `calc(100% - ${SUMMARY_PANEL_WIDTH}px)`,
      [theme.breakpoints.down('sm')]: {
         maxWidth: '100% !important',
      },
      top: 0,
      // top: -24,
      // paddingTop: 16,
      backgroundColor: 'white',
      width: '100%',
      marginTop: 41,
   },
   baseInputStyle: {
      composes: 'contrast-text',
   },
   notesInput: {
      composes: '$baseInputStyle',
      padding: `${theme.spacing(2)}px !important`,
      height: 'calc(100% - 60px)',
   },
   smallInput: {
      composes: '$baseInputStyle',
      height: 30,
      width: 90,
   },
   //Disable the arrows for the year input.
   smallYearInput: {
      /* Chrome, Safari, Edge, Opera */
      '& input::-webkit-outer-spin-button': {
         '-webkit-appearance': 'none',
         margin: 0,
      },
      '& input::-webkit-inner-spin-button': {
         '-webkit-appearance': 'none',
         margin: 0,
      },
      /* Firefox */
      '& input[type=number]': {
         '-moz-appearance': 'textfield',
      }
   },
   normalInput: {
      composes: '$baseInputStyle',
      height: 30,
      width: 190,
   },
   largeInput: {
      composes: '$baseInputStyle',
      height: 30,
      maxWidth: 296,
   },
   largeInputMultiline: {
      composes: '$baseInputStyle',
      minHeight: 30,
      height: 'unset',
      maxWidth: 296,
   },
   extraLargeInput: {
      composes: '$baseInputStyle',
      height: 30,
      maxWidth: 330,
   },
   formStyle: {
      paddingBottom: theme.spacing(2),
      width: `calc(100% - ${SUMMARY_PANEL_WIDTH}px) !important`,
      [theme.breakpoints.down('sm')]: {
         width: '100% !important',
      },
      '& div': {
         width: '100% !important',
      }
   },
   spacingStyle: {
      margin: `9px 0 !important`,
      maxWidth: `calc(100% - ${SUMMARY_PANEL_WIDTH}px) !important`,
      [theme.breakpoints.down('sm')]: {
         maxWidth: '100% !important',
      }
   },
   noRightRadius: {
      composes: '$baseInputStyle',
      borderRadius: '4px 0px 0px 4px',
      width: 113,
      height: 30,
      '&:focus': {
         //Move the left component on top so the border shows all the way around on focus.
         zIndex: 5,
      }
   },
   noLeftRadius: {
      composes: '$baseInputStyle',
      borderRadius: '0px 4px 4px 0px',
      height: 30,
      width: 169,
      //overlap the left component by one so there isn't a double lined border.
      marginLeft: -1,
   },
   meter: {
      maxWidth: 150,
   },
   inputLabelStyle: {
      marginBottom: theme.spacing(1),
   },
   inputStyle2: {
      '& input': {
         height: 30
      }
   }
});

const valuationTypesOptions = [
   {label: 'HeavyWorth Value (FMV)', value: HEAVYWORTH_VALUATION_TYPE},
   {label: 'Internal Value', value: INTERNAL_VALUATION_TYPE},
   {label: 'Equipment Profile Range Value', value: PROFILE_VALUATION_TYPE},
   {label: 'Owner/Representative Value', value: SELF_VALUATION_TYPE},
   {label: 'List Asset/No Value', value: NONE_VALUATION_TYPE},
];

/**
 * The Form to edit equipment items.
 */
class EquipmentEditForm extends Component {
   static propTypes = {
      //Indicates if errors should be checked and displayed.
      showErrors: PropTypes.bool,
      isDisabled: PropTypes.bool,
      item: PropTypes.object,
      defaultLocation: PropTypes.string,     // Evaluation location used as the default for new assets.
      isNew: PropTypes.bool,                            // Indicates if the asset is new.
   };

   static defaultProps = {
      showErrors: false,
      isDisabled: false,
      item: {},
      defaultLocation: '',
      isNew: false,
   };

   constructor(props, context) {
      super(props, context);

      let meters;

      if (props.item && props.item.meters) {
         meters = typeof props.item.meters === 'string' ? JSON.parse(props.item.meters) :
            (props.item.meters ? cloneDeep(props.item.meters) : []);
         if (findIndex(meters, {units: 'Hours'}) < 0) {
            meters.push({timestamp: Date.now(), units: 'Hours', value: ''})
         }
         if (findIndex(meters, {units: 'Miles'}) < 0) {
            meters.push({timestamp: Date.now() - 20, units: 'Miles', value: ''});
         }
      } else {
         meters = [
            {timestamp: Date.now(), units: 'Hours', value: ''}, {timestamp: Date.now() - 20, units: 'Miles', value: ''},
            {timestamp: Date.now() - 40, units: '', value: ''}
         ];
      }

      const valuationTypeValue = get(props, 'item.valuation_type');
      let valuation_type = find(valuationTypesOptions, {value: valuationTypeValue || getUserData().defaultValuationType});

      this.state = {
         showError: false,
         errorValues: undefined,
         errorId: undefined,
         isYearValid: true,
         year: get(props, 'item.year', ''),
         make: get(props, 'item.make', ''),
         model: get(props, 'item.model', ''),
         description: get(props, 'item.description', ''),
         location: get(props, 'item.location', props.defaultLocation),
         serial: get(props, 'item.serial', ''),
         vin: get(props, 'item.vin', ''),
         unique_id: get(props, 'item.unique_id', ''),
         personal_id: get(props, 'item.personal_id', get(props, 'item.inventory_id', '')),
         sticker_id: get(props, 'item.sticker_id', ''),
         valuation_type,
         meters,
         features: get(props, 'item.features', []),
         problems: get(props, 'item.problems', []),
         notes: get(props, 'item.notes', ''),
         subpackage: get(props, 'item.subpackage') || undefined,
         showMore: false,
      };
      if (props.isNew) {
         this.handleOnChange();
      }
      defer(this.isValid);
   }

   /**
    * Place the focus on the first input after mounting.
    */
   componentDidMount() {
      Papa.parse('/MakeModelv2.csv', {
         download: true,
         header: true,
         complete: (makes) => {
            this.makeModels = makes.data;
         }
      });
   }

   // noinspection JSCheckFunctionSignatures
   componentDidUpdate(prevProps) {
      if (!prevProps.showErrors && this.props.showErrors) {
         const elements = document.getElementsByClassName('error');
         if (elements.length > 0) {
            elements[0].focus();
         }
      }
   }

   /**
    * Add an item to the features or problems array.
    * @param name The name of the array (i.e. features or problems)
    * @return {Function} The function that will add the item to the array.
    */
   add = (name) => () => {
      const array = this.state[name].slice(0);
      array.push('');
      this.setState({[name]: array}, () => {
         const elements = document.querySelectorAll(`[name="${name}"]`);
         if (elements.length > 0) {
            elements[elements.length - 1].focus();
         }
      });
   };

   /**
    * Delete an item from the features or problems array.
    * @param name The name of the array (i.e. features or problems)
    * @param index The index of the item to delete.
    * @return {Function} The function that will delete the item from the array.
    */
   onDelete = (name, index) => () => {
      const array = this.state[name].slice(0);
      array.splice(index, 1);
      this.setState({[name]: array, isChanged: true}, this.handleOnChange);
   };

   /**
    * Add a new uninitialized meter to the meters array.
    */
   addMeter = () => {
      const meters = this.state.meters.slice(0);
      meters.push({timestamp: Date.now(), units: '', value: ''});
      this.setState({meters, isChanged: true}, () => {
         const elements = document.querySelectorAll(`[name="${meters.length - 1}value"]`);
         if (elements.length > 0) {
            elements[0].focus({preventScroll: false});
         }
      });

   };

   /**
    * On clicking the delete button for the meter, the meter at the index will be deleted.
    * @param index The index of the meter to be deleted.
    * @return {Function} The function to delete the item at the index.
    */
   onDeleteMeter = (index) => () => {
      const meters = this.state.meters.slice(0);
      meters.splice(index, 1);
      this.setState({meters, isChanged: true}, this.handleOnChange);
   };

   /**
    * Handle changes to a property of the meters. Set the property at the index.
    * @param index The index of the meter to set to the new value.
    * @param propertyName The name of the property to set.
    * @return {Function} The function that will handle meter property changes at the index.
    */
   handleMeterChange = (index, propertyName) => ({target}) => {
      const meters = this.state.meters.slice(0);
      meters[index][propertyName] = propertyName === 'value' ? Number.parseInt(target.value, 10) : target.value;
      this.setState({meters, isChanged: true}, this.handleDebounceNotifyChange);
   };

   isYearValid = (year) => {
      return !year || (moment(year, 'YYYY', true).isValid() && year >= FIRST_VALID_YEAR && year <= LAST_VALID_YEAR);
   };

   /**
    * Handle changes to the item.
    * @param target The target of the event that triggered the change.
    */
   handleChange = ({target}) => {
      this.setState({[target.name]: target.value, isChanged: true, lastChanged: Date.now()},
         this.handleDebounceNotifyChange);
   };

   /**
    * Handle changes to the item.
    * @param target The target of the event that triggered the change.
    */
   handleYearChange = (event) => {
      const {intl} = this.props;

      event.target.setCustomValidity(
         this.isYearValid(event.target.value) ? '' : formatMessage(intl, 'equipmentEditDetail.year.error',
            'The year must be between {firstYear} and {lastYear}.',
            {firstYear: FIRST_VALID_YEAR, lastYear: LAST_VALID_YEAR}));
      this.handleChange(event);
   };

   handleOnChange = () => {
      const {year, make, model, description, location, sticker_id, personal_id, serial, vin, features, problems, notes, valuation_type, subpackage, unique_id} = this.state;
      this.props.onChange && this.props.onChange({
         year,
         make,
         model,
         description,
         location,
         serial,
         vin,
         unique_id,
         personal_id,
         sticker_id,
         meters: this.getMeters(),
         features,
         problems,
         notes,
         subpackage,
         valuation_type: (valuation_type && valuation_type.value) || undefined,
      });
      this.isValid();
   };

   handleDebounceNotifyChange = debounce(this.handleOnChange, 1000);

   notifyOnChange = () => {
      this.handleDebounceNotifyChange.flush();
   };

   /**
    * Handle changes to the features and problems array. Set the value at the index.
    * @param index The index of the item to set to the new value.
    * @return {Function} The function that will handle changes at the index.
    */
   handleArrayChange = (index) => ({target}) => {
      const array = this.state[target.name].slice(0);
      array[index] = target.value;
      this.setState({[target.name]: array, isChanged: true}, this.handleDebounceNotifyChange);
   };

   /**
    * Handle changes to the item with auto suggest. If newValue is set, the user selected a suggestion.
    * Otherwise they are typing in the input.
    *
    * @param name The name of the input.
    * @param target The target of the event that triggered the change.
    * @param newValue The new value selected from the suggestions.
    */
   handleAutoSuggestChange = (name) => ({target}, newValue) => {
      if (newValue) {
         this.setState({[name]: newValue.newValue, isChanged: true}, this.handleDebounceNotifyChange);
      } else {
         this.handleChange({target});
      }
   };

   /**
    * Clear the suggestions for auto suggest.
    */
   onClearSuggestions = (name) => () => {
      this.setState({[name]: []});
   };

   /**
    * Get the registered users from the server.
    * @param userText The user (email) the user has typed.
    * @param setOwnerName Indicates if a single user is found if the name should be used for ownerName.
    * @return {Promise<void>} The promise for the return of the users.
    */
   getUsers = async (userText, setOwnerName = false) => {
      const results = await requestForServer(GET_USERS_EMAIL.format({email: userText}));
      const resultUsers = get(results, 'data.Users');
      if (resultUsers && resultUsers.length > 0) {
         const users = resultUsers.map(user => {
            return {
               name: user.Attributes.find(attr => attr.Name === 'name').Value,
               email: user.Attributes.find(attr => attr.Name === 'email').Value
            }
         });
         if (setOwnerName && users.length === 1) {
            this.setState({users, ownerName: users[0].name});
         } else {
            this.setState({users});
         }
      }
   };

   /**
    * Fetch the equipment make for auto suggest.
    * @param value The changed value used to get suggestions.
    */
   onFetchMakeSuggestions = ({value}) => {
      this.getMakeSuggestions(value);
   };

   /**
    * From the suggestion object return the text to display to the user.
    *
    * @param make The suggested equipment make.
    * @return {*|boolean|string} The make.
    */
   getMakeSuggestionValue = (make) => {
      return make.make;
   };

   /**
    * Get the list of equipment make suggestions that match the value.
    * @param value The value to search.
    */
   getMakeSuggestions = value => {
      const inputValue = value.trim().toLowerCase();
      let selectedMakeModels;

      if (inputValue.length > 0) {
         selectedMakeModels = this.makeModels.filter(makeModel => {
            return makeModel.make.toLowerCase().startsWith(inputValue)
         });
         // noinspection JSCheckFunctionSignatures
         selectedMakeModels = uniqBy(selectedMakeModels, 'make');
      } else {
         selectedMakeModels = [];
      }

      this.setState({makeSuggestions: selectedMakeModels});
   };

   /**
    * Fetch the models for auto suggest.
    * @param value The changed value used to get suggestions.
    */
   onFetchModelSuggestions = ({value}) => {
      this.getModelSuggestions(value);
   };

   /**
    * From the suggestion object return the text to display to the user.
    *
    * @param makeModel The suggested make and model.
    * @return {*|boolean|string} The model to show to the user.
    */
   getModelSuggestionValue = (makeModel) => {
      return makeModel.model;
   };

   /**
    * Get the list of model suggestions based on the value typed by the user.
    * @param value The value to search for.
    */
   getModelSuggestions = value => {
      const inputValue = value.trim().toLowerCase();
      let selectedMakeModels;

      selectedMakeModels = this.makeModels.filter(makeModel => {
         return makeModel.make.toLowerCase() === this.state.make.toLowerCase() &&
            makeModel.model.toLowerCase().startsWith(inputValue)
      });
      // noinspection JSCheckFunctionSignatures
      selectedMakeModels = uniqBy(selectedMakeModels, 'model');

      this.setState({modelSuggestions: selectedMakeModels});
   };

   /**
    * Indicates if all the user inputs are valid and can be saved to the server.
    * @return {*|boolean} True if all inputs are valid.
    */
   isValid = () => {
      let {location, valuation_type} = this.state;

      const isRequiredValid = location && valuation_type;
      const isYearValid = this.isYearValid(this.state.year);

      const badMetersIndex = this.state.meters.findIndex(meter => {
         return ((meter.value === '' || isNaN(meter.value)) && meter.units &&
            (meter.units !== 'Hours' && meter.units !== 'Miles')) ||
            (meter.units === '' && meter.value !== '' && !isNaN(meter.value))
      });

      this.setState(
         {isYearValid, badMetersIndex: badMetersIndex >= 0 ? badMetersIndex : undefined});
      return isYearValid && badMetersIndex < 0 && !!isRequiredValid;
   };

   getMeters = () => {

      const currentMeters = this.state.meters.filter(meter => meter.units !== '' && meter.value !== '');
      const hoursIndex = currentMeters.findIndex(meter => meter.units === 'Hours' && !meter.value);
      pullAt(currentMeters, [hoursIndex]);

      const milesIndex = currentMeters.findIndex(meter => meter.units === 'Miles' && !meter.value);
      pullAt(currentMeters, [milesIndex]);

      let metersArray = [];
      for (const meter of currentMeters) {
         metersArray.push(omit(meter, ['timestamp']))
      }
      // forOwn(this.state.meters, (value, key) => {
      //    metersArray.push({name: key, unit: key, value});
      // });
      return metersArray;
   };

   /**
    * Close the edit view.
    */
   onClose = (event, itemId) => {
      const {history, onClose} = this.props;
      if (itemId) {
         this.setState({isChanged: false}, () => {
            onClose && onClose();
            history.push(`/items/${itemId}`);
         });
      } else {
         history.goBack();
      }
   };

   onShowMore = (event) => {
      this.setState({showMore: true});
   };

   /**
    * Handle changes to a react select component.
    * @param value The value of the react select.
    * @param name The name of the react select.
    */
   handleSelectChange = (value, name) => {
      if (name) {
         this.setState({[name]: value, isChanged: true}, this.handleDebounceNotifyChange);
      } else if (value && value.preventDefault) {
         value.stopPropagation();
         value.preventDefault();
      }
   };

   render() {
      const {classes, intl, isDisabled, defaultLocation} = this.props;
      const {makeSuggestions, modelSuggestions, year, make, model, description, location, sticker_id, personal_id, serial, vin, unique_id, meters, features, problems, notes, valuation_type} = this.state;

      return (
         <Grid name='edit input root' container style={{overflow: 'hidden', height: '100%'}} direction={'column'}>
            {/*<Grid container direction={'column'} wrap={'nowrap'} spacing={2}>*/}
            <Grid name='edit input frame1' item style={{position: 'relative', flex: '1 1', overflow: 'auto'}}>
               <Grid name='edit input frame2' container item direction={'column'} style={{position: 'relative', overflow: 'auto'}}>
                  <Grid item>
                     <Typography variant='inherit'
                                 className={classNames(classes.description, 'dark-level-3-heading')}
                                 id='newItem.main.title'>
                        Asset Description
                     </Typography>
                  </Grid>
                  <Grid item className={classes.spacingStyle}>
                     <InputLabel className={classes.inputLabelStyle} htmlFor='valuationType'>
                        {formatMessage(intl, 'valuationType.title')}
                     </InputLabel>
                     <ReactSelect
                        name={'valuation_type'}
                        id={'valuationType'}
                        value={valuation_type}
                        onChange={this.handleSelectChange}
                        options={valuationTypesOptions}
                        isMulti={false}
                        required
                        escapeClearsValue={false}
                        clearable={false}
                        inputProps={{className: classes.extraLargeInput}}
                        disabled={isDisabled}
                        fullWidth
                     />
                  </Grid>
                  <Grid item className={classes.spacingStyle}>
                     <FormattedTextField
                        name='year'
                        value={year}
                        autoFocus
                        className={classes.smallYearInput}
                        label={<Typography color='textSecondary' variant={'body1'} id='newItem.year.label'/>}
                        inputProps={{
                           className: classes.smallInput,
                           type: 'number',
                           maxLength: 4,
                           pattern: '[0-9]{4}',
                           title: formatMessage(intl, 'equipmentEditDetail.year.error',
                              'The year must be between {firstYear} and {lastYear}.',
                              {firstYear: FIRST_VALID_YEAR, lastYear: LAST_VALID_YEAR})
                        }}
                        onChange={this.handleYearChange}
                        onBlur={this.notifyOnChange}
                        margin='none'
                        placeholder={'1995'}
                        style={{marginTop: 0, marginBottom: 0}}
                        disabled={isDisabled}
                        fullWidth
                     />
                  </Grid>
                  <Grid item className={classes.spacingStyle}>
                     <FormattedTextAutoSuggest
                        name='make'
                        label={<Typography color='textSecondary' variant={'body1'} id='newItem.make.label'/>}
                        value={make}
                        onChange={this.handleAutoSuggestChange('make')}
                        onBlur={this.notifyOnChange}
                        onFetchSuggestions={this.onFetchMakeSuggestions}
                        onClearSuggestions={this.onClearSuggestions('makeSuggestions')}
                        getSuggestionValue={this.getMakeSuggestionValue}
                        suggestions={makeSuggestions}
                        placeholder={'John Deere'}
                        inputProps={{className: classes.normalInput}}
                        disabled={isDisabled}
                        fullWidth
                     />
                  </Grid>
                  <Grid item className={classes.spacingStyle}>
                     <FormattedTextAutoSuggest
                        name='model'
                        label={<Typography color='textSecondary' variant={'body1'} id='newItem.model.label'/>}
                        value={model}
                        onChange={this.handleAutoSuggestChange('model')}
                        onBlur={this.notifyOnChange}
                        onFetchSuggestions={this.onFetchModelSuggestions}
                        onClearSuggestions={this.onClearSuggestions('modelSuggestions')}
                        getSuggestionValue={this.getModelSuggestionValue}
                        suggestions={modelSuggestions}
                        placeholder={'8970'}
                        inputProps={{className: classes.normalInput}}
                        disabled={isDisabled}
                        fullWidth
                     />
                  </Grid>
                  <Grid item className={classes.spacingStyle}>
                     <FormattedTextField
                        name='description'
                        label={<Typography color='textSecondary' variant={'body1'} id='newItem.description.label'
                                           style={{display: 'inline-block'}}/>}
                        value={description}
                        onChange={this.handleChange}
                        onBlur={this.notifyOnChange}
                        margin='normal'
                        fullWidth
                        placeholder={'4WD Tractor'}
                        style={{marginTop: 0, marginBottom: 0}}
                        inputProps={{className: classes.largeInput}}
                        InputLabelProps={{required: false}}
                        disabled={isDisabled}
                     />
                  </Grid>
                  <Grid item>

                  <Typography variant='inherit'
                              className={classNames(classes.descriptionStyle, 'dark-level-3-heading')}
                              id='newItem.location.title'/>
                  </Grid>
                  <Grid item className={classes.spacingStyle}>
                     <FormattedTextField
                        name='location'
                        label={<Typography color='textSecondary' variant={'body1'} id='newItem.location.label'
                                           style={{display: 'inline-block'}}/>}
                        required
                        defaultValue={defaultLocation}
                        value={location}
                        onChange={this.handleChange}
                        onBlur={this.notifyOnChange}
                        margin='normal'
                        fullWidth
                        placeholder={'Manhattan, KS'}
                        style={{marginTop: 0}}
                        inputProps={{className: classes.largeInput}}
                        InputLabelProps={{required: false}}
                        disabled={isDisabled}
                     />
                  </Grid>
                  <Grid item>
                  <Typography variant='inherit'
                              className={classNames(classes.descriptionStyle, 'dark-level-3-heading')}
                              id='newItem.identification.title'/>
                  </Grid>
                  <Grid item className={classes.spacingStyle}>
                     <FormattedTextField
                        name='serial'
                        label={<Typography color='textSecondary' variant={'body1'} id={'equipmentDetail.serial.label'}>
                           Serial: N/A
                        </Typography>}
                        value={serial}
                        onChange={this.handleChange}
                        onBlur={this.notifyOnChange}
                        margin='normal'
                        fullWidth
                        placeholder={'Serial'}
                        style={{marginTop: 0}}
                        inputProps={{className: `${classes.largeInput} numbers-contrast`}}
                        disabled={isDisabled}
                     />
                  </Grid>
                  <Grid item className={classes.spacingStyle}>
                     <FormattedTextField
                        name='vin'
                        label={<Typography color='textSecondary' variant={'body1'} id={'equipmentDetail.vin.label'}>
                           VIN: N/A
                        </Typography>}
                        value={vin}
                        onChange={this.handleChange}
                        onBlur={this.notifyOnChange}
                        margin='normal'
                        fullWidth
                        placeholder={'Vin'}
                        style={{marginTop: 0}}
                        inputProps={{className: `${classes.largeInput} numbers-contrast`}}
                        disabled={isDisabled}
                     />
                  </Grid>
                  {this.state.showMore ? (
                     <React.Fragment>
                        <Grid item className={classes.spacingStyle}>
                           <FormattedTextField
                               name='unique_id'
                               label={<Typography color='textSecondary' variant={'body1'}
                                                  id={'equipmentDetail.uniqueId.label'}>
                                  Inventory ID: N/A
                               </Typography>}
                               value={unique_id}
                               onChange={this.handleChange}
                               onBlur={this.notifyOnChange}
                               margin='normal'
                               fullWidth
                               placeholder={'Unique ID #'}
                               style={{marginTop: 0}}
                               inputProps={{className: classes.largeInput}}
                               disabled={isDisabled}
                           />
                        </Grid>
                        <Grid item className={classes.spacingStyle}>
                           <FormattedTextField
                              name='personal_id'
                              label={<Typography color='textSecondary' variant={'body1'}
                                                 id={'equipmentDetail.inventoryId.label'}>
                                 Inventory ID: N/A
                              </Typography>}
                              value={personal_id}
                              onChange={this.handleChange}
                              onBlur={this.notifyOnChange}
                              margin='normal'
                              fullWidth
                              placeholder={'Inventory ID'}
                              style={{marginTop: 0}}
                              inputProps={{className: classes.largeInput}}
                              disabled={isDisabled}
                           />
                        </Grid>
                        <Grid item className={classes.spacingStyle}>
                           <FormattedTextField
                              name='sticker_id'
                              label={<Typography color='textSecondary' variant={'body1'}
                                                 id={'equipmentDetail.stickerId.label'}>
                                 Sticker ID: N/A
                              </Typography>}
                              value={sticker_id}
                              onChange={this.handleChange}
                              onBlur={this.notifyOnChange}
                              margin='normal'
                              fullWidth
                              placeholder={'Sticker ID'}
                              style={{marginTop: 0}}
                              inputProps={{className: classes.largeInput}}
                              disabled={isDisabled}
                           />
                        </Grid>
                     </React.Fragment>
                  ) : (
                     <Grid item>
                        <Button className={'minimal-secondary-button'} onClick={this.onShowMore} disabled={isDisabled}>
                           <Typography variant='inherit'>
                              Show More
                           </Typography>
                        </Button>
                     </Grid>
                  )}
                  <Typography variant='inherit'
                              className={classNames(classes.descriptionStyle, 'dark-level-3-heading')}
                              id='newItem.meter.title'>
                     Service Meter Reading
                  </Typography>
                  <Grid item style={{width: `calc(100% - ${SUMMARY_PANEL_WIDTH}px)`}}>
                     {meters.map((meter, index) => (
                           <Grid key={'meter' + (meter.timestamp || index)} item>
                              <FormattedTextField
                                 name={index + 'value'}
                                 className={classes.meter}
                                 value={meter.value}
                                 required={!!meter.units && meter.units !== 'Hours' && meter.units !== 'Miles'}
                                 onChange={this.handleMeterChange(index, 'value')}
                                 onBlur={this.notifyOnChange}
                                 margin='normal'
                                 placeholder={meter.units === 'Hours' ? '1,618' :
                                    meter.units === 'Miles' ? '137,000' : '0'}
                                 style={{marginTop: 0, marginLeft: 0}}
                                 inputProps={{className: `${classes.noRightRadius} numbers-contrast text`}}
                                 onDelete={this.onDeleteMeter(index)}
                                 disabled={isDisabled}
                                 thousandSeparator={true}
                                 format={'number'}
                              />
                              <FormattedTextField
                                 name={index + 'meter'}
                                 className={classes.meter}
                                 value={meter.units}
                                 required={!!meter.value}
                                 onChange={this.handleMeterChange(index, 'units')}
                                 onBlur={this.notifyOnChange}
                                 margin='normal'
                                 placeholder={'Meter Name'}
                                 style={{marginTop: 0}}
                                 inputProps={{className: classes.noLeftRadius}}
                                 disabled={isDisabled}
                              />
                           </Grid>
                        )
                     )}
                     <Button className={'minimal-secondary-button'} onClick={this.addMeter} disabled={isDisabled}>
                        <Typography variant='inherit' id={'newItem.addMeter.label'}/>
                     </Button>
                  </Grid>
                  <Typography variant='inherit'
                              className={classNames(classes.descriptionStyle, 'dark-level-3-heading')}
                              id='newItem.features.title'>
                     Features
                  </Typography>
                  <Grid item>
                     {features.map((feature, index) => (
                           <Grid item>
                              <FormattedTextField
                                 key={index + 'feature'}
                                 name={'features'}
                                 value={feature}
                                 onChange={this.handleArrayChange(index)}
                                 onBlur={this.notifyOnChange}
                                 margin='normal'
                                 fullWidth
                                 placeholder={'Feature'}
                                 style={{marginTop: 0, marginBottom: 18}}
                                 inputProps={{className: classes.largeInputMultiline}}
                                 onDelete={this.onDelete('features', index)}
                                 isDeletable={true}
                                 disabled={isDisabled}
                                 multiline={true}
                                 maxRows={4}
                              />
                           </Grid>
                        )
                     )}
                     <Button className={'minimal-secondary-button'} onClick={this.add('features')}
                             disabled={isDisabled}>
                        <Typography variant={'inherit'} id={'newItem.addFeature.label'}>
                           Add Feature
                        </Typography>
                     </Button>
                  </Grid>
                  <Typography variant='inherit'
                              className={classNames(classes.descriptionStyle, 'dark-level-3-heading')}
                              id='newItem.problems.title'>
                     Problems
                  </Typography>
                  <Grid item>
                     {problems.map((problem, index) => (
                           <Grid item>
                              <FormattedTextField
                                 key={index + 'problem'}
                                 name={'problems'}
                                 value={problem}
                                 onChange={this.handleArrayChange(index)}
                                 onBlur={this.notifyOnChange}
                                 margin='normal'
                                 fullWidth
                                 placeholder={'Problem'}
                                 style={{marginTop: 0, marginBottom: 18}}
                                 inputProps={{className: classes.largeInput}}
                                 onDelete={this.onDelete('problems', index)}
                                 isDeletable={true}
                                 disabled={isDisabled}
                              />
                           </Grid>
                        )
                     )}
                     <Button className={'minimal-secondary-button'} onClick={this.add('problems')}
                             disabled={isDisabled}>
                        <Typography variant='inherit' id={'newItem.addProblem.label'}>
                           Add Problem
                        </Typography>
                     </Button>
                  </Grid>
                  <Typography variant='inherit'
                              className={classNames(classes.descriptionStyle, 'dark-level-3-heading')}
                              id='newItem.notes.title'>
                     Notes
                  </Typography>
                  <Grid className={classes.formStyle} item>
                     <FormattedTextField name={'notes'} value={notes} onChange={this.handleChange} multiline={true}
                                         rows={4}
                                         placeholder={'Additional comments about the asset'} margin={'none'}
                                         inputProps={{className: classes.notesInput}}
                                         onBlur={this.notifyOnChange}
                                         disabled={isDisabled}

                     />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      );
   }
}

export default injectIntl(withStyles(styles)(EquipmentEditForm));
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import {clone, get, isFunction} from 'lodash';
import PropTypes from 'prop-types';
import React, {useMemo, useEffect, useState} from 'react';
import ReactTableOriginal from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import {
   HEADER_ICON_WIDTH_EVAL_TABLE, HEADER_HEIGHT_EVAL_TABLE, UP_ARROW, DOWN_ARROW, SECONDARY_HEADER_HEIGHT
} from '../../../Constants';
import {sortMethod, hasValue} from '../../../fhg/utils/Utils';

// withFixedColumns doesn't handle ref and/or innerRef correctly. Use tableRef as the ref and innerRef.
function Table({tableRef, ...props}) {
   return <ReactTableOriginal ref={tableRef} innerRef={tableRef} {...props}/>;
}

Table.propTypes = {
   ...ReactTableOriginal.propTypes,
};
Table.defaultProps = {
   ...ReactTableOriginal.defaultProps,
};
const ReactTable = withFixedColumns(Table);

const useStyles = makeStyles(theme => ({
   progressStyle: {
      position: 'absolute',
      marginLeft: '47%',
      marginTop: 45,
      zIndex: 1001,

   },
   equipmentTableStyle: {
      '@supports not (-ms-high-contrast: none)': {
         flex: '1 1 auto',
      },
      maxHeight: '100%',
      border: 'none',
      overflow: 'hidden',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         width: '100%',
      },
      '@media print': {
         display: 'table',
      },
      '& .sortContent:hover::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         filter: 'opacity(30%)',
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.3,
         },
         background: `url(${UP_ARROW}) no-repeat`,
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
      '& .-sort-desc .sortContent::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         filter: 'opacity(110%) !important',
         '@supports not (-ms-high-contrast: none)': {
            backgroundColor: `${theme.palette.button.standard.secondary} !important`,
            '-webkit-mask': `url(${DOWN_ARROW}) no-repeat`,
            mask: `url(${DOWN_ARROW}) no-repeat`,
         },
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.5,
            background: `url(${DOWN_ARROW}) no-repeat`,
         },
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
      '& .-sort-asc .sortContent::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         filter: 'opacity(110%) !important',
         '@supports not (-ms-high-contrast: none)': {
            backgroundColor: theme.palette.button.standard.secondary,
            '-webkit-mask': `url(${UP_ARROW}) no-repeat`,
            mask: `url(${UP_ARROW}) no-repeat`,
         },
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.5,
            background: `url(${UP_ARROW}) no-repeat`,
         },
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
      '& .rt-table': {
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            backgroundColor: theme.palette.environment.light.level2.base,
         },
         paddingBottom: 52,
      },
      '& .rt-thead .rt-tr': {
         marginLeft: 2,
         textAlign: 'left',
         textTransform: 'uppercase',
      },
      '& .rt-thead.-header': {
         backgroundColor: theme.palette.environment.light.level2.base,
         maxHeight: SECONDARY_HEADER_HEIGHT,
      },
      '& .rt-th, .rt-td': {
         alignSelf: 'center',
         paddingTop: theme.spacing(1),
         paddingBottom: theme.spacing(1),
         paddingLeft: theme.spacing(1),
         transition: 'none',
      },
      '& .rt-th div': {
         height: '100%',
      },
      '& .rt-th': {
         [theme.breakpoints.down('sm')]: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
         },
      },
      '& .rt-tr': {
         marginLeft: '4px !important',
         marginTop: 1,
         marginBottom: 1,
         flex: '0 0 auto',
      },
      '& .rt-tbody .rt-tr': {
         // Needed to make the :after 'absolute' position work for the selection borders.
         position: 'relative',
         backgroundColor: theme.palette.environment.light.level0.base,
      },
      '& .rt-tbody .rt-tr-group': {
         flex: '0 0 auto',
         borderBottom: `1px solid ${theme.palette.environment.light.level0.accent} !important;`,
      },
      '& .rt-resizable-header-content': {
         height: '100%',
         paddingBottom: theme.spacing(1),
      },
      '& .rt-tbody .rt-td': {
         fontSize: `${theme.size.font['text-small']}rem !important`,
      },
   },
   emptyHeight: {
      minHeight: 400,
      height: '100%'
   },
   noDataStyle: {
      width: 'fit-content',
      backgroundColor: `${theme.palette.environment.light.level2.base} !important`,
      [theme.breakpoints.up('md')]: {
         // whiteSpace: 'nowrap',
      },
   },
}), {name: 'AssetTableStyle'});

AssetTable.propTypes = {
   list: PropTypes.any,
   loading: PropTypes.any,
   loadingComponent: PropTypes.func,
   saving: PropTypes.any,
   noDataProps: PropTypes.func,
   trProps: PropTypes.func,
   columns: PropTypes.arrayOf(PropTypes.any),
   defaultSort: PropTypes.array,
   onSortedChange: PropTypes.func,
   refresh: PropTypes.any,
   tableRef: PropTypes.any,
   noDataText: PropTypes.string,
};

export default function AssetTable ({
   columns,
   noDataText='This catalog has no assets. Click the New Asset button to start adding assets.',
   list=[],
   loading,
   defaultSort,
   onSortedChange,
   tableRef,
   trProps,
}) {
   const classes = useStyles();

   const [isInitialized, setIsInitialized] = useState(false);

   /**
    * Scroll to the selected item.
    */
   const scrollIntoView = () => {
      const elements = document.getElementsByClassName('selected');

      if (elements.length > 0) {
         if (isFunction(elements[0].scrollIntoViewIfNeeded)) {
            elements[0].scrollIntoViewIfNeeded();
         } else {
            let objDiv = get(elements, '[0].parentElement.parentElement');
            if (objDiv) {
               objDiv.scrollTop = elements[0].offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
                  (elements[0].offsetHeight / 2);
            }
         }
      }
   };

   const filteredColumns = useMemo(() => {
      /**
       * Filter out the columns that don't have data in any row. The column.show is set to false if no data.
       *
       * NOTE: isShow is required on the column to be filtered. The column also must have an accessor to be able to
       * access the data.
       *
       * @param columns The table columns. Used for the accessor to get the data from the column to filter.
       * @param data The table data.
       * @return {*|Array} The array of columns.
       */
      const filterEmptyColumns = (columns=[], data = []) => {
         let useColumns = columns;
         if (localStorage.hideEmptyColumns === 'true' && data.length > 0) {
            // This is needed to know if the props.columns are changed.
            useColumns = clone(columns);

            for (let column of useColumns) {
               //Only process columns with isShow property and are showing.
               if (column.isShow === true && column.accessor !== undefined) {
                  let columnAccessor = column.accessor;
                  let hasValues = false;
                  for (const item of data) {
                     let value = typeof columnAccessor === 'function' ? columnAccessor(item) : get(item, columnAccessor);

                     if (hasValue(value)) {
                        hasValues = true;
                        break;
                     }
                  }
                  column.show = hasValues === true;
               }
            }
         }
         return useColumns;
      };

      return filterEmptyColumns(columns, list);
   }, [columns, list]);

   /**
    * Scroll the selected row into view. Only scroll when component is first made visible.
    */
   useEffect(() => {
       if (!isInitialized && columns.length > 0 && list.length > 0) {
          scrollIntoView();
          setIsInitialized(true);
       }
   }, [columns, list]);

   return (
      <ReactTable tableRef={tableRef} className={classNames(classes.equipmentTableStyle, 'equipment-list',
         {[classes.emptyHeight]: list.length <= 0})}
                  defaultPageSize={10000} minRows={0} loading={loading}
                  defaultSorted={defaultSort}
                  onSortedChange={onSortedChange}
                  defaultSortMethod={sortMethod}
                  LoadingComponent={() => (
                     loading && <CircularProgress className={classes.progressStyle}/>
                  )}
                  resizable={false}
                  multiSort={true}
                  noDataText={loading ? ' ' : noDataText}
                  data={list}
                  showPagination={false}
                  getNoDataProps={() => ({className: classes.noDataStyle})}
                  getTrProps={trProps}
                  style={{height: list.length > 0 ? undefined : '100%'}}
                  columns={filteredColumns}
      />
   );
}

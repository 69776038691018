import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import MoreVerticalIcon from '@material-ui/icons/MoreVertOutlined';
import PropTypes from 'prop-types';
import React, {Fragment, PureComponent} from 'react';
import Typography from '../../../fhg/components/Typography';
import PhotoCategorySelect from './PhotoCategorySelect';

export const MOVE_FIRST = 'first';
export const MOVE_PREVIOUS = 'previous';
export const MOVE_NEXT = 'next';
export const MOVE_LAST = 'last';

const styles = theme => ({
   moreColor: {
      color: 'black',
   },
   moreIconStyle: {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      '@supports not (-ms-high-contrast: none)': {
         filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
         '-webkit-filter': 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      },
      padding: theme.spacing(0.5),
      width: 40,
      height: 40,
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1) - 2,
      '&:hover': {
         backgroundColor: 'rgba(255, 255, 255, 0.6)',
      }
   }
});

/**
 * The More Menu for thumbnail photos.
 */
class PhotoMoreMenu extends PureComponent {
   static propTypes = {
      onDeletePhoto: PropTypes.func,
      onRefresh: PropTypes.func,
      onMovePhoto: PropTypes.func,
      isLast: PropTypes.bool,
      isFirst: PropTypes.bool,
      onSortAllPhotos: PropTypes.func,
   };

   state = {
      anchorEl: undefined,
      showCategorySelect: false,
   };

   /**
    * When the More Menu icon is clicked, open the menu.
    * @param event The click event.
    */
   onClick = event => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({anchorEl: event.currentTarget});
   };

   /**
    * When the More Menu is closed.
    */
   onClose = () => {
      this.setState({anchorEl: null});
   };

   /**
    * When the delete photo menu item is selected, call the prop to delete the photo.
    */
   handleDeletePhoto = event => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({anchorEl: null}, this.props.onDeletePhoto);
   };

   /**
    * When a move photo menu item is selected, call the prop to move the photo.
    */
   handleMovePhoto = action => event => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({anchorEl: null}, () => this.props.onMovePhoto(action));
   };

   /**
    * When a sort all photos menu item is selected, call the prop to sort the photos.
    */
   handleSortAllPhotos = event => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({anchorEl: null}, this.props.onSortAllPhotos);
   };

   handleCategorizePhoto = event => {
      this.setState({anchorEl: null, showCategorySelect: true});
   };

   closeCategorizePhoto = event => {
      this.setState({showCategorySelect: false});
   };

   render() {
      const {classes, image, onRefresh, isFirst, isLast} = this.props;
      const {anchorEl, showCategorySelect} = this.state;

      const open = Boolean(anchorEl);

      return (
         <Fragment>
            {showCategorySelect && (
               <PhotoCategorySelect onClose={this.closeCategorizePhoto} images={image} onRefresh={onRefresh}/>
            )}
            <IconButton
               className={`${classes.moreIconStyle} no-print`}
               aria-label='More'
               aria-owns={open ? 'long-menu' : null}
               aria-haspopup='true'
               onClick={this.onClick}
               style={{zIndex: 1002, color: 'black'}}
            >
               <MoreVerticalIcon/>
            </IconButton>
            <Menu
               id='long-menu'
               anchorEl={anchorEl}
               open={open}
               onClose={this.onClose}
            >
               <MenuItem onClick={this.handleDeletePhoto}>
                  <Typography color='inherit' variant='body1' id={'photoDetail.delete.menuItem'}/>
               </MenuItem>
               <MenuItem onClick={this.handleCategorizePhoto}>
                  <Typography color='inherit' variant='body1' id={'photoDetail.changeCategory.menuItem'}/>
               </MenuItem>
               <MenuItem onClick={this.handleMovePhoto(MOVE_FIRST)} disabled={isFirst}>
                  <Typography color='inherit' variant='body1' id={'photoDetail.moveFirst.menuItem'}/>
               </MenuItem>
               <MenuItem onClick={this.handleMovePhoto(MOVE_PREVIOUS)} disabled={isFirst}>
                  <Typography color='inherit' variant='body1' id={'photoDetail.movePrevious.menuItem'}/>
               </MenuItem>
               <MenuItem onClick={this.handleMovePhoto(MOVE_NEXT)} disabled={isLast}>
                  <Typography color='inherit' variant='body1' id={'photoDetail.moveNext.menuItem'}/>
               </MenuItem>
               <MenuItem onClick={this.handleMovePhoto(MOVE_LAST)} disabled={isLast}>
                  <Typography color='inherit' variant='body1' id={'photoDetail.moveLast.menuItem'}/>
               </MenuItem>
               <MenuItem onClick={this.handleSortAllPhotos}>
                  <Typography color='inherit' variant='body1' id={'photoDetail.sortAllPhotos.menuItem'}/>
               </MenuItem>
            </Menu>
         </Fragment>
      );
   }
}

export default withStyles(styles)(PhotoMoreMenu);